import type { CommentDto } from "api/types";
import { Button } from "components/Button/Button";
import { CommentFieldWithAvatar } from "components/CommentField/CommentField";
import { ConfirmModal } from "components/ConfirmModal/ConfirmModal";
import { LoadingIcon } from "components/Icons/Icons";
import type { FormImage } from "components/ImageInput/useImageInput";
import { useImageInput } from "components/ImageInput/useImageInput";
import type { FormVideo } from "components/VideoInput/useVideoInput";
import { isVideoUploaded, useVideoInput } from "components/VideoInput/useVideoInput";
import { parseISO } from "date-fns";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useUploadVideo } from "hooks/Network/useUploadVideo";
import { sortBy } from "lodash-es";
import { useConfig } from "providers/ConfigProvider";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import type { CreateCommentPayload, EditCommentPayload } from "./Comment";
import { Comment } from "./Comment";

interface Props {
  messageId: string;
  hasMoreComments: boolean;
  isLoadingComments: boolean;
  comments: CommentDto[];
  onLoadMore: () => void;
  commentPost: ({ parentId, comment, images, videos, failureMessage }: CreateCommentPayload) => Promise<void>;
  editComment: ({ commentId, comment, images, videos, failureMessage }: EditCommentPayload) => Promise<void>;
  deleteComment: (commentId: string) => void;
  onLikeComment: (commentId: string) => void;
  onUnlikeComment: (commentId: string) => void;
  onViewCommentLikes: (data: { commentId: string; likeCount: number }) => void;
  isGreyedOut: boolean;
}

export function CommentList({
  messageId,
  hasMoreComments,
  isLoadingComments,
  comments,
  onLoadMore,
  commentPost,
  editComment,
  deleteComment,
  onLikeComment,
  onUnlikeComment,
  onViewCommentLikes,
  isGreyedOut,
}: Props): React.ReactNode {
  const [editingComment, setEditingComment] = useState<string | undefined>(undefined);
  const [deletingComment, setDeletingComment] = useState<string | undefined>(undefined);
  const isVideoAttachmentAllowed = useConfig("isVideoAttachmentAllowed");
  const { t } = useTranslation();
  const sessionUser = useSessionUser();

  const [images, setImages] = useState<FormImage[]>([]);
  const [videos, setVideos] = useState<FormVideo[]>([]);
  const { addImages, removeImage, removeImages } = useImageInput({
    selectedImages: images,
    onChange: setImages,
  });
  const { uploadFormVideo } = useUploadVideo({
    onProgress: ({ name, progress }) => {
      setVideos((prevState) =>
        prevState.map((video) => {
          if (!isVideoUploaded(video) && video.file.name === name) {
            return { ...video, uploadProgress: progress };
          } else {
            return video;
          }
        }),
      );
    },
  });
  const { addVideos, removeVideo, removeVideos } = useVideoInput({
    selectedVideos: videos,
    onChange: setVideos,
    uploadFn: uploadFormVideo,
  });

  const { getValues, setValue, watch, register } = useForm<{ commentValue: string }>();

  const commentsByDate = useMemo(() => sortBy(comments, (x) => parseISO(x.postedAt).valueOf()), [comments]);

  useEffect(() => {
    register("commentValue");
  }, [register]);

  const commentValue = watch("commentValue");

  async function onReply() {
    const { commentValue } = getValues();

    if (editingComment) {
      await editComment({
        comment: commentValue,
        commentId: editingComment,
        images,
        videos,
        failureMessage: t("component.community-post.comments.edit-error"),
      });
      setEditingComment(undefined);
    } else {
      await commentPost({
        comment: commentValue,
        images,
        videos,
        failureMessage: t("component.community-post.comments.error"),
      });
    }

    removeImages();
    removeVideos();
    setValue("commentValue", "");
  }

  const onEdit = useCallback(
    (comment: CommentDto) => {
      setValue("commentValue", comment.content || "");
      if (comment.image) {
        setImages([comment.image]);
      }

      if (comment.videos && comment.videos.length > 0) {
        setVideos(comment.videos);
      }

      setEditingComment(comment.id);
    },
    [setValue, setImages, setEditingComment],
  );

  const onDelete = useCallback((comment: CommentDto) => {
    setDeletingComment(comment.id);
  }, []);

  const onCancelEdit = useCallback(() => {
    setValue("commentValue", "");
    removeImages();
    removeVideos();
    setEditingComment(undefined);
  }, [setValue, removeImages, removeVideos]);

  const onChangeContent = useCallback((value: string) => setValue("commentValue", value), [setValue]);

  return (
    <div>
      {hasMoreComments && (
        <Button styling="tertiary" data-testid="comments-load-more" onClick={onLoadMore}>
          <span className="text-caption">{t("component.community-post.comments.see-more")}</span>
        </Button>
      )}
      {isLoadingComments ? <LoadingIcon className="inset-0 mx-auto my-4 w-6" /> : null}
      <div>
        {commentsByDate.map((comment) => (
          <Comment
            key={comment.id}
            comment={comment}
            isGreyedOut={isGreyedOut}
            onEdit={onEdit}
            onDelete={onDelete}
            messageId={messageId}
            editComment={editComment}
            createComment={commentPost}
            onLike={onLikeComment}
            onUnlike={onUnlikeComment}
            onViewCommentLikes={() => onViewCommentLikes({ commentId: comment.id, likeCount: comment.totalLikesCount })}
          />
        ))}
      </div>
      {!isGreyedOut && !isLoadingComments && (
        <form>
          <div className="flex items-end">
            <CommentFieldWithAvatar
              user={sessionUser}
              value={commentValue}
              onChange={onChangeContent}
              images={images}
              videos={videos}
              onSubmit={onReply}
              isEdit={editingComment !== undefined}
              onCancel={onCancelEdit}
              allowedAttachments={isVideoAttachmentAllowed ? ["image", "video"] : ["image"]}
              onRemoveVideo={removeVideo}
              onAddVideo={addVideos}
              onRemoveImage={removeImage}
              onAddImages={addImages}
            />
          </div>
        </form>
      )}
      <ConfirmModal
        isOpen={!!deletingComment}
        id="delete-comment-modal"
        title={t("component.community-post.comments.delete.modal.title")}
        description={t("component.community-post.comments.delete.modal.description")}
        isLoading={false}
        theme="danger"
        onReject={() => setDeletingComment(undefined)}
        onOpenChange={(state) => {
          if (!state) {
            setDeletingComment(undefined);
          }
        }}
        rejectBtnProps={{
          "data-testid": "delete-comment-modal-cancel",
        }}
        onResolve={() => {
          deleteComment(deletingComment!);
          setDeletingComment(undefined);
        }}
        resolveBtnProps={{
          text: t("common.action.delete"),
          "data-testid": "delete-comment-modal-confirm",
        }}
        shouldCloseOnEsc
        data-testid="cancel-edit-modal"
      />
    </div>
  );
}
