import { FloatingPortal } from "@floating-ui/react";
import { Overlay } from "components/Overlay/Overlay";
import { ProjectList } from "components/ProjectSwitcher/ProjectList";
import { useRecentProjects } from "components/ProjectSwitcher/useRecentProjects";
import { useProjectContext } from "providers/ProjectContext";
import type React from "react";
import { type CSSProperties, forwardRef } from "react";
import { useParams } from "react-router";
import { twJoin } from "tailwind-merge";

interface PortfolioSidebarProjectSwitcherProps {
  style: CSSProperties;
  isOpened: boolean;
  onClose: () => void;
}

// eslint-disable-next-line react/display-name
export const PortfolioSidebarProjectSwitcher = forwardRef<HTMLDivElement, PortfolioSidebarProjectSwitcherProps>(
  ({ style, isOpened, onClose }, ref): React.ReactNode => {
    const { changeProject, projectId } = useProjectContext();
    const params = useParams();

    const isInProject = !!params["slug"];
    const { projects } = useRecentProjects(projectId, !isInProject, 3);

    const onSelectProject = (projectId: string) => {
      onClose();
      changeProject(projectId, true);
    };

    if (!isOpened) return null;

    return (
      <FloatingPortal>
        <div
          className={twJoin("!left-2 z-50 w-[calc(100%+32px)] max-w-[calc(100vw-32px)] md:w-72 lg:w-80")}
          {...{ ref, style }}
        >
          <div className="overflow-hidden rounded-lg bg-white p-4 shadow">
            <ProjectList
              currentProjectId={projectId}
              projects={projects}
              maxProjects={3}
              showCurrentProject={!isInProject}
              noBottomPadding
              {...{ onSelectProject }}
            />
          </div>
        </div>
        <Overlay onClick={onClose} />
      </FloatingPortal>
    );
  },
);
