import { PortfolioSidebar } from "components/PortfolioSidebar/PortfolioSidebar";
import { ProjectSidebar } from "components/ProjectSidebar/ProjectSidebar";
import { useConnectedProjects } from "hooks/Network/useConnectedProjects";
import { useBool } from "hooks/useBool";
import { getLocalStorageValue, useUpdateLocalStorage } from "hooks/useLocalStorage";
import { useScreenIsBiggerThan } from "hooks/useScreenIsBiggerThan";
import { AnimatePresence, LayoutGroup } from "motion/react";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router";

import { MobileMenuBar } from "./MobileMenuBar";

const MENU_PORTFOLIO_KEY = "portfolio-menu-wide";
const MENU_PROJECT_KEY = "project-menu-wide";

type MenuType = "portfolio" | "project";

export function NavigationSidebar(): React.ReactNode {
  const params = useParams();
  const isInProject = !!params["slug"];
  // Mobile only
  const [openedMenu, setOpenedMenu] = useState<MenuType | null>(null);
  // Desktop only
  const [isPortoflioSidebarCollapsed, portfolioSidebarCollapsedHandlers] = useBool(
    getLocalStorageValue(MENU_PORTFOLIO_KEY, false),
  );
  const [isProjectSidebarCollapsed, projectSidebarCollapsedHandlers] = useBool(
    getLocalStorageValue(MENU_PROJECT_KEY, false),
  );
  useUpdateLocalStorage(MENU_PORTFOLIO_KEY, isPortoflioSidebarCollapsed);
  useUpdateLocalStorage(MENU_PROJECT_KEY, isProjectSidebarCollapsed);

  const location = useLocation();
  const isDesktop = useScreenIsBiggerThan("md");
  const connectedProjects = useConnectedProjects();
  const canViewPortfolio = useMemo(
    () => connectedProjects.data?.some((x) => x.userRole !== "resident"),
    [connectedProjects.data],
  );

  useEffect(() => {
    if (!isInProject) return;

    portfolioSidebarCollapsedHandlers.set(true);
    projectSidebarCollapsedHandlers.set(false);
  }, [isInProject, portfolioSidebarCollapsedHandlers, projectSidebarCollapsedHandlers]);

  useEffect(() => {
    setOpenedMenu(null);
  }, [location.pathname]);

  // Mobile only
  const onToggleOpenedMenu = (type: MenuType, state: boolean) => {
    if (isDesktop) return;

    setOpenedMenu(state ? type : null);
  };

  return (
    <nav
      className="flex md:grid md:h-full"
      style={{
        gridTemplateColumns: isInProject ? "repeat(2, min-content)" : "min-content",
      }}
    >
      {!isDesktop && (
        <MobileMenuBar
          onToggleMenu={() => onToggleOpenedMenu(isInProject ? "project" : "portfolio", true)}
          {...{ isInProject }}
        />
      )}
      <AnimatePresence mode="wait">
        <LayoutGroup>
          {canViewPortfolio && (
            <PortfolioSidebar
              isOpened={isDesktop || (!isInProject && openedMenu === "portfolio")}
              onToggleOpen={(state) => onToggleOpenedMenu("portfolio", state)}
              isCollapsed={isDesktop && isPortoflioSidebarCollapsed}
              onToggleCollapse={portfolioSidebarCollapsedHandlers.set}
              isFaded={isInProject}
            />
          )}

          {isInProject && (
            <ProjectSidebar
              isOpened={isDesktop || openedMenu === "project"}
              onToggleOpen={(state) => onToggleOpenedMenu("project", state)}
              isCollapsed={isDesktop && isProjectSidebarCollapsed}
              onToggleCollapse={projectSidebarCollapsedHandlers.set}
            />
          )}
        </LayoutGroup>
      </AnimatePresence>
    </nav>
  );
}
