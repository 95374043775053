import { ErrorPage } from "components/Error/ErrorPage";
import { canAccessCommunityFeed } from "components/ProjectSidebar/permissions";
import { RequireAuth } from "components/Routing/RequireAuth";
import type { RouteObject } from "react-router";

import { AdminCreatePost } from "./pages/AdminCreatePost";
import { MessageDetailsPage } from "./pages/Details";
import { MessageFeedPage } from "./pages/List";
import { canCreateMessage, canListMessages } from "./permissions";
import { messagesRoutes } from "./routes";

export const messagesRoute = [
  {
    path: messagesRoutes.list,
    element: (
      <RequireAuth permission={canAccessCommunityFeed}>
        <MessageFeedPage />
      </RequireAuth>
    ),
  },
  {
    path: messagesRoutes.details,
    element: (
      <RequireAuth permission={canListMessages}>
        <MessageDetailsPage />
      </RequireAuth>
    ),
  },
  {
    path: messagesRoutes.adminCreatePost,
    element: (
      <RequireAuth permission={canCreateMessage}>
        <AdminCreatePost />
      </RequireAuth>
    ),
  },
  { path: "*", element: <ErrorPage status={404} /> },
] satisfies RouteObject[];
