import iconChevronRight from "assets/icons/chevron-right.svg";
import { ContextMenu } from "components/ContextMenu/ContextMenu";
import { Icon } from "components/Icon/Icon";
import { debounce } from "lodash-es";
import type { PropsWithChildren } from "react";
import { Fragment, useCallback, useEffect, useLayoutEffect, useMemo, useReducer, useRef } from "react";
import { Link, type To, useNavigate } from "react-router";
import { twJoin } from "tailwind-merge";

export interface BreadcrumbsProps {
  pages: Breadcrumb[];
}

export function Breadcrumbs(props: BreadcrumbsProps): React.ReactNode {
  const { pages } = props;
  const navigate = useNavigate();
  const rootRef = useRef<HTMLElement>(null);
  const [state, dispatch] = useReducer<(state: State, action: Action) => State, Breadcrumb[]>(
    reducer,
    pages,
    (pages) => {
      return {
        hiddenItems: [],
        visibleItems: pages,
      };
    },
  );
  const transform = useCallback(() => {
    if (rootRef == null || rootRef.current == null) {
      return;
    }
    const { clientWidth: rootClientWidth, scrollWidth: rootScrollWidth } = rootRef.current;
    if (rootScrollWidth > rootClientWidth && state.visibleItems.length > 2) {
      dispatch({ type: "remove" });
    }
  }, [state]);
  const debouncedTransform = useMemo(
    () =>
      debounce(() => {
        dispatch({ type: "reset", payload: pages });
      }, 200),
    [pages],
  );
  useLayoutEffect(() => {
    /*
     * we need not debounced fn to change layout fast on first render
     * */
    transform();
  }, [transform]);

  useEffect(() => {
    dispatch({ type: "reset", payload: pages });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(pages)]);

  useEffect(() => {
    window.addEventListener("resize", debouncedTransform);

    return () => window.removeEventListener("resize", debouncedTransform);
  }, [debouncedTransform]);

  return (
    <nav className="flex w-full" ref={rootRef} aria-label="Breadcrumb" data-testid="breadcrumbs">
      <ol className="flex flex-wrap items-center gap-x-1">
        {state.visibleItems.map((page, i, { length }) => {
          const isLastItem = length - 1 === i;

          return (
            <Fragment key={page.name}>
              {state.hiddenItems.length && i === 1 ? (
                <Item>
                  <Icon name={iconChevronRight} className="mr-1 shrink-0" aria-hidden="true" size={16} />
                  <ContextMenu
                    actions={state.hiddenItems.map(({ name, ...props }) => ({
                      text: name,
                      status: {
                        disabled: !("to" in props) && !("callback" in props),
                      },
                      callback: () => {
                        if ("to" in props) {
                          void navigate(props.to);
                        } else if ("callback" in props) {
                          props.callback();
                        }
                      },
                    }))}
                  >
                    {({ openHandlers }) => (
                      <button
                        className="relative flex items-center justify-center before:absolute before:size-8 before:content-['']"
                        type="button"
                        onClick={openHandlers.setTrue}
                      >
                        <span className="text-caption text-aop-basic-blue-500">...</span>
                      </button>
                    )}
                  </ContextMenu>
                </Item>
              ) : null}
              <Item isLastItem={isLastItem}>
                {i !== 0 ? (
                  <Icon name={iconChevronRight} className="mr-1 shrink-0" aria-hidden="true" size={16} />
                ) : null}
                {
                  // eslint-disable-next-line no-nested-ternary
                  "to" in page ? (
                    <Link
                      className={twJoin(
                        "rounded-2px px-1 first:pl-0 hover:bg-grey-100 focus:outline-none focus-visible:ring-1 focus-visible:ring-grey-900",
                        isLastItem ? "pointer-events-none" : "underline",
                      )}
                      data-testid="breadcrumbs-link"
                      to={page.to}
                      aria-current={isLastItem ? "page" : undefined}
                    >
                      <span className="whitespace-nowrap text-caption">{page.name}</span>
                    </Link>
                  ) : "callback" in page ? (
                    <button
                      className={twJoin(
                        "rounded-2px px-1 first:pl-0 hover:bg-grey-100 focus:outline-none focus-visible:ring-1 focus-visible:ring-grey-900",
                        isLastItem ? undefined : "underline",
                      )}
                      type="button"
                      data-testid="breadcrumbs-link"
                      onClick={page.callback}
                    >
                      <span className="whitespace-nowrap text-caption">{page.name}</span>
                    </button>
                  ) : (
                    <span className="whitespace-nowrap text-caption">{page.name}</span>
                  )
                }
              </Item>
            </Fragment>
          );
        })}
      </ol>
    </nav>
  );
}

function Item(props: PropsWithChildren<{ isLastItem?: boolean }>): React.ReactNode {
  return (
    <li>
      <div className={twJoin("flex items-center", props.isLastItem ? "text-black" : "text-grey-500")}>
        {props.children}
      </div>
    </li>
  );
}

export type Breadcrumb = {
  name: string;
} & ({} | { to: To } | { callback: () => void });

interface State {
  hiddenItems: Breadcrumb[];
  visibleItems: Breadcrumb[];
}

type Action = {
  type: "remove" | "reset";
  payload?: Breadcrumb[];
};

function reducer(state: State, action: Action) {
  let newVisibleItems;
  let newHiddenItems;
  let currentItem;
  switch (action.type) {
    case "remove":
      if (state.visibleItems.length === 2) {
        return state;
      }
      newVisibleItems = [...state.visibleItems];
      [currentItem] = newVisibleItems.splice(1, 1);
      newHiddenItems = [...state.hiddenItems, currentItem];

      return { hiddenItems: newHiddenItems, visibleItems: newVisibleItems };
    case "reset":
      return { hiddenItems: [], visibleItems: [...(action.payload ?? [])] };
    default:
      return state;
  }
}
