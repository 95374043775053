import { ErrorPage } from "components/Error/ErrorPage";
import { RequireAuth } from "components/Routing/RequireAuth";
import type { RouteObject } from "react-router";

import { CreateOrEditPage } from "./pages/CreateOrEdit";
import { CreateOrEditProjectConnectionGroupsPage } from "./pages/GroupsCreateOrEdit";
import { ListProjectConnectionGroupsPage } from "./pages/GroupsList";
import { ListConnectionsPage } from "./pages/List";
import {
  canListProjectConnectionGroups,
  canListProjectConnections,
  canManageProjectConnectionGroups,
  canManageProjectConnections,
} from "./permissions";
import { projectConnectionsRoutes } from "./routes";

export const projectConnectionsRoute = [
  {
    path: projectConnectionsRoutes.list,
    element: (
      <RequireAuth permission={canListProjectConnections}>
        <ListConnectionsPage />
      </RequireAuth>
    ),
  },
  {
    path: projectConnectionsRoutes.create,
    element: (
      <RequireAuth permission={canManageProjectConnections}>
        <CreateOrEditPage />
      </RequireAuth>
    ),
  },
  {
    path: projectConnectionsRoutes.edit,
    element: (
      <RequireAuth permission={canManageProjectConnections}>
        <CreateOrEditPage />
      </RequireAuth>
    ),
  },
  {
    path: projectConnectionsRoutes.listGroups,
    element: (
      <RequireAuth permission={canListProjectConnectionGroups}>
        <ListProjectConnectionGroupsPage />
      </RequireAuth>
    ),
  },
  {
    path: projectConnectionsRoutes.createGroup,
    element: (
      <RequireAuth permission={canManageProjectConnectionGroups}>
        <CreateOrEditProjectConnectionGroupsPage />
      </RequireAuth>
    ),
  },
  {
    path: projectConnectionsRoutes.editGroup,
    element: (
      <RequireAuth permission={canManageProjectConnectionGroups}>
        <CreateOrEditProjectConnectionGroupsPage />
      </RequireAuth>
    ),
  },
  { path: "*", element: <ErrorPage status={404} /> },
] satisfies RouteObject[];
