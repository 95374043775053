import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type {
  ProjectConnectionGroupCreateRequest,
  ProjectConnectionGroupUpdateRequest,
  TranslateRequest,
} from "api/types";
import { ErrorPage } from "components/Error/ErrorPage";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { toPermissionsRequest } from "helpers/system-groups/permissions";
import { toTranslationsRequest } from "helpers/system-groups/translations";
import { isDefined } from "helpers/util";
import { useProjectId } from "hooks/Network/useProjectId";
import { useUploadImage } from "hooks/Network/useUploadImage";
import { QUERY_KEYS } from "query-keys";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { routes } from "routes";

import type { FormValues, LayoutProps } from "./Layout";
import { getDefaultFormValues } from "./Manager";

interface LoaderProps {
  children: (props: LayoutProps) => React.ReactNode;
}

export function Loader({ children }: LoaderProps): React.ReactNode {
  const projectId = useProjectId();
  const { id: connectionId, gid: groupId } = useParams<{ id: string; gid: string }>();
  const isEdit = isDefined(groupId);
  const api = useApi();
  const queryClient = useQueryClient();
  const showFlashToast = useFlashToast();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { uploadFormImage, isUploadingImage } = useUploadImage();

  const {
    data: projectConnection,
    isPending: isLoadingProjectConnection,
    error: projectConnectionError,
  } = useQuery({
    queryKey: QUERY_KEYS.PROJECT_CONNECTION_DETAILS(connectionId!),
    queryFn: () => api.getProjectConnectionsDetailsV1(connectionId!),
    select: commonAPIDataSelector,
  });

  const {
    data: group,
    isFetching: isLoadingGroup,
    error: groupError,
  } = useQuery({
    queryKey: QUERY_KEYS.PROJECT_CONNECTION_GROUP_DETAILS(groupId!),
    queryFn: () => api.getProjectConnectionsGroupsDetailsV1(groupId!, connectionId!),
    enabled: isEdit,
    select: commonAPIDataSelector,
  });

  const {
    data: languages = [],
    isFetching: isLoadingLanguages,
    error: languagesError,
  } = useQuery({
    queryKey: QUERY_KEYS.LANGUAGES_ALL,
    queryFn: () => api.getLanguagesAllV1(),
    select: commonAPIDataSelector,
  });

  const {
    data: defaultGroupsPermissions = [],
    isFetching: isLoadingDefaultGroupsPermissions,
    error: defaultGroupsPermissionsError,
  } = useQuery({
    queryKey: QUERY_KEYS.ROLE_TYPE_DEFAULT_PERMISSIONS(projectId),
    queryFn: () => api.getPermissionsDefaultTypeV1(),
    select: commonAPIDataSelector,
  });

  const {
    data: roles = [],
    isFetching: isLoadingRoles,
    error: rolesError,
  } = useQuery({
    queryKey: QUERY_KEYS.PERMISSION_ROLES(projectId),
    queryFn: () => api.getPermissionsRolesV1(),
    select: commonAPIDataSelector,
  });

  const { mutateAsync: onTranslate, isPending: isTranslating } = useMutation({
    mutationFn: (payload: TranslateRequest) => api.postTranslationsTranslateV1(payload).then((x) => x.data),
  });

  const { mutateAsync: createGroup, isPending: isSubmittingCreate } = useMutation({
    mutationFn: (payload: ProjectConnectionGroupCreateRequest) =>
      api.postProjectConnectionsGroupsV1(connectionId!, payload),
    onSuccess: () => {
      showFlashToast({
        type: "success",
        title: t("page.project-connection-groups.create.success"),
      });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.PROJECT_CONNECTIONS });
      void navigate(routes.projectConnections.list());
    },
    onError: () => {
      showFlashToast({
        type: "error",
        title: t("page.project-connection-groups.create.error"),
      });
    },
  });

  const { mutateAsync: editGroup, isPending: isSubmittingEdit } = useMutation({
    mutationFn: (payload: ProjectConnectionGroupUpdateRequest) =>
      api.putProjectConnectionsGroupsV1(connectionId!, groupId!, payload),
    onSuccess: () => {
      showFlashToast({
        type: "success",
        title: t("page.project-connection-groups.edit.success"),
      });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.PROJECT_CONNECTIONS });
      void navigate(routes.projectConnections.list());
    },
    onError: () => {
      showFlashToast({
        type: "error",
        title: t("page.project-connection-groups.edit.error"),
      });
    },
  });

  const { mutateAsync: deleteGroup, isPending: isDeletingGroup } = useMutation({
    mutationFn: () => api.deleteProjectConnectionsGroupsByIdV1(connectionId!, groupId!),
    onSuccess: () => {
      showFlashToast({
        type: "success",
        title: t("page.project-connection-groups.delete.success"),
      });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.PROJECT_CONNECTIONS });
      void navigate(routes.projectConnections.list());
    },
    onError: () => {
      showFlashToast({
        type: "error",
        title: t("page.project-connection-groups.delete.error"),
      });
    },
  });

  async function onSubmit(payload: FormValues) {
    if (!payload.image || payload.image.length === 0) {
      showFlashToast({
        type: "error",
        title: t("page.project-connection-groups.create.error"),
      });

      return;
    }

    const image = await uploadFormImage(payload.image[0]);

    if (!image) return;

    const groupRequest: ProjectConnectionGroupCreateRequest | ProjectConnectionGroupUpdateRequest = {
      type: payload.type,
      translations: toTranslationsRequest(languages, payload.nameTranslations, payload.descriptionTranslations),
      imageId: image.id,
      defaultPostingAudience: payload.audience,
      permissions: toPermissionsRequest(payload.permissions),
      icon: payload.icon!,
    };

    if (isEdit) {
      await editGroup(groupRequest);
    } else {
      await createGroup({ ...groupRequest, postingLevel: payload.postingLevel });
    }
  }

  const error =
    languagesError ||
    defaultGroupsPermissionsError ||
    rolesError ||
    groupError ||
    !connectionId ||
    projectConnectionError;
  if (error) {
    return <ErrorPage error={error} />;
  }

  const isLoading =
    isLoadingLanguages ||
    isLoadingDefaultGroupsPermissions ||
    isLoadingRoles ||
    isLoadingGroup ||
    isLoadingProjectConnection;
  if (isLoading) {
    return <FullSizeLoader withPadding />;
  }

  const defaultValues = getDefaultFormValues(roles, languages, defaultGroupsPermissions, group);

  return children({
    projectConnection: projectConnection,
    languages: languages ?? [],
    defaultValues,
    onTranslate,
    isTranslating,
    onSubmit,
    onDelete: deleteGroup,
    isSubmitting: isUploadingImage || isSubmittingCreate || isSubmittingEdit || isDeletingGroup,
  });
}
