import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { CompanyRequest } from "api/types";
import { ErrorPage } from "components/Error/ErrorPage";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { isDefined } from "helpers/util";
import { useProjectId } from "hooks/Network/useProjectId";
import { useUploadImage } from "hooks/Network/useUploadImage";
import { useSlug } from "hooks/useSlug";
import { useAddressQueries } from "queries/addresses/queryOptions";
import { QUERY_KEYS } from "query-keys";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { routes } from "routes";

import type { LayoutProps } from "./Layout";

export function Loader(props: { children: (props: LayoutProps) => React.ReactNode }): React.ReactNode {
  const projectId = useProjectId();
  const slug = useSlug();
  const { id: companyId } = useParams<{ id: string }>();
  const isEditMode = isDefined(companyId);
  const api = useApi();
  const showFlashToast = useFlashToast();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { uploadFormImage, isUploadingImage } = useUploadImage();
  const {
    data: companies,
    isPending: isLoadingCompanies,
    error: companiesError,
  } = useQuery({
    queryKey: QUERY_KEYS.COMPANY_LIST(projectId),
    queryFn: () => api.getCompaniesV1({ Limit: 10000, Offset: 0 }),
    select: commonAPIDataSelector,
  });
  const addressQueries = useAddressQueries();
  const {
    data: addresses,
    isLoading: isLoadingAddresses,
    error: addressesError,
  } = useQuery(addressQueries.getAddresses());
  const {
    data: companyDetails,
    isFetching: isLoadingCompanyDetails,
    error: companyDetailsError,
  } = useQuery({
    queryKey: QUERY_KEYS.COMPANY_DETAILS(projectId, companyId!),
    queryFn: () => api.getCompaniesDetailsV1(companyId!),
    select: commonAPIDataSelector,
    enabled: isEditMode,
  });
  const createCompany = useMutation({
    mutationFn: (data: CompanyRequest) => api.postCompaniesV1(data),
    onSuccess() {
      showFlashToast({ title: t("page.companies.new.notification.success"), type: "success" });
      void navigate(routes.companies.list({ slug }));
    },
    onError() {
      showFlashToast({ title: t("page.companies.new.notification.failed"), type: "error" });
    },
    onSettled() {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.COMPANY_LIST(projectId) });
    },
  });
  const editCompany = useMutation({
    mutationFn: (data: CompanyRequest) => api.putCompaniesV1(companyId!, data),
    onSuccess() {
      showFlashToast({ title: t("page.companies.edit.notification.success"), type: "success" });
      void navigate(routes.companies.list({ slug }));
    },
    onError() {
      showFlashToast({ title: t("page.companies.edit.notification.failed"), type: "error" });
    },
    onSettled() {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.COMPANY_LIST(projectId) });
    },
  });

  const existingCompanies = useMemo(
    () => companies?.items.filter((x) => x.id !== companyId) ?? [],
    [companies, companyId],
  );

  const error = companyDetailsError || addressesError || companiesError;
  if (error) {
    return <ErrorPage error={error} />;
  }

  const isLoading = isLoadingCompanyDetails || isLoadingAddresses || isLoadingCompanies;
  if (isLoading) {
    return <FullSizeLoader withPadding />;
  }

  return props.children({
    isEditMode: isEditMode,
    defaultValues: isEditMode
      ? {
          name: companyDetails!.name,
          description: companyDetails!.description,
          address: addresses?.items.find((x) => x.id === companyDetails!.address.id),
          background: companyDetails?.backgroundImage ? [companyDetails.backgroundImage] : [],
          logo: companyDetails?.logo ? [companyDetails.logo] : [],
        }
      : {},
    isSubmitting: isUploadingImage || editCompany.isPending || createCompany.isPending,
    existingCompanies: existingCompanies,
    addresses: addresses?.items ?? [],
    async onSubmit(data) {
      const payload: CompanyRequest = {
        addressId: data.address.id,
        name: data.name,
        description: data.description,
      };

      if (data.background.length > 0) {
        const background = await uploadFormImage(data.background[0]);

        payload.backgroundImageId = background?.id;
      }
      if (data.logo.length > 0) {
        const logo = await uploadFormImage(data.logo[0]);

        payload.logoId = logo?.id;
      }

      if (isEditMode) {
        editCompany.mutate(payload);
      } else {
        createCompany.mutate(payload);
      }
    },
  });
}
