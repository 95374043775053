import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { SurveyDto } from "api/types";
import { ErrorPage } from "components/Error/ErrorPage";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSlug } from "hooks/useSlug";
import { QUERY_KEYS } from "query-keys";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { routes } from "routes";

import type { LayoutProps } from "./Layout";

interface Props {
  children: (data: LayoutProps) => React.ReactNode;
}

export function Loader(props: Props): React.ReactNode {
  const projectId = useProjectId();
  const slug = useSlug();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const api = useApi();
  const { id: surveyId } = useParams<{ id: string }>();

  const {
    data: surveyDetails,
    error: surveysError,
    isPending: isLoadingSurvey,
  } = useQuery({
    queryKey: QUERY_KEYS.SURVEY_DETAILS(projectId, surveyId!),
    queryFn: () => api.getSurveysDetailsV1(surveyId!),
    select: commonAPIDataSelector,
  });

  const showFlashToast = useFlashToast();
  const queryClient = useQueryClient();
  const deleteSurvey = useMutation({
    mutationFn: (survey: SurveyDto) => api.deleteSurveysByIdV1(survey.id),
    onSuccess() {
      showFlashToast({ type: "success", title: t("page.surveys.actions.delete.notifications.success") });
      void navigate(routes.surveys.list({ slug }));
    },
    onError() {
      showFlashToast({ type: "error", title: t("page.surveys.actions.delete.notifications.failed") });
    },
    onSettled() {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.SURVEYS(projectId) });
    },
  });

  if (isLoadingSurvey) {
    return <FullSizeLoader withPadding />;
  }

  const error = surveysError;
  if (error) {
    return <ErrorPage error={error} />;
  }

  return props.children({
    survey: surveyDetails,
    onDelete: deleteSurvey.mutateAsync,
  });
}
