import { useQuery } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { ErrorPage } from "components/Error/ErrorPage";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { useProjectId } from "hooks/Network/useProjectId";
import { QUERY_KEYS } from "query-keys";
import type React from "react";
import { useParams } from "react-router";

import type { LayoutProps } from "./Layout";

interface Props {
  children: (props: LayoutProps) => React.ReactNode;
}

export function Loader(props: Props): React.ReactNode {
  const projectId = useProjectId();
  const api = useApi();
  const { id: eventId } = useParams<{ id: string }>();
  const {
    data: response,
    isPending: isEventDetailsLoading,
    error,
  } = useQuery({
    queryKey: QUERY_KEYS.EVENT_DETAILS(projectId, eventId!),
    queryFn: () => api.getEventsDetailsV1(eventId!, { includeAllParticipants: true }),
  });
  const eventDetails = response?.data;
  const isPageLoading = isEventDetailsLoading;

  if (error) {
    return <ErrorPage error={error} />;
  }

  return eventDetails == null || isPageLoading ? (
    <FullSizeLoader withPadding />
  ) : (
    props.children({
      eventDetails,
    })
  );
}
