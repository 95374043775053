import { ErrorPage } from "components/Error/ErrorPage";
import { RequireAuth } from "components/Routing/RequireAuth";
import { CreateOrEditProjectPage } from "modules/project/pages/CreateOrEdit";
import type { RouteObject } from "react-router";

import { AvailableIntegrationDetailPage } from "./pages/AvailableIntegrationDetail/AvailableIntegrationDetail";
import { AvailableIntegrationsPage } from "./pages/AvailableIntegrations/AvailableIntegrations";
import { CreateMassMessage } from "./pages/CreatePost";
import { Overview } from "./pages/Overview/Overview";
import { ListTicketsPage } from "./pages/PortfolioTicketList";
import { TicketSettingsPage } from "./pages/TicketSettings";
import { UsersLookupPage } from "./pages/UsersLookup/UsersLookupPage";
import { portfolioRoutes } from "./routes";

export const portfolioRoute = [
  { path: portfolioRoutes.overview, element: <Overview /> },
  {
    path: portfolioRoutes.newProject,
    element: (
      <RequireAuth permission={(x) => x.isSuperAdmin}>
        <CreateOrEditProjectPage />
      </RequireAuth>
    ),
  },
  {
    path: portfolioRoutes.userLookup,
    element: (
      <RequireAuth permission={(x) => x.isSuperAdmin}>
        <UsersLookupPage />
      </RequireAuth>
    ),
  },
  { path: portfolioRoutes.createMassMessage, element: <CreateMassMessage /> },
  { path: portfolioRoutes.tickets, element: <ListTicketsPage /> },
  { path: portfolioRoutes.ticketSettings, element: <TicketSettingsPage /> },
  { path: portfolioRoutes.integrations, element: <AvailableIntegrationsPage /> },
  { path: portfolioRoutes.integrationDetails, element: <AvailableIntegrationDetailPage /> },
  { path: "*", element: <ErrorPage status={404} /> },
] satisfies RouteObject[];
