import { Paper } from "components/Paper/Paper";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useDocumentTitle } from "hooks/useDocumentTitle";
import { useConfig } from "providers/ConfigProvider";
import { useTranslation } from "react-i18next";
import { twJoin } from "tailwind-merge";

import { HomeCommunityPostList } from "../components/HomeCommunityPostList";
import { HomeDataWidgets } from "../components/HomeDataWidgets";
import { HomeFahWidget } from "../components/HomeFahWidget";
import { HomeFahWidgetLegacy } from "../components/HomeFahWidgetLegacy";

export function Layout(): React.ReactNode {
  const sessionUser = useSessionUser();
  const isAnalyticsV2Available = useConfig("isAnalyticsV2Available");
  const { t } = useTranslation();

  useDocumentTitle(undefined);

  const canViewWidgets = sessionUser.isAdmin && sessionUser.role.type !== "maintenance";

  return (
    <Paper theme="no-header-minimal">
      <div className="max-w-[880px] 2xl:max-w-none">
        <div
          className={twJoin(
            "grid grid-cols-1 grid-rows-[auto_auto] gap-4 2xl:justify-between 2xl:gap-x-16",
            isAnalyticsV2Available ? "2xl:grid-cols-[0.7fr_0.3fr]" : "2xl:grid-cols-[min-content_auto]",
          )}
        >
          {sessionUser.isAdmin && !isAnalyticsV2Available && <HomeFahWidgetLegacy />}
          {sessionUser.isAdmin && isAnalyticsV2Available && (
            <div className="flex w-full flex-col gap-4">
              <h1 className="text-headline1">
                {t("page.home.feel-at-home.description", { name: sessionUser.firstName })} 👋
              </h1>
              <HomeFahWidget />
            </div>
          )}

          {canViewWidgets ? <HomeDataWidgets /> : <div />}

          <HomeCommunityPostList />
        </div>
      </div>
    </Paper>
  );
}
