import * as Sentry from "@sentry/react";
import type { Locale } from "date-fns";
import {
  formatDistance as formateDistanceDateFns,
  formatDistanceStrict,
  formatDistanceToNow as formatDistanceToNowDateFns,
  formatDistanceToNowStrict,
} from "date-fns";
import type { i18n } from "i18next";
import { useTranslation } from "react-i18next";

import { fallbackLanguageCode, locales } from "../../helpers/hardcoded-translations";

let logged = false;

interface DateDistance {
  start: Date;
  end?: Date;
}

interface FormattedDistanceProps {
  distance: DateDistance;
  strict?: boolean;
}
export function FormattedDistance({ distance, strict = false }: FormattedDistanceProps): React.ReactNode {
  const { i18n } = useTranslation();

  return <span>{formatDistance(i18n, distance, strict)}</span>;
}

export function formatDistance(
  { language, t }: i18n,
  distance: FormattedDistanceProps["distance"],
  strict = false,
): string {
  const result = formatDistanceForLanguage(t, language, distance, strict);
  if (result.trim() === "" && !logged) {
    logged = true;
    try {
      Sentry.captureException(`Date formatting yielded empty string`, {
        extra: {
          start: distance.start,
          end: distance.end,
          strict,
          language,
          locales,
          fallbackLanguageCode,
        },
      });
    } catch (error) {
      /* empty */
    }
  }

  return result;
}

function formatDistanceForLanguage(t: i18n["t"], languageId: string, distance: DateDistance, strict: boolean): string {
  if (!(languageId in locales)) {
    console.warn(
      `Language ${languageId} not found in hardcoded translations, falling back to language id ${fallbackLanguageCode}`,
    );
  }

  const locale = locales[languageId as keyof typeof locales] || locales[fallbackLanguageCode];

  if (distance.end === undefined) {
    return formateDistanceToNow(distance.start, strict, locale);
  }

  return formatRelativeDistance(distance, strict, locale);
}

function formateDistanceToNow(startDate: Date, strict: boolean, locale: Locale): string {
  if (strict) {
    return formatDistanceToNowStrict(startDate, { locale: locale });
  }

  return formatDistanceToNowDateFns(startDate, { locale: locale });
}

function formatRelativeDistance(duration: DateDistance, strict: boolean, locale: Locale): string {
  if (strict) {
    return formatDistanceStrict(duration.start, duration.end!, { locale: locale });
  }

  return formateDistanceDateFns(duration.start, duration.end!, { locale: locale });
}
