import { ErrorPage } from "components/Error/ErrorPage";
import type { RouteObject } from "react-router";

import { OnboardingGroupsPage } from "./pages/Groups";
import { OnboardingHelpCategoriesPage } from "./pages/HelpCategories";
import { OnboardingSlidesPage } from "./pages/Slides";
import { OnboardingUserProfilePage } from "./pages/UserProfile";
import { onboardingFlowRoutes } from "./routes";

export const onboardingFlowRoute = [
  {
    path: onboardingFlowRoutes.slides,
    element: <OnboardingSlidesPage />,
  },
  {
    path: onboardingFlowRoutes.userProfile,
    element: <OnboardingUserProfilePage />,
  },
  {
    path: onboardingFlowRoutes.groups,
    element: <OnboardingGroupsPage />,
  },
  {
    path: onboardingFlowRoutes.helpCategories,
    element: <OnboardingHelpCategoriesPage />,
  },
  { path: "*", element: <ErrorPage status={404} /> },
] satisfies RouteObject[];
