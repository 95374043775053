import { ErrorPage } from "components/Error/ErrorPage";
import {
  canAccessProjectIntegrationSettings,
  canAccessProjectPlusButtonOptions,
  canAccessProjectSettings,
  canAccessProjectStyling,
} from "components/ProjectSidebar/permissions";
import { RequireAuth } from "components/Routing/RequireAuth";
import type { RouteObject } from "react-router";

import { CreateOrEditProjectPage } from "./pages/CreateOrEdit";
import { IntegrationsPage } from "./pages/Integrations/IntegrationsPage";
import { PlusButtonOptionsPage } from "./pages/PlusButtonOptions";
import { StylingPage } from "./pages/Styling";
import { projectRoutes } from "./routes";

export const projectsRoute = [
  {
    path: projectRoutes.edit,
    element: (
      <RequireAuth permission={canAccessProjectSettings}>
        <CreateOrEditProjectPage isEditMode />
      </RequireAuth>
    ),
  },
  {
    path: projectRoutes.styling,
    element: (
      <RequireAuth permission={canAccessProjectStyling}>
        <StylingPage />
      </RequireAuth>
    ),
  },
  {
    path: projectRoutes.plusButtonOptions,
    element: (
      <RequireAuth permission={canAccessProjectPlusButtonOptions}>
        <PlusButtonOptionsPage />
      </RequireAuth>
    ),
  },
  {
    path: projectRoutes.integrations,
    element: (
      <RequireAuth permission={canAccessProjectIntegrationSettings}>
        <IntegrationsPage />
      </RequireAuth>
    ),
  },
  { path: "*", element: <ErrorPage status={404} /> },
] satisfies RouteObject[];
