import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { TicketCategoryDto, TicketRequest, UserDetailsDto } from "api/types";
import { ErrorPage } from "components/Error/ErrorPage";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { isDefined } from "helpers/util";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useUploadImage } from "hooks/Network/useUploadImage";
import { usePermission } from "hooks/usePermission";
import { useSlug } from "hooks/useSlug";
import { QUERY_KEYS } from "query-keys";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { routes } from "routes";

import type { LayoutFormValues, LayoutProps } from "./Layout";

interface Props {
  children: (props: LayoutProps) => React.ReactNode;
}

export function Loader({ children }: Props): React.ReactNode {
  const projectId = useProjectId();
  const slug = useSlug();
  const { t } = useTranslation();
  const [category, setCategory] = useState<TicketCategoryDto | undefined>(undefined);
  const [reporterId, setReporterId] = useState<string>();
  const showFlashToast = useFlashToast();
  const api = useApi();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const hasPermission = usePermission();
  const sessionUser = useSessionUser();

  const {
    isSuccess: hasFetchedCategories,
    data: categories = [],
    isFetching: isCategoriesFetching,
    error: categoriesError,
  } = useQuery({
    queryKey: QUERY_KEYS.TICKET_CATEGORIES(projectId),
    queryFn: () => api.getTicketCategoriesV1(),
    select: (data) =>
      commonAPIDataSelector(data).items.filter((category) =>
        hasPermission((p) => p.hasTicketCategoryPermission(category.id, (x) => x.canAddRequest)),
      ),
  });

  const {
    data: repairAssignees,
    isFetching: isFetchingRepairAssignees,
    error: repairAssigneesError,
  } = useQuery({
    queryKey: QUERY_KEYS.ASSIGNEES_BY_CATEGORY(projectId, category?.id || "-"),
    queryFn: () => api.getUsersRepairAssigneesV1({ categoryId: category!.id }),
    enabled: category != null,
    select: commonAPIDataSelector,
  });

  const usersQueryArgs = {
    Offset: 0,
    Limit: 10000,
    IncludeAdmins: true,
    IncludeDeleted: false,
    IncludeNotRegistered: true,
  };
  const {
    data: users = [],
    isFetching: isUsersFetching,
    error: usersError,
  } = useQuery({
    queryKey: QUERY_KEYS.USERS_QUERY(projectId, usersQueryArgs),
    queryFn: () => api.getUsersV1(usersQueryArgs),
    select: (data) => commonAPIDataSelector(data).items,
  });

  const { data: reporter } = useQuery({
    queryKey: QUERY_KEYS.USER_DETAILS(projectId, reporterId!),
    queryFn: () => api.getUsersDetailsV1(reporterId!),
    select: (data) => commonAPIDataSelector(data),
    enabled: isDefined(reporterId),
  });

  const { isPending: isSubmitting, mutate: create } = useMutation({
    mutationFn: (data: TicketRequest) => api.postTicketsV1(data),
    onSuccess: (response) => {
      showFlashToast({
        type: "success",
        title: t("page.tickets.create.action.create.notification.success"),
      });

      void navigate(routes.tickets.details({ slug, id: response.data.id }));
    },
    onError: () => {
      showFlashToast({
        type: "error",
        title: t("page.tickets.create.action.create.notification.error"),
        "data-testid": "request-error",
      });
    },
    onSettled: () => {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.TICKETS(projectId) });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.PORTFOLIO_TICKETS });
    },
  });

  const { uploadFormImage, isUploadingImage } = useUploadImage();

  async function onSubmit(data: LayoutFormValues) {
    const imageIds: string[] | undefined = [];
    if (data.images) {
      for (const image of data.images) {
        const uploadedImage = await uploadFormImage(image);
        if (uploadedImage) {
          imageIds.push(uploadedImage.id);
        }
      }
    }

    create({
      categoryId: category!.id,
      title: data.title,
      content: data.description,
      assigneeId: data.assignee?.id,
      imageIds: imageIds.length ? imageIds : undefined,
      reporterId: data.reporter?.id,
      visibility: data.visibility,
    });
  }

  const usersWithSessionUser = useMemo(() => {
    if (!sessionUser.address || users.some((x) => x.id === sessionUser.id)) {
      return users;
    }

    return [
      {
        ...sessionUser,
        roleId: sessionUser.role.id,
        locatedAt: "",
        isAdmin: sessionUser.role.type !== "resident",
        canBeDeleted: false,
        canBeEdited: false,
      } satisfies Partial<UserDetailsDto>,
      ...users,
    ];
  }, [users, sessionUser]);

  const isPageLoading = isCategoriesFetching || isUsersFetching;
  if (isPageLoading) {
    return <FullSizeLoader withPadding />;
  }

  const error = categoriesError || usersError || repairAssigneesError;
  if (error) {
    return <ErrorPage error={error} />;
  }

  // No categories available to create tickets in
  if (hasFetchedCategories && categories.length === 0) {
    return <ErrorPage status={403} />;
  }

  return (
    <>
      {children({
        categories: categories.filter((c) => !c.disabled),
        isLoadingOptions: isFetchingRepairAssignees,
        isSubmitting: isSubmitting || isUploadingImage,
        users: usersWithSessionUser,
        repairAssignees,
        selectedCategory: category,
        reporter,
        defaultValues: {
          title: "",
          description: "",
          visibility: "private",
        },
        sessionUser,
        onSubmit,
        onUpdateCategory: setCategory,
        onUpdateReporterId: setReporterId,
      })}
    </>
  );
}
