import { ErrorPage } from "components/Error/ErrorPage";
import type { RouteObject } from "react-router";

import { RegistrationPage } from "./pages";
import { registrationRoutes } from "./routes";

export const registrationRoute = [
  {
    path: registrationRoutes.main,
    element: <RegistrationPage />,
  },
  { path: "*", element: <ErrorPage status={404} /> },
] satisfies RouteObject[];
