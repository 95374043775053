import iconPlayCircle from "assets/icons/play-circle.svg";
import { Icon } from "components/Icon/Icon";
import { MediaXButton } from "components/Media/MediaXButton";
import type { FormVideo, InputFile } from "components/VideoInput/useVideoInput";
import { isVideoUploaded } from "components/VideoInput/useVideoInput";
import { formatFileSize } from "helpers/file-size";
import { isDefined } from "helpers/util";
import { useBool } from "hooks/useBool";
import type React from "react";
import { twJoin } from "tailwind-merge";

import { VideoPreviewDetailsModal } from "./VideoPreviewDetailsModal";
import { VideoPreviewProgressIndicator } from "./VideoPreviewProgressIndicator";

interface VideoPreviewProps {
  video: FormVideo;
  isInvalid?: boolean;
  isPlayable?: boolean;
  onDelete?: () => void;
}

export function VideoPreview({ video, isInvalid, isPlayable = true, onDelete }: VideoPreviewProps): React.ReactNode {
  const [isVideoModalOpened, videoModalOpenHandler] = useBool(false);

  const Component = isPlayable ? "button" : "div";
  let fileName: string | undefined = "";
  let fileSize: number | undefined = 0;
  let fileSrc: string = "";
  let uploadProgress: undefined | number = undefined;
  let isThumbnailShown = false;
  if (isVideoUploaded(video)) {
    fileName = video.fileName;
    fileSize = video.fileSize;
    fileSrc = video.url;
    uploadProgress = 100;
  } else {
    fileName = video.file.name;
    fileSize = video.file.size;
    fileSrc = video.url;
    isThumbnailShown = true;

    if (isDefined(video.uploadProgress)) {
      uploadProgress = video.uploadProgress;
    }
  }
  const fileExtension = fileName?.split(".").pop();

  return (
    <>
      <div
        className={twJoin(
          "flex max-h-20 w-fit min-w-[320px] max-w-[400px] items-center rounded-lg border bg-grey-100 text-left transition-colors",
          isInvalid ? "border-red-600" : "border-transparent",
          isPlayable ? "hocus:bg-grey-300/50" : undefined,
        )}
      >
        <Component
          className={twJoin("flex w-full items-center gap-4 p-4", isPlayable && "cursor-pointer")}
          type={isPlayable ? "button" : undefined}
          onClick={isPlayable ? videoModalOpenHandler.setTrue : undefined}
        >
          {isThumbnailShown ? (
            <video
              className="aspect-video h-12 shrink-0 overflow-hidden rounded-[4px] bg-black object-contain"
              src={fileSrc}
            />
          ) : (
            <Icon name={iconPlayCircle} size={32} />
          )}
          <div className="flex w-full flex-col justify-center gap-1 text-black">
            <span className="line-clamp-1 max-w-60 break-all text-left text-body-bold leading-[120%]">
              {fileName || "..."}
            </span>
            {isDefined(uploadProgress) && uploadProgress < 100 && (
              <VideoPreviewProgressIndicator progress={uploadProgress} />
            )}
            {(!isDefined(uploadProgress) || uploadProgress === 100) && isDefined(fileSize) && (
              <p className="text-left leading-none text-grey-600">{`${formatFileSize(fileSize, 0)} ${fileExtension?.toUpperCase() || ""}`}</p>
            )}
          </div>
        </Component>

        {onDelete && (
          <div className="ml-2 pr-4">
            <MediaXButton onClick={onDelete} />
          </div>
        )}
      </div>
      <VideoPreviewDetailsModal
        isOpened={isVideoModalOpened}
        videoName={fileName}
        videoSrc={fileSrc}
        onOpenChange={videoModalOpenHandler.set}
      />
    </>
  );
}

interface VideoPreviewForAppPreviewProps {
  video: InputFile;
}

export function VideoPreviewForAppPreview({ video }: VideoPreviewForAppPreviewProps): React.ReactNode {
  const fileExtension = video.file.name.split(".").pop();

  return (
    <div
      className={twJoin(
        "flex w-fit max-w-full items-center gap-2 rounded-lg bg-grey-100 p-3 pl-2 text-left transition-colors",
      )}
    >
      <Icon name={iconPlayCircle} size={24} />
      <div className="flex flex-col justify-center gap-1 text-black">
        <span className="line-clamp-1 max-w-60 break-all leading-none">{video.file.name}</span>
        <span className="leading-none text-grey-600">{`${formatFileSize(video.file.size, 0)} ${fileExtension?.toUpperCase() || ""}`}</span>
      </div>
    </div>
  );
}
