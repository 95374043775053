import { createColumnHelper, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import type { CompanyDto, UserDetailsDto, UserRoleDto } from "api/types";
import { Anchor } from "components/Anchor/Anchor";
import { Button } from "components/Button/Button";
import { Checkbox } from "components/Checkbox/Checkbox";
import type { ContextMenuAction } from "components/ContextMenu/ContextMenu";
import { ContextMenu } from "components/ContextMenu/ContextMenu";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { LoadingIcon } from "components/Icons/Icons";
import { Label } from "components/Label/Label";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { SearchInput } from "components/SearchInput/SearchInput";
import { Select } from "components/Select/Select";
import { Table } from "components/Table/Table";
import { UserAvatar } from "components/UserAvatar/UserAvatar";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useOnIntersection } from "hooks/useOnIntersection";
import { usePermission } from "hooks/usePermission";
import { useSlug } from "hooks/useSlug";
import { debounce } from "lodash-es";
import type { ChangeEvent } from "react";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { routes } from "routes";

import type { OnUpdateFilters, UsersParams } from "./Loader";

export interface LayoutProps {
  filters: UsersParams;
  onUpdateFilters: OnUpdateFilters;
  userTotal: number;
  userList: UserDetailsDto[];
  isLoadingUsers: boolean;
  hasMoreUsers: boolean | undefined;
  loadMoreUsers: () => void;
  isFetchingUsers: boolean;
  isLoadingMoreUsers: boolean;
  roles: UserRoleDto[];
  selectedCompany?: CompanyDto;
  companies?: CompanyDto[];
}

export function Layout({
  filters,
  onUpdateFilters,
  userTotal,
  userList,
  isLoadingUsers,
  hasMoreUsers,
  loadMoreUsers,
  isLoadingMoreUsers,
  isFetchingUsers,
  roles,
  selectedCompany,
  companies,
}: LayoutProps): React.ReactNode {
  const slug = useSlug();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const sessionUser = useSessionUser();
  const hasPermission = usePermission();
  const ref = useOnIntersection({
    threshold: 0,
    onIntersect: useCallback(() => {
      if (!isLoadingMoreUsers && hasMoreUsers) {
        loadMoreUsers();
      }
    }, [isLoadingMoreUsers, hasMoreUsers, loadMoreUsers]),
  });

  const columns = useMemo(() => {
    const helper = createColumnHelper<UserDetailsDto>();

    return [
      helper.accessor("avatar", {
        header: "",
        cell: ({ row }) => (
          <Anchor to={routes.users.details({ slug, id: row.original.id })}>
            <div className="my-2 size-9 shrink-0">
              <UserAvatar isUserDeleted={!!row.original.deletedAt} img={row.original.avatar} />
            </div>
          </Anchor>
        ),
        enableSorting: false,
        minSize: 90,
        size: 90,
        maxSize: 90,
      }),
      helper.accessor("firstName", {
        header: t("page.user-overview.table.first-name"),
        cell: (cell) =>
          cell.row.original.canViewProfile ? (
            <Anchor to={routes.users.details({ slug, id: cell.row.original.id })}>{cell.getValue()}</Anchor>
          ) : (
            <span>{cell.getValue()}</span>
          ),
      }),
      helper.accessor("lastName", {
        header: t("page.user-overview.table.last-name"),
        cell: (cell) =>
          cell.row.original.canViewProfile ? (
            <Anchor to={routes.users.details({ slug, id: cell.row.original.id })}>{cell.getValue()}</Anchor>
          ) : (
            <span>{cell.getValue()}</span>
          ),
      }),
      helper.accessor("deletedAt", {
        header: t("page.user-overview.table.status"),
        cell: (cell) => {
          if (cell.getValue()) {
            return <Label theme="red">{t("page.user-overview.table.status.deleted")}</Label>;
          } else if (cell.row.original.registeredAt) {
            return <Label theme="green">{t("page.user-overview.table.status.registered")}</Label>;
          } else {
            return <Label theme="yellow">{t("page.user-overview.table.status.invited")}</Label>;
          }
        },
        enableSorting: false,
      }),
      helper.accessor("locatedAt", {
        header:
          sessionUser.project.type === "addressBased"
            ? t("page.user-overview.table.address")
            : t("page.user-overview.table.company"),
      }),
      helper.accessor("roleId", {
        header: t("page.user-overview.table.role"),
        cell: (cell) => {
          const roleId = cell.getValue();
          const role = roles.find((role) => role.id === roleId);

          return <span>{role?.name || t("page.user-overview.table.role.super-admin")}</span>;
        },
        enableSorting: false,
      }),
      helper.accessor("id", {
        header: "",
        cell: (cell) => {
          const actions: ContextMenuAction[] = [];
          const id = cell.getValue();

          if (cell.row.original.canBeEdited) {
            actions.push({
              callback: () => {
                void navigate(routes.users.edit({ slug, id }));
              },
              text: t("page.user-overview.table.actions.edit-user"),
            });
          }

          if (sessionUser.isSuperAdmin && !cell.row.original.deletedAt) {
            actions.push({
              callback: () =>
                void navigate(routes.adminNotifications.otherUserSettings({ slug, userId: id }), {
                  state: {
                    fromPage: routes.users.list({ slug }),
                  },
                }),
              text: t("page.user-overview.table.actions.edit-notifications"),
            });
          }

          return (
            <div className="flex justify-end">
              <ContextMenu actions={actions} />
            </div>
          );
        },
        enableSorting: false,
        maxSize: 50,
        minSize: 50,
        size: 50,
      }),
    ];
  }, [navigate, roles, sessionUser.isSuperAdmin, sessionUser.project.type, slug, t]);

  const sortMapTableToApi = {
    firstName: "firstName",
    lastName: "lastName",
    locatedAt: sessionUser.project.type === "addressBased" ? "address" : "company",
  } as const;

  const sortMapApiToTable = {
    firstName: "firstName",
    lastName: "lastName",
    address: "locatedAt",
    company: "locatedAt",
  } as const;

  const sorting =
    filters.SortBy && filters.SortBy in sortMapApiToTable
      ? [
          {
            id: sortMapApiToTable[filters.SortBy as keyof typeof sortMapApiToTable],
            desc: filters.SortDescending ?? false,
          },
        ]
      : [];

  const usersTableInstance = useReactTable<UserDetailsDto>({
    columns: columns,
    data: userList,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
      columnVisibility: {
        roleId: sessionUser.isSuperAdmin || sessionUser.role.type === "projectOwner",
      },
    },
    onSortingChange(updater) {
      const newValue = typeof updater === "function" ? updater(sorting) : updater;
      const newSort = newValue?.[0];

      if (newSort && newSort.id in sortMapTableToApi) {
        const sortId = sortMapTableToApi[newSort.id as keyof typeof sortMapTableToApi];
        onUpdateFilters("SortBy", sortId);
        onUpdateFilters("SortDescending", newSort.desc);

        return;
      }

      onUpdateFilters("SortBy", undefined);
      onUpdateFilters("SortDescending", undefined);
    },
    manualSorting: true,
    enableSortingRemoval: false,
  });

  const onSearch = useMemo(
    () => debounce((e: ChangeEvent<HTMLInputElement>) => onUpdateFilters("Search", e.target.value), 500),
    [onUpdateFilters],
  );

  return (
    <DocumentPaper
      theme="minimal"
      title={t("page.user-overview.title")}
      subTitle={t("page.user-overview.subtitle")}
      actions={
        hasPermission((x) => x.userManagement.canInviteUser) ? (
          <Button type="link" data-testid="create-user-btn" href={routes.users.new({ slug })}>
            {t("page.user-overview.new-user")}
          </Button>
        ) : null
      }
      header={
        <div className="flex flex-wrap items-center gap-4">
          <SearchInput
            defaultValue={filters.Search}
            placeholder={t("page.user-overview.filters.search")}
            onChange={onSearch}
          />
          {companies ? (
            <Select
              items={companies}
              selected={selectedCompany}
              onChange={(e) => onUpdateFilters("CompanyId", e?.id)}
              keySelector={(x) => x.id}
              renderOption={(x) => x.name}
              emptyItem={t("page.user-overview.filters.companies.all")}
            />
          ) : null}
          {sessionUser.isAdmin && (
            <>
              <label className="flex items-center gap-2">
                <Checkbox
                  name={t("page.user-overview.filters.admins")}
                  onChange={(e) => onUpdateFilters("IncludeAdmins", e.target.checked)}
                  checked={filters.IncludeAdmins}
                />
                <span className="text-caption">{t("page.user-overview.filters.admins")}</span>
              </label>
              <label className="flex items-center gap-2">
                <Checkbox
                  name={t("page.user-overview.filters.invited")}
                  onChange={(e) => onUpdateFilters("IncludeNotRegistered", e.target.checked)}
                  checked={filters.IncludeNotRegistered}
                />
                <span className="text-caption">{t("page.user-overview.filters.invited")}</span>
              </label>
              {sessionUser.isSuperAdmin && (
                <label className="flex items-center gap-2">
                  <Checkbox
                    name={t("page.user-overview.filters.deleted")}
                    onChange={(e) => onUpdateFilters("IncludeDeleted", e.target.checked)}
                    checked={filters.IncludeDeleted}
                  />
                  <span className="text-caption">{t("page.user-overview.filters.deleted")}</span>
                </label>
              )}
            </>
          )}
        </div>
      }
    >
      {isLoadingUsers ? (
        <FullSizeLoader withPadding />
      ) : (
        <div className="flex flex-col gap-2">
          <span className="text-body-bold">{t("page.user-overview.total", { count: userTotal })}</span>
          <div className="overflow-auto">
            {userList.length > 0 ? (
              <Table
                table={usersTableInstance}
                data-testid="users-list"
                getRowProps={() => ({
                  "data-testid": "users-item",
                })}
                showSorting
                isLoading={isFetchingUsers && !isLoadingMoreUsers}
              />
            ) : (
              t("page.user-overview.no-data")
            )}
            {hasMoreUsers && (
              <div className="p-4" ref={ref}>
                <LoadingIcon className="inset-0 mx-auto my-4 w-6" />
              </div>
            )}
          </div>
        </div>
      )}
    </DocumentPaper>
  );
}
