import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { BuildingEditRequest } from "api/types";
import { ErrorPage } from "components/Error/ErrorPage";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { isDefined } from "helpers/util";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSlug } from "hooks/useSlug";
import { QUERY_KEYS } from "query-keys";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { routes } from "routes";

import type { LayoutProps } from "./Layout";

export function Loader(props: { children: (props: LayoutProps) => React.ReactNode }): React.ReactNode {
  const projectId = useProjectId();
  const slug = useSlug();
  const { id: buildingId } = useParams<{ id: string }>();
  const isEditMode = isDefined(buildingId);
  const api = useApi();
  const showFlashToast = useFlashToast();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {
    data: buildings,
    isFetching: isLoadingBuildings,
    error: buildingsError,
  } = useQuery({
    queryKey: QUERY_KEYS.BUILDING_LIST(projectId),
    queryFn: () => api.getBuildingsV1(),
    select: commonAPIDataSelector,
  });
  const {
    data: buildingDetails,
    isFetching: isLoadingBuildingDetails,
    error: buildingDetailsError,
  } = useQuery({
    queryKey: QUERY_KEYS.BUILDING_DETAILS(projectId, buildingId!),
    queryFn: () => api.getBuildingsDetailsV1(buildingId!),
    select: commonAPIDataSelector,
    enabled: isEditMode,
  });
  const createBuilding = useMutation({
    mutationFn: api.postBuildingsV1,
    onSuccess() {
      showFlashToast({ title: t("page.buildings.new.notification.success"), type: "success" });
      void navigate(routes.buildings.list({ slug }));
    },
    onError() {
      showFlashToast({ title: t("page.buildings.new.notification.failed"), type: "error" });
    },
    onSettled() {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.BUILDING_LIST(projectId) });
    },
  });
  const editBuilding = useMutation({
    mutationFn: (data: BuildingEditRequest) => api.putBuildingsV1(buildingId!, data),
    onSuccess() {
      showFlashToast({ title: t("page.buildings.edit.notification.success"), type: "success" });
      void navigate(routes.buildings.list({ slug }));
    },
    onError() {
      showFlashToast({ title: t("page.buildings.edit.notification.failed"), type: "error" });
    },
    onSettled() {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.BUILDING_LIST(projectId) });
    },
  });

  const existingBuildings = useMemo(
    () => buildings?.items.filter((x) => x.id !== buildingId) ?? [],
    [buildings, buildingId],
  );

  const error = buildingDetailsError || buildingsError;
  if (error) {
    return <ErrorPage error={error} />;
  }

  const isLoading = isLoadingBuildingDetails || isLoadingBuildings;
  if (isLoading) {
    return <FullSizeLoader withPadding />;
  }

  return props.children({
    isEditMode: isEditMode,
    defaultValues: isEditMode ? { name: buildingDetails!.name } : {},
    isSubmitting: editBuilding.isPending || createBuilding.isPending,
    existingBuildings,
    onSubmit(data) {
      if (isEditMode) {
        editBuilding.mutate({
          name: data.name,
        });
      } else {
        createBuilding.mutate({
          name: data.name,
        });
      }
    },
  });
}
