import { useQuery } from "@tanstack/react-query";
import type { FeelingAtHomeDistributionDto } from "api/types";
import { twResolve } from "helpers/tw-resolve";
import { useAnalyticsQueries } from "queries/analytics/queryOptions";
import type React from "react";
import { useTranslation } from "react-i18next";

const DEFAULT_DISTRIBUTIONS: FeelingAtHomeDistributionDto[] = [
  {
    type: "community",
    importance: 0,
    score: 0,
  },
  {
    type: "building",
    importance: 0,
    score: 0,
  },
  {
    type: "service",
    importance: 0,
    score: 0,
  },
];

export function HomefahWidgetDistributionSection(): React.ReactNode {
  const { t } = useTranslation();

  const analyticsQueries = useAnalyticsQueries();
  const { data } = useQuery(analyticsQueries.getFah());

  const getCategoryLabel = (type: FeelingAtHomeDistributionDto["type"]) => {
    switch (type) {
      case "community":
        return t("common.entity.community");
      case "building":
        return t("common.entity.building");
      case "service":
        return t("common.entity.service");
    }
  };

  // Show distributions in descending order of importance
  const distributions = data?.distributions || DEFAULT_DISTRIBUTIONS;
  const sortedDistributions = distributions.sort(
    (distributionA, distributionB) => distributionB.importance - distributionA.importance,
  );

  return (
    <div className="flex w-full flex-col items-center">
      <h2 className="text-center text-headline2">{t("components.analytics-widgets.fah-distribution.title")}</h2>
      <table className="h-fit w-full max-w-[400px] border-separate border-spacing-y-4 p-4">
        <tbody>
          <tr className="[&>*:not(:first-child)]:text-right [&>*]:text-overline">
            <th />
            <th>{t("components.analytics-widgets.fah-distribution.importance.label").toLowerCase()}</th>
            <th>{t("components.analytics-widgets.fah-distribution.score.label").toLowerCase()}</th>
          </tr>
          {sortedDistributions &&
            sortedDistributions.map((distribution) => {
              const labelImportance = distribution.importance
                ? `${(distribution.importance * 100).toFixed(1)}%`
                : t("common.label.not-applicable");
              const labelScore = distribution.score || "-";

              return (
                <tr
                  className="align-bottom [&>*:not(:first-child)]:text-right [&>*]:leading-none"
                  key={distribution.type}
                >
                  <td className="text-headline3">{getCategoryLabel(distribution.type)}</td>
                  <td
                    className={twResolve(
                      "text-headline1-medium leading-none",
                      distribution.type === "community" && "text-indigo",
                      distribution.type === "building" && "text-mint",
                      distribution.type === "service" && "text-orange",
                    )}
                  >
                    {labelImportance}
                  </td>
                  <td className="w-12 text-headline3">{labelScore}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
}
