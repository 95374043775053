import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { UserCreateRequest, UserUpdateRequest } from "api/types";
import { ErrorPage } from "components/Error/ErrorPage";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import type { FormImage } from "components/ImageInput/useImageInput";
import { parseISO } from "date-fns";
import { useLanguages } from "helpers/languages";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useUploadImage } from "hooks/Network/useUploadImage";
import { usePermission } from "hooks/usePermission";
import { useAddressQueries } from "queries/addresses/queryOptions";
import { QUERY_KEYS } from "query-keys";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

import type { FormValues, LayoutProps } from "./Layout";

interface LoaderProps {
  children: (props: LayoutProps) => React.ReactNode;
}

const SUPER_ADMIN_ROLE = { id: "superAdmin", name: "Super Admin", isAdmin: true };

export function Loader(props: LoaderProps): React.ReactNode {
  const projectId = useProjectId();
  const { t } = useTranslation();
  const api = useApi();
  const query = useQueryClient();
  const showFlashToast = useFlashToast();
  const { uploadFormImage, isUploadingImage } = useUploadImage();
  const sessionUser = useSessionUser();
  const { id: userIdParam } = useParams<{ id: string }>();
  const hasPermission = usePermission();

  const isEditMode = !!userIdParam;
  const isCreateMode = !isEditMode;
  const isSelfEdit = sessionUser.id === userIdParam;
  const canLocationBeEdited =
    !isSelfEdit ||
    hasPermission((x) => (x.isResident ? x.userManagement.canEditResidentInfo : x.userManagement.canEditAdminInfo));

  const addressQueries = useAddressQueries();
  const {
    data: addresses,
    isLoading: isLoadingAddresses,
    error: errorLoadingAddresses,
  } = useQuery({
    ...addressQueries.getAddresses(),
    enabled: sessionUser.project.type === "addressBased" && (isCreateMode || canLocationBeEdited),
  });

  const {
    data: companies,
    isLoading: isLoadingCompanies,
    error: errorLoadingCompanies,
  } = useQuery({
    queryKey: QUERY_KEYS.COMPANY_LIST(projectId),
    queryFn: () => api.getCompaniesV1({ Limit: 10000, Offset: 0 }),
    select: commonAPIDataSelector,
    enabled: sessionUser.project.type === "companyBased" && (isCreateMode || canLocationBeEdited),
  });

  const {
    data: rolesData = [],
    isLoading: isLoadingRoles,
    error: errorLoadingRoles,
  } = useQuery({
    queryKey: QUERY_KEYS.ROLE_LIST(projectId),
    queryFn: () => api.getPermissionsRolesV1(),
    select: commonAPIDataSelector,
    enabled: hasPermission((x) => x.userManagement.canAssignRoles),
  });

  const { data: languages, isFetching: isLoadingLanguages, error: errorLanguages } = useLanguages();

  const checkEmailExists = useMutation({
    mutationFn: (email: string) => api.postUsersEmailExistsV1({ email }).then((x) => x.data),
  });

  async function uploadImageAndCreateUser(payload: UserCreateRequest, image: FormImage[]) {
    const newPayload = { ...payload };

    if (image.length > 0) {
      const uploadedImage = await uploadFormImage(image[0]);

      newPayload.avatarId = uploadedImage?.id;
    }

    return await createUser.mutateAsync(newPayload);
  }

  const createUser = useMutation({
    mutationFn: (payload: UserCreateRequest) => api.postUsersV1(payload).then((x) => x.data),
    onSuccess: () => {
      showFlashToast({ type: "success", title: t("page.create-user.new.success") });
    },
    onError: () => {
      showFlashToast({ type: "error", title: t("page.create-user.new.error") });
    },
  });

  async function uploadImageAndEditUser(payload: UserUpdateRequest, image: FormImage[]) {
    const newPayload = { ...payload };
    if (image.length > 0) {
      const uploadedImage = await uploadFormImage(image[0]);

      newPayload.avatarId = uploadedImage?.id;
    }

    return await editUser.mutateAsync(newPayload);
  }

  const editUser = useMutation({
    mutationFn: (payload: UserUpdateRequest) => api.putUsersV1(userIdParam!, payload).then((x) => x.data),
    onSuccess: () => {
      showFlashToast({ type: "success", title: t("page.create-user.edit.success") });
      if (isSelfEdit) {
        void query.invalidateQueries();
      } else {
        void query.invalidateQueries({ queryKey: QUERY_KEYS.USER_DETAILS(projectId, userIdParam!) });
      }
    },
    onError: () => {
      showFlashToast({ type: "error", title: t("page.create-user.edit.error") });
    },
  });

  const {
    data: user,
    isLoading: isLoadingUser,
    error: errorLoadingUser,
  } = useQuery({
    queryKey: QUERY_KEYS.USER_DETAILS(projectId, userIdParam!),
    queryFn: () => api.getUsersDetailsV1(userIdParam!),
    select: commonAPIDataSelector,
    enabled: !!userIdParam,
  });

  const error =
    errorLoadingAddresses || errorLoadingCompanies || errorLoadingRoles || errorLoadingUser || errorLanguages;
  if (error) {
    return <ErrorPage error={error} />;
  }

  const loading = isLoadingAddresses || isLoadingCompanies || isLoadingRoles || isLoadingUser || isLoadingLanguages;
  if (loading) {
    return <FullSizeLoader withPadding />;
  }

  if (userIdParam && user && !user.canBeEdited) {
    return <ErrorPage status={403} />;
  }

  const roles = rolesData.map((role) => ({
    id: role.id,
    name: role.name,
    isAdmin: role.type !== "resident",
  }));
  if (sessionUser.isSuperAdmin) {
    roles.push(SUPER_ADMIN_ROLE);
  }

  const defaultFormValues: FormValues =
    userIdParam && user
      ? {
          firstName: user.firstName,
          lastName: user.lastName,
          mobileNumber: user.phoneNumber ?? "",
          email: user.email ?? "",
          address: addresses?.items.find((address) => address.id === user.addressId),
          company: companies?.items.find((company) => company.id === user.companyId),
          occupation: user.occupation ?? "",
          language: languages?.find((language) => language.id === user.languageId),
          role: user.isSuperAdmin ? SUPER_ADMIN_ROLE : roles.find((x) => x.id === user.roleId),
          avatar: user.avatar ? [user.avatar] : [],
          hideChat: !user.chatEnabled,
          deactivateAfter: !!user.deleteAfter,
          deactivateAfterDate: user.deleteAfter ? parseISO(user.deleteAfter) : "",
          addMore: false,
          onboardingComplete: !!user.registeredAt,
          isOnlyAllowedOwnTickets: user.isOnlyAllowedOwnTickets,
          mfaMethod: user.mfaMethod === undefined ? "automatic" : user.mfaMethod,
        }
      : {
          firstName: "",
          lastName: "",
          mobileNumber: "",
          email: "",
          address: undefined,
          company: undefined,
          occupation: "",
          role: roles?.find((x) => !x.isAdmin),
          avatar: [],
          hideChat: false,
          deactivateAfter: false,
          deactivateAfterDate: "",
          addMore: false,
          onboardingComplete: false,
          isOnlyAllowedOwnTickets: false,
          language: languages?.[0],
          mfaMethod: "automatic",
        };

  // emailExists endpoint does not work without list users permission. So maintenance users won't be able to change their own email. We can do this better when we build an email change flow in dashboard.
  const canEmailBeEdited = user ? user.canEmailBeEdited && hasPermission((x) => x.userManagement.canListUsers) : true;

  return props.children({
    addresses: addresses?.items ?? [],
    companies: companies?.items ?? [],
    roles: roles ?? [],
    languages: languages ?? [],
    checkEmailExists: checkEmailExists.mutateAsync,
    createUser: uploadImageAndCreateUser,
    updateUser: uploadImageAndEditUser,
    defaultFormValues: defaultFormValues,
    canEmailBeEdited,
    canRoleBeEdited: user ? user.canRoleBeEdited : true,
    isSubmitting: createUser.isPending || editUser.isPending || isUploadingImage,
    canLocationBeEdited,
    isEditMode,
    isSelfEdit,
  });
}
