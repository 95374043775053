import { Navigate } from "react-router";

import { AuthenticationState, useAuthenticationState } from "./AuthenticationStateProvider";

export function NotLoggedInGuard({ children }: { children: React.ReactNode }): React.ReactNode {
  const state = useAuthenticationState();

  if (state === AuthenticationState.Loading) {
    return null;
  }

  if (state !== AuthenticationState.NogLoggedIn) {
    return <Navigate to="/" />;
  }

  return children;
}
