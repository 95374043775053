import { useApiClient } from "api/hooks/useApi";
import type { VideoDto } from "api/types";
import { MAXIMUM_VIDEO_FILE_SIZE_IN_MEGA_BYTES, megaBytesToBytes } from "helpers/file-size";
import { ALLOWED_FILE_TYPES } from "helpers/file-types";
import { CANCEL_TOKENS } from "queries/files/helpers";
import { useCallback } from "react";

export type FormVideo = VideoDto | InputFile;

export interface InputFile {
  file: File;
  url: string;
  uploadPromise?: Promise<VideoDto | undefined>;
  uploadProgress?: number;
}

interface Props {
  selectedVideos: FormVideo[];
  maximumFiles?: number;
  onChange: (videos: FormVideo[]) => void;
  uploadFn?: (video: FormVideo) => Promise<VideoDto | undefined>;
}

interface VideoInput {
  readonly addVideo: (file: File) => void;
  readonly addVideos: (files: FileList | null) => void;
  readonly replaceVideo: (oldFile: FormVideo, newFile: File) => void;
  readonly removeVideo: (imageToRemove: FormVideo) => void;
  readonly removeVideos: () => void;
}

export function useVideoInput({ selectedVideos, maximumFiles = 1, onChange, uploadFn }: Props): VideoInput {
  const apiClient = useApiClient();

  const removeVideo = useCallback(
    (videoToRemove: FormVideo) => {
      const newSelectedVideos = [];

      for (const video of selectedVideos) {
        if (videoToRemove.url === video.url) {
          if (!isVideoUploaded(video)) {
            // Revoke local file reference url
            revokeFileUrl([video]);
            // Cancel on-going request for the file
            apiClient.abortRequest(CANCEL_TOKENS.UPLOAD_VIDEO(video.file.name));
          }
        } else {
          newSelectedVideos.push(video);
        }
      }
      onChange(newSelectedVideos);
    },
    [apiClient, onChange, selectedVideos],
  );

  const removeVideos = useCallback(() => {
    for (const video of selectedVideos) {
      removeVideo(video);
    }
    onChange([]);
  }, [onChange, selectedVideos, removeVideo]);

  const addVideos = useCallback(
    (files: FileList | File[] | null) => {
      if (!files) {
        return;
      }

      const newlyAddedVideos: InputFile[] = Array.from(files)
        .slice(0, maximumFiles)
        .map((file) => {
          const videoFile: InputFile = {
            url: URL.createObjectURL(file),
            file,
          };
          const isVideoSizeValid = file.size <= megaBytesToBytes(MAXIMUM_VIDEO_FILE_SIZE_IN_MEGA_BYTES);
          const isVideoTypeValid = ALLOWED_FILE_TYPES.VIDEO.includes(file.type);

          // Prevent video from calling `uploadFn` callback if it does not match acceptance criteria
          if (isVideoSizeValid && isVideoTypeValid && uploadFn) {
            videoFile.uploadPromise = uploadFn(videoFile).catch(() => {
              removeVideo(videoFile);

              return undefined;
            });
          }

          return videoFile;
        });

      const newSelectedVideos = [...selectedVideos, ...newlyAddedVideos].filter((video) => Boolean(video));

      if (newSelectedVideos.length > maximumFiles) {
        const omittedVideos = newSelectedVideos.splice(0, newSelectedVideos.length - maximumFiles);

        omittedVideos.forEach((video) => !isVideoUploaded(video) && removeVideo(video));
      }

      onChange(newSelectedVideos);
    },
    [maximumFiles, uploadFn, onChange, removeVideo, selectedVideos],
  );

  const addVideo = useCallback((file: File) => addVideos([file]), [addVideos]);

  const replaceVideo = useCallback(
    (oldVideo: FormVideo, newFile: File) => {
      if (!newFile) {
        return;
      }

      const newSelectedVideos = [];
      for (const video of selectedVideos) {
        if (oldVideo.url === video.url) {
          revokeFileUrl([video]);
          newSelectedVideos.push({ url: URL.createObjectURL(newFile), file: newFile });
        } else {
          newSelectedVideos.push(video);
        }
      }

      onChange(newSelectedVideos);
    },
    [onChange, selectedVideos],
  );

  return { addVideo, addVideos, replaceVideo, removeVideo, removeVideos } as const;
}

export function isVideoUploaded(video?: FormVideo): video is VideoDto {
  return video ? "id" in video : false;
}

function revokeFileUrl(videos: FormVideo[]) {
  for (const video of videos) {
    if (!isVideoUploaded(video)) {
      URL.revokeObjectURL(video.url);
    }
  }
}
