import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { SelfUpdateRequest } from "api/types";
import { useProjectId } from "hooks/Network/useProjectId";
import { QUERY_KEYS } from "query-keys";

const usePutSelfMutation = (opts: { onError: (error: unknown) => void; onSuccess: () => void }) => {
  const api = useApi();
  const queryClient = useQueryClient();
  const projectId = useProjectId();

  return useMutation({
    mutationFn: (payload: SelfUpdateRequest) => api.putSelfV3(payload),
    onSettled() {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.SELF(projectId) });
    },
    ...opts,
  });
};

export const userMutations = {
  usePutSelfMutation,
};
