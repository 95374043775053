import { Overlay } from "components/Overlay/Overlay";
import { ProjectSwitcher } from "components/ProjectSwitcher/ProjectSwitcher";
import { AnimatePresence } from "motion/react";
import type React from "react";
import { type CSSProperties, forwardRef } from "react";
import { createPortal } from "react-dom";

interface ProjectSidebarProjectSwitcherProps {
  style: CSSProperties;
  isOpened: boolean;
  onClose: () => void;
}

// eslint-disable-next-line react/display-name
export const ProjectSidebarProjectSwitcher = forwardRef<HTMLDivElement, ProjectSidebarProjectSwitcherProps>(
  ({ style, isOpened, onClose }, ref): React.ReactNode => {
    return createPortal(
      <>
        <div className="absolute z-50 max-w-sm md:!-top-4 md:!left-16 md:w-96" {...{ ref, style }}>
          <ProjectSwitcher isOpen={isOpened} onClose={onClose} showRecentProjects showCurrentProject={false} />
        </div>
        <AnimatePresence>{isOpened && <Overlay onClick={onClose} className="z-40" />}</AnimatePresence>
      </>,
      document.body,
    );
  },
);
