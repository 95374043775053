import { Carousel as ArkCarousel, Portal as ArkPortal } from "@ark-ui/react";
import type { ImageDto } from "api/types";
import IconChevronLeft from "assets/icons/chevron-left.svg";
import IconChevronRight from "assets/icons/chevron-right.svg";
import { IconButton } from "components/Button/IconButton";
import { Icon } from "components/Icon/Icon";
import type { FormImage } from "components/ImageInput/useImageInput";
import { preloadImage } from "helpers/image";
import { twResolve } from "helpers/tw-resolve";
import { useKey } from "hooks/useKey";
import { AnimatePresence, type AnimationProps, motion } from "motion/react";
import type React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { twJoin } from "tailwind-merge";

const commonAnimProps: AnimationProps = {
  initial: "initial",
  animate: "animate",
  exit: "initial",
  transition: { duration: 0.2 },
};

type CarouselImage = {
  // Use type to guard in case of type mutation
  // eslint-disable-next-line @typescript-eslint/no-duplicate-type-constituents
  url: ImageDto["url"] | FormImage["url"];
  description?: ImageDto["description"];
};

export type CarouselProps = {
  images: CarouselImage[];
  defaultPage?: number;
  objectFit?: React.CSSProperties["objectFit"];
} & (
  | {
      styling: "overlay";
      allowZoom?: never;
    }
  | {
      styling?: "default";
      allowZoom?: boolean;
    }
);

export function Carousel({
  images,
  defaultPage = 0,
  objectFit = "cover",
  allowZoom,
  styling = "default",
}: CarouselProps): React.ReactNode {
  const [activePage, setActivePage] = useState(defaultPage);
  const [zoomedPage, setZoomedPage] = useState<number | null>(null);

  const { t } = useTranslation();

  // Preload next slide
  useEffect(() => {
    const nextImage = images[activePage + 1];

    if (nextImage) {
      preloadImage(nextImage.url);
    }
  }, [activePage, images]);

  useKey(
    "Escape",
    () => {
      setZoomedPage(null);
    },
    zoomedPage !== null,
  );

  // Arrow key navigation only enabled for "overlay" styling
  useKey(
    "ArrowLeft",
    () => {
      setActivePage(activePage - 1);
    },
    activePage > 0 && zoomedPage === null && styling === "overlay",
  );

  useKey(
    "ArrowRight",
    () => {
      setActivePage(activePage + 1);
    },
    activePage < images.length - 1 && zoomedPage === null && styling === "overlay",
  );

  const onZoom = (index: number) => {
    setZoomedPage(index);
  };

  return (
    <>
      <ArkCarousel.Root
        className="relative size-full overflow-hidden"
        page={activePage}
        onPageChange={(details) => {
          if (details.page >= 0) {
            setActivePage(details.page);
          }
        }}
      >
        <ArkCarousel.ItemGroup className="h-full">
          {images.map((image, index) => (
            <ArkCarousel.Item
              key={index}
              onClick={allowZoom ? () => onZoom(index) : undefined}
              className={twJoin("bg-black", allowZoom && "cursor-zoom-in")}
              {...{ index }}
            >
              <img className="size-full" style={{ objectFit }} src={image.url} alt={image.description} />
            </ArkCarousel.Item>
          ))}
        </ArkCarousel.ItemGroup>
        <span className="pointer-events-none absolute bottom-0 left-0 z-0 h-16 w-full bg-gradient-to-t from-black/60 to-black/0" />

        <ArkCarousel.Control className="absolute left-0 top-1/2 flex w-full -translate-y-1/2 items-center justify-between px-2">
          <ArkCarousel.PrevTrigger asChild>
            <IconButton title={t("common.action.previous")} size="sm" withTooltip={false} styling="primary" isCircular>
              <Icon name={IconChevronLeft} />
            </IconButton>
          </ArkCarousel.PrevTrigger>
          <ArkCarousel.NextTrigger asChild>
            <IconButton title={t("common.action.next")} size="sm" withTooltip={false} styling="primary" isCircular>
              <Icon name={IconChevronRight} />
            </IconButton>
          </ArkCarousel.NextTrigger>
        </ArkCarousel.Control>

        <ArkCarousel.IndicatorGroup className="absolute bottom-0 left-1/2 z-10 flex -translate-x-1/2 items-center gap-2 px-2 py-3">
          {images.map((_, index) => (
            <ArkCarousel.Indicator onClick={() => setActivePage(index)} key={index} asChild {...{ index }}>
              <button
                type="button"
                className={twJoin(
                  "size-[10px] rounded-full transition",
                  index === activePage ? "bg-white" : "bg-black/60",
                )}
              />
            </ArkCarousel.Indicator>
          ))}
        </ArkCarousel.IndicatorGroup>
      </ArkCarousel.Root>
      {allowZoom && (
        <ArkPortal>
          <AnimatePresence>
            {zoomedPage !== null && (
              <div className="fixed left-0 top-0 z-40 size-full cursor-zoom-out" onClick={() => setZoomedPage(null)}>
                {/* Overlay */}
                <motion.span
                  className={twResolve("absolute left-0 top-0 -z-10 size-full bg-black/60")}
                  variants={{
                    initial: { opacity: 0 },
                    animate: { opacity: 1 },
                  }}
                  {...commonAnimProps}
                />
                <motion.div
                  className="z-10 flex h-screen w-screen items-center justify-center p-6 md:p-16"
                  variants={{
                    initial: { opacity: 0, scale: 0.98 },
                    animate: { opacity: 1, scale: 1 },
                  }}
                  {...commonAnimProps}
                >
                  <img
                    className="max-h-full max-w-full rounded-lg"
                    src={images[zoomedPage].url}
                    alt={images[zoomedPage].description}
                  />
                </motion.div>
              </div>
            )}
          </AnimatePresence>
        </ArkPortal>
      )}
    </>
  );
}
