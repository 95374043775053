import type { DocumentDto } from "api/types";
import type { FormDocument } from "components/DocumentInput/useDocumentFile";
import { isDocumentUploaded } from "components/DocumentInput/useDocumentFile";
import type { UploadDocumentRequest } from "queries/files";
import { fileMutations } from "queries/files";
import { useCallback } from "react";

export function useUploadDocument(): {
  uploadDocument: UploadDocument;
  uploadFormDocument: UploadFormDocument;
  isUploadingDocument: boolean;
} {
  const { mutateAsync: uploadDocument, isPending: isUploadingDocument } = fileMutations.useAddDocument();

  const uploadFormDocument: UploadFormDocument = useCallback(
    async (formDocument) => {
      if (isDocumentUploaded(formDocument)) {
        return formDocument;
      }

      if (formDocument.uploadPromise) {
        return formDocument.uploadPromise;
      }

      return await uploadDocument(formDocument);
    },
    [uploadDocument],
  );

  return { uploadDocument, uploadFormDocument, isUploadingDocument };
}

export type UploadDocument = (image: UploadDocumentRequest) => Promise<DocumentDto>;
export type UploadFormDocument = (document: FormDocument) => Promise<DocumentDto | undefined>;
