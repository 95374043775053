import { useProjectId } from "hooks/Network/useProjectId";
import { clearSessionStorage } from "hooks/useLocalStorage";
import { useEffect } from "react";
import { matchPath, useLocation } from "react-router";
import { routes } from "routes";

export const ticketStorageKeys = {
  // Stored in session storage
  residentFocusMode: () => `resident-focus`,
  filters: (projectId: string) => `${projectId}-ticket-filters` as const,
  portfolio_filters: () => `portfolio-ticket-filters`,
  // Stored in local storage
  tabSort: () => `tab-sorting`,
} as const;

export function clearTicketsStorage(projectId: string): void {
  clearSessionStorage(ticketStorageKeys.residentFocusMode());
  clearSessionStorage(ticketStorageKeys.filters(projectId));
}

// This hook clears the tickets cache when the user navigates away from the tickets page
export function useClearTicketsCache(): void {
  const projectId = useProjectId();
  const location = useLocation();

  useEffect(() => {
    if (matchPath(routes.tickets.ROOT, location.pathname)) {
      return;
    }

    if (matchPath(routes.ticketCategories.ROOT, location.pathname)) {
      return;
    }

    if (matchPath(routes.ticketStatuses.ROOT, location.pathname)) {
      return;
    }

    if (matchPath(routes.users.details.PATH, location.pathname)) {
      return;
    }

    clearTicketsStorage(projectId);
  }, [location, projectId]);
}
