import { ErrorPage } from "components/Error/ErrorPage";
import { RequireAuth } from "components/Routing/RequireAuth";
import { TicketCategoryDetailsPage } from "modules/ticket-categories/pages/Details";
import { TicketCategoryListPage } from "modules/ticket-categories/pages/List";
import type { RouteObject } from "react-router";

import { canListCategories, canManageCategories } from "./permissions";
import { ticketCategoriesRoutes } from "./routes";

export const ticketCategoriesRoute = [
  {
    path: ticketCategoriesRoutes.list,
    element: (
      <RequireAuth permission={canListCategories}>
        <TicketCategoryListPage />
      </RequireAuth>
    ),
  },
  {
    path: ticketCategoriesRoutes.create,
    element: (
      <RequireAuth permission={canManageCategories}>
        <TicketCategoryDetailsPage />
      </RequireAuth>
    ),
  },
  {
    path: ticketCategoriesRoutes.edit,
    element: (
      <RequireAuth permission={canManageCategories}>
        <TicketCategoryDetailsPage />
      </RequireAuth>
    ),
  },
  { path: "*", element: <ErrorPage status={404} /> },
] satisfies RouteObject[];
