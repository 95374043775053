import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { ErrorPage } from "components/Error/ErrorPage";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { isDefined } from "helpers/util";
import { useProjectId } from "hooks/Network/useProjectId";
import { QUERY_KEYS } from "query-keys";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

import type { LayoutProps } from "./Layout";

interface LoaderProps {
  children: (props: LayoutProps) => React.ReactNode;
}

export function Loader({ children }: LoaderProps): React.ReactNode {
  const projectId = useProjectId();
  const api = useApi();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const showFlashToast = useFlashToast();

  const { aid: assetId, rid: reservationId } = useParams<{ aid: string; rid: string }>();

  const {
    data: reservation,
    isPending: isLoading,
    error: detailsError,
  } = useQuery({
    queryKey: QUERY_KEYS.BOOKINGS_RESERVATION_DETAILS(projectId, assetId!, reservationId!),
    queryFn: () => api.getBookableAssetsBookingsDetailsV1(reservationId!, assetId!),
    select: commonAPIDataSelector,
    enabled: isDefined(assetId) && isDefined(reservationId),
  });

  const deleteReservation = useMutation({
    mutationFn: () =>
      api.deleteBookableAssetsBookingsByIdV1(reservationId!, assetId!, { reason: undefined }).then((x) => x.data),
    onSuccess: () => {
      showFlashToast({
        type: "success",
        title: t("page.bookings.reservation-details.delete.notification.success"),
      });

      void queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.BOOKINGS_RESERVATION_DETAILS(projectId, assetId!, reservationId!),
      });
    },
    onError: () =>
      showFlashToast({
        type: "error",
        title: t("page.bookings.reservation-details.delete.notification.error"),
      }),
  });

  const error = !isDefined(assetId) || !isDefined(reservationId) || detailsError;
  if (error) {
    return <ErrorPage error={error} />;
  }

  const loading = isLoading || reservation === undefined;
  if (loading) {
    return <FullSizeLoader withPadding />;
  }

  return children({
    reservation,
    onDelete: deleteReservation.mutateAsync,
  });
}
