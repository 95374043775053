import { extendTailwindMerge } from "tailwind-merge";
import { createTV } from "tailwind-variants";

export const twConfig = {
  extend: {
    theme: {
      colors: [
        "aop-off-white",
        "aop-basic-blue-600",
        "aop-basic-blue-500",
        "aop-basic-blue-100",
        "aop-dark-blue-500",
        "aop-dark-blue-400",
        "aop-dark-blue-200",
        "aop-bright-purple-500",
        "aop-bright-purple-200",
        "aop-bright-purple-100",
        "grey-50",
        "aop-chat-green-100",
        "transparent",
        "current",
        "black",
        "white",
        "grey-900",
        "grey-700",
        "grey-600",
        "grey-500",
        "grey-400",
        "grey-300",
        "grey-100",
        "grey-50",
        "blue-800",
        "blue-700",
        "blue-600",
        "blue-500",
        "blue-400",
        "blue-200",
        "blue-100",
        "green-800",
        "green-700",
        "green-600",
        "green-500",
        "green-400",
        "green-200",
        "green-100",
        "yellow-800",
        "yellow-700",
        "yellow-600",
        "yellow-500",
        "yellow-400",
        "yellow-200",
        "yellow-100",
        "red-800",
        "red-700",
        "red-600",
        "red-500",
        "red-400",
        "red-200",
        "red-100",
        "orange",
        "mint",
        "teal",
        "cyan",
        "indigo",
        "purple",
        "pink",
        "brown",
        "app-grey",
        "app-grey-1",
      ],
    },
    classGroups: {
      "font-size": [
        "text-headline1",
        "text-headline1-medium",
        "text-headline2",
        "text-headline3",
        "text-headline4",
        "text-body-bold",
        "text-body",
        "text-body-italic",
        "text-caption-bold",
        "text-caption",
        "text-overline-bold",
        "text-overline",
        "text-btn-label-bold",
        "text-btn-label",
      ],
      leading: [
        "leading-old-headline4",
        "leading-old-6",
        "leading-old-10",
        "leading-old-tight",
        "leading-input",
        "leading-none",
      ],
      "font-style": ["text-body-italic"],
      "font-family": ["font-icon", "font-sans"],
      shadow: ["shadow-azure"],
      "border-w": ["border-1rem"],
    },
  },
} satisfies Parameters<typeof extendTailwindMerge>[0];

export const twResolve = extendTailwindMerge(twConfig);
export const twVariants = createTV({
  twMergeConfig: twConfig,
});
