import { infiniteQueryOptions, queryOptions } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { QUERY_KEYS } from "query-keys";

export const useAddressQueries = () => {
  const projectId = useProjectId();
  const api = useApi();

  const ADDRESS_AMOUNT = 40;

  return {
    getAddresses: (search?: string, addressTypes?: string[]) =>
      queryOptions({
        queryKey: QUERY_KEYS.ADDRESS_LIST(projectId),
        queryFn: () =>
          api.getAddressesV1({
            Search: search,
            AddressType: addressTypes,
            Limit: 10000,
          }),
        select: commonAPIDataSelector,
      }),
    getAddressesInfinite: (search?: string, addressTypes?: string[]) =>
      infiniteQueryOptions({
        queryKey: QUERY_KEYS.ADDRESS_LIST_INFINITE(projectId, search, addressTypes),
        queryFn: ({ pageParam = 0 }) =>
          api.getAddressesV1({
            Offset: pageParam * ADDRESS_AMOUNT,
            Limit: ADDRESS_AMOUNT,
            Search: search,
            AddressType: addressTypes,
          }),
        initialPageParam: 0,
        getNextPageParam: (lastPage, pages) => {
          if (!lastPage.data.hasMore) {
            return undefined;
          }

          return pages.length;
        },
      }),
    getAddressStats: () =>
      queryOptions({
        queryKey: QUERY_KEYS.ADDRESS_STATS(projectId),
        queryFn: () => api.getAddressesStatsV1(),
        select: commonAPIDataSelector,
      }),
    getAddressTypes: () =>
      queryOptions({
        queryKey: QUERY_KEYS.ADDRESS_TYPES(projectId),
        queryFn: () => api.getAddressesTypesV1(),
        select: commonAPIDataSelector,
      }),
  };
};
