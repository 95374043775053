import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { GroupChatInfoDto, RemoveMemberRequest, UpdateGroupChatRequest, UserDto } from "api/types";
import { ConfirmModal } from "components/ConfirmModal/ConfirmModal";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { Form } from "components/Form/Form";
import type { FormImage } from "components/ImageInput/useImageInput";
import type { ModalBaseProps } from "components/Modal/Modal";
import { Modal } from "components/Modal/Modal";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useUploadImage } from "hooks/Network/useUploadImage";
import { useBool } from "hooks/useBool";
import { useSlug } from "hooks/useSlug";
import { QUERY_KEYS } from "query-keys";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { routes } from "routes";

import { EditGroupChatModalAddMembersView } from "./EditGroupChatModalAddMembersView";
import { EditGroupChatModalInfoView } from "./EditGroupChatModalInfoView";

type EditGroupChatModalProps = ModalBaseProps & {
  chatId: string;
  groupInfo: GroupChatInfoDto;
};

export interface EditGroupChatFormValues {
  groupName: string;
  groupImage: FormImage[];
  newMembers: UserDto[];
}

enum Step {
  GroupInfo,
  AddMembers,
}

export function EditGroupChatModal({
  isOpened,
  onOpenChange,
  chatId,
  groupInfo,
}: EditGroupChatModalProps): React.ReactNode {
  const { t } = useTranslation();
  const slug = useSlug();
  const api = useApi();
  const queryClient = useQueryClient();
  const projectId = useProjectId();
  const sessionUser = useSessionUser();

  const navigate = useNavigate();
  const showFlashToast = useFlashToast();

  const [step, setStep] = useState<Step>(Step.GroupInfo);
  const [userToRemove, setUserToRemove] = useState<UserDto | null>(null);
  const [isLeaveModalOpen, leaveModalHandler] = useBool(false);

  const { uploadFormImage, isUploadingImage } = useUploadImage();
  const formMethods = useForm<EditGroupChatFormValues>({
    defaultValues: { groupName: groupInfo.name, groupImage: [groupInfo.image], newMembers: [] },
  });

  // Reset form state when the group is edited and new data is available
  useEffect(() => {
    formMethods.reset({ groupName: groupInfo.name, groupImage: [groupInfo.image], newMembers: [] });
  }, [formMethods, groupInfo]);

  const mutationLeaveGroupChat = useMutation({
    mutationFn: () => api.postChatsMembersLeaveV2(chatId),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.CHATS_LIST(projectId) });

      showFlashToast({ type: "success", title: t("component.group-chat-leave-modal.success") });
      void navigate(routes.chats.list({ slug: slug }));
    },
    onError: () => {
      showFlashToast({
        type: "error",
        title: t("component.group-chat-leave-modal.error"),
      });
    },
    onSettled: () => {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.CHATS_MEMBERS(projectId, chatId) });
    },
  });
  const { mutateAsync: removeMember, isPending: isRemovingMember } = useMutation({
    mutationFn: (payload: RemoveMemberRequest) => api.putChatsMembersRemoveV2(chatId, payload),
    onSettled: () => {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.CHATS_MEMBERS(projectId, chatId) });
    },
    onSuccess: () => {
      setUserToRemove(null);
    },
    onError: () => {
      showFlashToast({
        type: "error",
        title: t("component.group-chat-remove-user-modal.error"),
      });
    },
  });
  const { mutateAsync: updateGroupChat } = useMutation({
    mutationFn: (payload: UpdateGroupChatRequest) => api.putChatsGroupV2(chatId, payload),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.CHATS_DETAILS(projectId, chatId) });
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.CHATS_LIST(projectId) });
      onOpenChange(false);
    },
  });

  useEffect(() => {
    if (!isOpened) {
      formMethods.reset();
      setStep(0);
    }
  }, [isOpened, formMethods]);

  const onSubmit = async (data: EditGroupChatFormValues) => {
    const payload: UpdateGroupChatRequest = { name: data.groupName.trim() };

    if (data.groupImage.length > 0) {
      const uploadedImage = await uploadFormImage(data.groupImage[0]);

      payload.imageId = uploadedImage?.id;
    }

    void updateGroupChat(payload);
  };

  const handlePrevStep = (step: Step) => {
    setStep(step);
  };

  const handleNextStep = (step: Step) => {
    setStep(step);
  };

  let title = "";
  switch (step) {
    case Step.GroupInfo:
      title = t("component.group-chat-info-modal.title");
      break;
    case Step.AddMembers:
      title = t("page.chats.modal.new-chat.group-chat.select-member.title");
      break;
  }

  return (
    <Modal.Root {...{ title, isOpened, onOpenChange }}>
      <Form className="flex max-h-full w-full flex-col" {...{ formMethods, onSubmit }}>
        {step === Step.GroupInfo && (
          <EditGroupChatModalInfoView
            groupChatId={chatId}
            isModalOpen={isOpened}
            isUpdating={isUploadingImage || mutationLeaveGroupChat.isPending}
            onAddMembers={() => handleNextStep(Step.AddMembers)}
            onLeaveGroup={leaveModalHandler.setTrue}
          />
        )}
        {step === Step.AddMembers && sessionUser.chatEnabled && (
          <EditGroupChatModalAddMembersView
            onNavigateBack={() => handlePrevStep(Step.GroupInfo)}
            isOpen={isOpened}
            {...{ chatId }}
          />
        )}
      </Form>

      <ConfirmModal
        id="leave-group-chat-modal"
        title={t("component.group-chat-leave-modal.title")}
        description={t("component.group-chat-leave-modal.description")}
        isLoading={mutationLeaveGroupChat.isPending}
        theme="danger"
        onOpenChange={leaveModalHandler.set}
        onReject={leaveModalHandler.setFalse}
        rejectBtnProps={{
          "data-testid": "leave-group-chat-modal-cancel",
        }}
        onResolve={() => mutationLeaveGroupChat.mutateAsync()}
        resolveBtnProps={{
          "data-testid": "leave-group-chat-modal-confirm",
          text: t("common.action.confirm"),
        }}
        isOpen={isLeaveModalOpen}
        shouldCloseOnEsc
      />
      <ConfirmModal
        id="chat-remove-user-modal"
        title={t("component.group-chat-remove-user-modal.title")}
        description={t("component.group-chat-remove-user-modal.description", { user: userToRemove?.fullName })}
        isLoading={isRemovingMember}
        theme="danger"
        onOpenChange={(state) => {
          if (!state) {
            setUserToRemove(null);
          }
        }}
        onReject={() => setUserToRemove(null)}
        rejectBtnProps={{
          "data-testid": "chat-remove-user-modal-cancel",
        }}
        onResolve={() => removeMember({ userId: userToRemove!.id })}
        resolveBtnProps={{
          "data-testid": "chat-remove-user-modal-confirm",
          text: t("common.action.remove"),
        }}
        isOpen={!!userToRemove}
        shouldCloseOnEsc
      />
    </Modal.Root>
  );
}
