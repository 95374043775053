import { useQuery } from "@tanstack/react-query";
import { Button } from "components/Button/Button";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { SUPPORT_EMAIL } from "helpers/constants";
import { isDefined } from "helpers/util";
import { AnimatePresence, motion } from "motion/react";
import { useAnalyticsQueries } from "queries/analytics/queryOptions";
import type React from "react";
import { useTranslation } from "react-i18next";

import { HomefahWidgetDistributionSection } from "./HomeFahWidgetDistributionSection";
import { HomeFahWidgetScoreSection } from "./HomeFahWidgetScoreSection";

export function HomeFahWidget(): React.ReactNode {
  const { t } = useTranslation();

  const analyticsQueries = useAnalyticsQueries();
  const { data, isLoading } = useQuery(analyticsQueries.getFah());

  const isFahNotStarted =
    !isLoading && (!data || !isDefined(data.totalFeelingAtHomeScore)) && data?.status === "notStarted";

  return (
    <div className="mb-2 w-full rounded-lg bg-aop-dark-blue-500 p-6">
      <div className="flex w-full flex-col items-center rounded-lg bg-white">
        <div className="w-full p-6 pb-0">
          <h1 className="text-center text-headline1-medium leading-[32px]">{t("page.home.fah-widget.title")}</h1>
        </div>
        <div className="relative w-full">
          {/* Loading */}
          <AnimatePresence>
            {isLoading && (
              <motion.div
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2, ease: "linear", delay: 0.2 }}
                className="absolute inset-0 z-10 bg-white"
              >
                <FullSizeLoader noDelay />
              </motion.div>
            )}
          </AnimatePresence>

          {/* Empty state */}
          {isFahNotStarted && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.2, ease: "linear" }}
              className="absolute inset-0 z-20 flex flex-col items-center justify-between gap-4 bg-white/90 p-4 backdrop-blur-sm"
            >
              <div className="flex flex-col gap-1">
                <h2 className="text-center text-headline2">{t("page.home.fah-widget.empty.title")}</h2>
                <p className="text-center">{t("page.home.fah-widget.empty.description")}</p>
              </div>
              <Button type="link" href={`mailto:${SUPPORT_EMAIL}?subject=Request to start FAH survey`} isExternal>
                {t("page.home.fah-widget.get-help-success.btn")}
              </Button>
            </motion.div>
          )}

          <div className="grid grid-rows-[auto_auto] xl:grid-cols-2 xl:grid-rows-1 [&>:first-child]:border-b [&>:first-child]:border-aop-dark-blue-500 xl:[&>:first-child]:border-none [&>div]:px-3 [&>div]:py-8 xl:[&>div]:px-4 xl:[&>div]:py-6">
            <HomeFahWidgetScoreSection />
            <HomefahWidgetDistributionSection />
          </div>
        </div>
      </div>
    </div>
  );
}
