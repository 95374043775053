import type { ReservationsFilters } from "modules/calendar/constants";
import { GLOBAL_QUERY_KEYS } from "query-keys-global";
import type { ApiQueryParams } from "types/api-types";

export const PROJECT_QUERY_KEYS = {
  ADMIN_NOTIFICATION_STATUS: ["ADMIN_NOTIFICATION"],
  ADMIN_NOTIFICATION_LIST: (query?: ApiQueryParams<"getAdminNotificationsV1">) =>
    query ? (["ADMIN_NOTIFICATION_LIST", query] as const) : (["ADMIN_NOTIFICATION_LIST"] as const),

  ADDRESS_LIST: ["ADDRESSES"],
  ADDRESS_LIST_INFINITE: (search?: string, addressTypes?: string[]) =>
    ["ADDRESSES", "INFINITE", { search, addressTypes }] as const,
  ADDRESS_STATS: ["ADDRESSES", "STATS"],
  ADDRESS_DETAILS: (id: string) => [...PROJECT_QUERY_KEYS.ADDRESS_LIST, id] as const,
  ADDRESS_TYPES: () => [...PROJECT_QUERY_KEYS.ADDRESS_LIST, "TYPES"] as const,

  ANALYTICS: ["ANALYTICS"],
  ANALYTICS_ACTIVE_CONTRIBUTORS: (dateRange: string) =>
    [...PROJECT_QUERY_KEYS.ANALYTICS, "ACTIVE_CONTRIBUTORS", dateRange] as const,
  ANALYTICS_DETAILS_ADOPTION: (dateRange: string) =>
    [...PROJECT_QUERY_KEYS.ANALYTICS, "DETAILS_ADOPTION", dateRange] as const,
  ANALYTICS_DETAILS_ADOPTION_VACANT: (dateRange: string) =>
    [...PROJECT_QUERY_KEYS.ANALYTICS, "DETAILS_ADOPTION_VACANT", dateRange] as const,
  ANALYTICS_DETAILS_ENGAGEMENT: (dateRange: string) =>
    [...PROJECT_QUERY_KEYS.ANALYTICS, "DETAILS_ENGAGEMENT", dateRange] as const,
  ANALYTICS_ENGAGED_RESIDENTS: () => [...PROJECT_QUERY_KEYS.ANALYTICS, "ENGAGED_RESIDENTS"] as const,
  ANALYTICS_FEELING_AT_HOME: () => [...PROJECT_QUERY_KEYS.ANALYTICS, "FEELING_AT_HOME"] as const,
  ANALYTICS_MESSAGE_TYPES: (dateRange: string) =>
    [...PROJECT_QUERY_KEYS.ANALYTICS, "MESSAGE_TYPES", dateRange] as const,
  ANALYTICS_PLATFORM_ADOPTION: () => [...PROJECT_QUERY_KEYS.ANALYTICS, "PLATFORM_ADOPTION"] as const,
  ANALYTICS_PLATFORM_COMPANY_ADOPTION: () => [...PROJECT_QUERY_KEYS.ANALYTICS, "PLATFORM_COMPANY_ADOPTION"] as const,
  ANALYTICS_TICKETS_AVERAGE_CLOSING_TIME: (dateRange: string) =>
    [...PROJECT_QUERY_KEYS.ANALYTICS, "AVERAGE_CLOSING_TIME", dateRange] as const,
  ANALYTICS_TICKETS_AVERAGE_RATING: (dateRange: string) =>
    [...PROJECT_QUERY_KEYS.ANALYTICS, "AVERAGE_RATING", dateRange] as const,
  ANALYTICS_TICKETS_BUCKETS_LEAD_TIME: (dateRange: string) =>
    [...PROJECT_QUERY_KEYS.ANALYTICS, "TICKETS_BUCKETS_LEAD_TIME", dateRange] as const,
  ANALYTICS_TICKETS_CONVERSATIONS_STARTED: (dateRange: string) =>
    [...PROJECT_QUERY_KEYS.ANALYTICS, "CONVERSATIONS_STARTED", dateRange] as const,
  ANALYTICS_TICKETS_OVERVIEW: (dateRange: string) =>
    [...PROJECT_QUERY_KEYS.ANALYTICS, "TICKETS_OVERVIEW", dateRange] as const,
  ANALYTICS_WEEKLY_MESSAGES: () => [...PROJECT_QUERY_KEYS.ANALYTICS, "WEEKLY_MESSAGES"] as const,

  ASSIGNEES_BY_CATEGORY: (categoryId: string) => ["ASSIGNEES_BY_CATEGORY", categoryId] as const,
  ASSIGNEES_BY_ROLES: (roleIds: string[]) => ["ASSIGNEES_BY_ROLES", roleIds] as const,

  AUTOMATED_SURVEY_QUEUE_LIST: (query?: ApiQueryParams<"getAutomatedSurveysQueuesV1">) =>
    query
      ? ([...GLOBAL_QUERY_KEYS.AUTOMATED_SURVEYS, "QUEUE", "LIST", query] as const)
      : ([...GLOBAL_QUERY_KEYS.AUTOMATED_SURVEYS, "QUEUE", "LIST"] as const),
  AUTOMATED_SURVEY_QUEUE_DETAILS: (id: string) =>
    [...GLOBAL_QUERY_KEYS.AUTOMATED_SURVEYS, "QUEUE_DETAILS", id] as const,
  AUTOMATED_SURVEY_QUESTION_DETAILS: (queueId: string, questionId: string) =>
    [...GLOBAL_QUERY_KEYS.AUTOMATED_SURVEYS, "QUEUE_DETAILS", queueId, "QUESTION_DETAILS", questionId] as const,
  AUTOMATED_SURVEY_QUESTION_RESPONSE_SUMMARY: (queueId: string, questionId: string) =>
    [
      ...GLOBAL_QUERY_KEYS.AUTOMATED_SURVEYS,
      "QUEUE_DETAILS",
      queueId,
      "QUESTION_RESPONSE_SUMMARY",
      questionId,
    ] as const,

  BOOKINGS: ["BOOKINGS"],
  BOOKINGS_ASSETS: (query?: ApiQueryParams<"getBookableAssetsV1">) =>
    query
      ? ([...PROJECT_QUERY_KEYS.BOOKINGS, "ASSETS", query] as const)
      : ([...PROJECT_QUERY_KEYS.BOOKINGS, "ASSETS"] as const),
  BOOKINGS_ASSETS_INFINITE: (query: ApiQueryParams<"getBookableAssetsV1">) =>
    [...PROJECT_QUERY_KEYS.BOOKINGS, "ASSETS", "INFINITE", query] as const,
  BOOKINGS_ASSET_DETAILS: (id: string) => [...PROJECT_QUERY_KEYS.BOOKINGS, "ASSET_DETAILS", id] as const,
  BOOKINGS_ASSET_SLOTS: (id: string, query?: ApiQueryParams<"getBookableAssetsSlotsV1", 1>) =>
    query
      ? ([...PROJECT_QUERY_KEYS.BOOKINGS, "ASSET_TIME_SLOTS", id, query] as const)
      : ([...PROJECT_QUERY_KEYS.BOOKINGS, "ASSET_TIME_SLOTS", id] as const),
  BOOKINGS_ASSET_STATISTICS: (id: string) => [...PROJECT_QUERY_KEYS.BOOKINGS, "ASSET_STATISTICS", id] as const,
  BOOKINGS_ASSET_BOOKINGS: (id: string, query?: ApiQueryParams<"getBookableAssetsBookingsV1", 1>) =>
    query
      ? ([...PROJECT_QUERY_KEYS.BOOKINGS, "ASSET_BOOKINGS", id, "BOOKINGS", query] as const)
      : ([...PROJECT_QUERY_KEYS.BOOKINGS, "ASSET_BOOKINGS", id, "BOOKINGS"] as const),
  BOOKINGS_RESERVATION_DETAILS: (aid: string, rid: string) =>
    [...PROJECT_QUERY_KEYS.BOOKINGS, "RESERVATION_DETAILS", aid, rid] as const,
  BOOKINGS_USER_RESERVATIONS: (query?: ReservationsFilters) =>
    query
      ? ([...PROJECT_QUERY_KEYS.BOOKINGS, "USER_RESERVATIONS", query] as const)
      : ([...PROJECT_QUERY_KEYS.BOOKINGS, "USER_RESERVATIONS"] as const),

  BUILDING_LIST: ["BUILDINGS"],
  BUILDING_DETAILS: (id: string) => [...PROJECT_QUERY_KEYS.BUILDING_LIST, id] as const,

  CHATS: ["CHATS"],
  CHATS_LIST: () => [...PROJECT_QUERY_KEYS.CHATS, "LIST"] as const,
  CHATS_MESSAGES: (id: string) => [...PROJECT_QUERY_KEYS.CHATS, "MESSAGES", id] as const,
  CHATS_AVAILABLE_USERS: (search: string) => [...PROJECT_QUERY_KEYS.CHATS, "AVAILABLE_USERS", search] as const,
  CHATS_DETAILS: (id: string) => [...PROJECT_QUERY_KEYS.CHATS, "DETAILS", id] as const,
  CHATS_REPLIES: (id: string) => [...PROJECT_QUERY_KEYS.CHATS, "REPLIES", id] as const,
  CHATS_MEMBERS: (id: string) => [...PROJECT_QUERY_KEYS.CHATS, "MEMBERS", id] as const,

  COMPANY_DETAILS: (id: string) => [...PROJECT_QUERY_KEYS.COMPANY_LIST, id],
  COMPANY_LIST: ["COMPANIES"],

  COMMUNITY_GROUPS: ["COMMUNITY_GROUPS"],
  COMMUNITY_GROUPS_QUERY: (query?: ApiQueryParams<"getGroupsV2">) =>
    query
      ? ([...PROJECT_QUERY_KEYS.COMMUNITY_GROUPS, "FILTERS", query] as const)
      : ([...PROJECT_QUERY_KEYS.COMMUNITY_GROUPS, "FILTERS"] as const),
  COMMUNITY_GROUP_DETAILS: (id: string) => [...PROJECT_QUERY_KEYS.COMMUNITY_GROUPS, id] as const,
  COMMUNITY_GROUP_MEMBERS: (id: string, search: string) =>
    [...PROJECT_QUERY_KEYS.COMMUNITY_GROUPS, id, "MEMBERS", search] as const,
  COMMUNITY_GROUPS_DEFAULT_PERMISSIONS: ["GROUPS_DEFAULT_PERMISSIONS"],

  CONSTRAINTS: ["CONSTRAINTS"],

  DASHBOARD_LINKS_DETAILS: (id: string) => [...PROJECT_QUERY_KEYS.DASHBOARD_LINKS_LIST, id],
  DASHBOARD_LINKS_LIST: ["DASHBOARD_LINKS"],

  DATA_WIDGETS: ["DATA_WIDGETS"],
  DATA_WIDGETS_LIST: () => [...PROJECT_QUERY_KEYS.DATA_WIDGETS, "LIST"] as const,
  DATA_WIDGETS_DETAILS: (id: string) => [...PROJECT_QUERY_KEYS.DATA_WIDGETS, "DETAILS", id] as const,

  DOCUMENTS_LIST_ALL: ["DOCUMENT_LIST"],
  DOCUMENTS_LIST: (folderId: string, query?: { Offset: number; Limit: number }) =>
    query
      ? ([...PROJECT_QUERY_KEYS.DOCUMENTS_LIST_ALL, "LIST", folderId, query] as const)
      : ([...PROJECT_QUERY_KEYS.DOCUMENTS_LIST_ALL, "LIST", folderId] as const),
  DOCUMENTS_LIST_DETAILS: (folderId: string) => [...PROJECT_QUERY_KEYS.DOCUMENTS_LIST(folderId), "DETAILS"] as const,
  DOCUMENTS_FILE_DETAILS: (folderId: string, fileId: string) =>
    [...PROJECT_QUERY_KEYS.DOCUMENTS_LIST(folderId), "FILE", fileId] as const,
  DOCUMENTS_ROOT_LIST: ["DOCUMENTS_ROOT_LIST"],
  DOCUMENTS_ROOT_FOLDER_DETAILS: (id: string) => [...PROJECT_QUERY_KEYS.DOCUMENTS_ROOT_LIST, "DETAILS", id] as const,
  DOCUMENTS_ROOT_DEFAULT_PERMISSIONS: ["DOCUMENTS_ROOT_DEFAULT_PERMISSIONS"],

  EVENT_LIST_ALL: ["EVENT_LIST"],
  EVENT_LIST: <T>(filters: T) => [...PROJECT_QUERY_KEYS.EVENT_LIST_ALL, "FILTERED", filters] as const,
  EVENT_DETAILS: (id: string) => [...PROJECT_QUERY_KEYS.EVENT_LIST_ALL, "DETAILS", id] as const,
  EVENT_CATEGORIES: ["EVENT_CATEGORIES"],

  FEELING_AT_HOME: ["FEELING_AT_HOME"],
  FEELING_AT_HOME_DETAILS: (query?: ApiQueryParams<"getAnalyticsFeelingAtHomeDetailsV1">) =>
    query
      ? [...PROJECT_QUERY_KEYS.FEELING_AT_HOME, "DETAILS", query]
      : ([...PROJECT_QUERY_KEYS.FEELING_AT_HOME, "DETAILS"] as const),
  FEELING_AT_HOME_EXPORT: (query?: ApiQueryParams<"getAnalyticsFeelingAtHomeExportV1">) =>
    query
      ? [...PROJECT_QUERY_KEYS.FEELING_AT_HOME, "EXPORT", query]
      : ([...PROJECT_QUERY_KEYS.FEELING_AT_HOME, "EXPORT"] as const),

  LANGUAGES: ["LANGUAGES"],

  MESSAGES: ["MESSAGES"],
  MESSAGES_FEED_STATS: () => [...PROJECT_QUERY_KEYS.MESSAGES, "STATS"] as const,
  MESSAGES_FEED: (filter?: ApiQueryParams<"getCommunityFeedV2">) =>
    filter
      ? ([...PROJECT_QUERY_KEYS.MESSAGES, "FEED", filter] as const)
      : ([...PROJECT_QUERY_KEYS.MESSAGES, "FEED"] as const),
  MESSAGES_FEED_OLD: (filter?: ApiQueryParams<"getMessagesV2">) =>
    filter
      ? ([...PROJECT_QUERY_KEYS.MESSAGES, "FEED", "MESSAGES_OLD", filter] as const)
      : ([...PROJECT_QUERY_KEYS.MESSAGES, "FEED", "MESSAGES_OLD"] as const),
  MESSAGES_DETAILS: (id: string) => [...PROJECT_QUERY_KEYS.MESSAGES, "ID", id] as const,
  MESSAGES_COMMENTS: (id: string) => [...PROJECT_QUERY_KEYS.MESSAGES, "COMMENTS", id] as const,
  MESSAGES_COMMENT_LIKES: (messageId: string, commentId: string) =>
    [...PROJECT_QUERY_KEYS.MESSAGES, "COMMENT_LIKES", messageId, commentId] as const,
  MESSAGES_COMMENT_TRANSLATION: (messageId: string, commentId: string, languageCode: string) =>
    [...PROJECT_QUERY_KEYS.MESSAGES, "COMMENTS", messageId, "TRANSLATION", commentId, languageCode] as const,
  MESSAGES_INFORM_ADMINS: (id: string) => [...PROJECT_QUERY_KEYS.MESSAGES, "INFORM_ADMINS", id] as const,
  MESSAGES_INFORMED_ADMINS: (id: string) => [...PROJECT_QUERY_KEYS.MESSAGES, "INFORMED_ADMINS", id] as const,
  MESSAGES_LIKES: (id: string) => [...PROJECT_QUERY_KEYS.MESSAGES, "LIKES", id] as const,
  MESSAGES_AUDIENCE: (query?: ApiQueryParams<"getCommunityFeedAudienceV1">) =>
    query
      ? ([...PROJECT_QUERY_KEYS.MESSAGES, "AUDIENCE", query] as const)
      : ([...PROJECT_QUERY_KEYS.MESSAGES, "AUDIENCE"] as const),
  MESSAGES_AI_SENTIMENT: (id: string) => [...PROJECT_QUERY_KEYS.MESSAGES, "AI_SENTIMENT", id] as const,
  MESSAGES_AI_TOPICS: (id: string) => [...PROJECT_QUERY_KEYS.MESSAGES, "AI_TOPICS", id] as const,

  MESSAGE_COMMENTS: ["MESSAGES_COMMENTS"],
  MESSAGE_COMMENTS_DETAILS: (id: string) => [...PROJECT_QUERY_KEYS.MESSAGE_COMMENTS, id] as const,
  MESSAGE_COMMENT_DETAILS: (mid: string, cid: string) => [...PROJECT_QUERY_KEYS.MESSAGE_COMMENTS, mid, cid] as const,

  MESSAGE_TRANSLATION: (id: string, languageCode: string) =>
    [...PROJECT_QUERY_KEYS.MESSAGES_DETAILS(id), "TRANSLATIONS", languageCode] as const,

  NOTIFICATION_SETTINGS_USER: (userId: string) => ["NOTIFICATION_SETTINGS", userId] as const,

  ONBOARDING_SCREENS_DETAILS: (id: string) => [...PROJECT_QUERY_KEYS.ONBOARDING_SCREENS_LIST, id],
  ONBOARDING_SCREENS_LIST: ["ONBOARDING_SCREENS"],

  PERMISSION_ROLES: ["PERMISSION_ROLES"],
  PLUS_BUTTON_OPTIONS: ["PLUS_BUTTON_OPTIONS"],
  PLUS_BUTTON_OPTIONS_LIST: () => [...PROJECT_QUERY_KEYS.PLUS_BUTTON_OPTIONS, "LIST"] as const,

  PROJECT_DETAILS: ["PROJECT_DETAILS"],
  PROJECT_SUMMARY: ["PROJECT_SUMMARY"],

  INTEGRATION_REMS_CONFIGURATION: ["INTEGRATION_REMS_CONFIGURATION"],
  INTEGRATION_ZIEZODAN_CONFIGURATION: ["INTEGRATION_ZIEZODAN_CONFIGURATION"],
  INTEGRATION_IQBI_CONFIGURATION: ["INTEGRATION_IQBI_CONFIGURATION"],
  INTEGRATION_MY_PUP_CONFIGURATION: ["INTEGRATION_MY_PUP_CONFIGURATION"],
  INTEGRATION_HOMEDNA_CONFIGURATION: ["INTEGRATION_HOMEDNA_CONFIGURATION"],
  INTEGRATION_COPILOT_CONFIGURATION: ["INTEGRATION_COPILOT_CONFIGURATION"],

  REPAIR_CATEGORY_DEFAULT_PERMISSIONS: ["REPAIR_CATEGORY_DEFAULT_PERMISSIONS"],

  ROLE_DETAILS: (roleId: string) => [...PROJECT_QUERY_KEYS.ROLE_LIST, roleId] as const,
  ROLE_LIST: ["ROLE_LIST"],
  ROLE_TYPE_DEFAULT_PERMISSIONS: ["ROLE_TYPE_DEFAULT_PERMISSIONS"],

  SERVICE_PARTNER: (id: string) => [...PROJECT_QUERY_KEYS.SERVICE_PARTNERS_ALL, id] as const,
  SERVICE_PARTNER_IQBI: (id: string, month?: string) =>
    month
      ? ([...PROJECT_QUERY_KEYS.SERVICE_PARTNER(id), "IQBI", month] as const)
      : ([...PROJECT_QUERY_KEYS.SERVICE_PARTNER(id), "IQBI"] as const),
  SERVICE_PARTNERS_ALL: ["SERVICE_PARTNERS_LIST"],
  SERVICE_PARTNERS_CREATE_TYPES: ["SERVICE_PARTNERS_CREATE_TYPES"],
  SERVICE_PARTNERS_ALL_INFINITE: () => [...PROJECT_QUERY_KEYS.SERVICE_PARTNERS_ALL, "INFINITE"] as const,
  SERVICE_PARTNERS_CATEGORIES: ["SERVICE_PARTNERS_CATEGORIES"],
  SERVICE_PARTNERS_DEFAULT_PERMISSIONS: ["SERVICE_PARTNERS_DEFAULT_PERMISSIONS"],

  SURVEYS: ["SURVEYS"],
  SURVEYS_QUERY: (query?: ApiQueryParams<"getSurveysV1">) =>
    query ? ([...PROJECT_QUERY_KEYS.SURVEYS, "FILTERS", query] as const) : PROJECT_QUERY_KEYS.SURVEYS,
  SURVEY_DETAILS: (id: string) => [...PROJECT_QUERY_KEYS.SURVEYS, "DETAILS", id] as const,
  SURVEY_DETAILS_RESPONSE_SUMMARY: (surveyId: string, questionId: string) =>
    [...PROJECT_QUERY_KEYS.SURVEYS, surveyId, "QUESTION_RESPONSE_SUMMARY", questionId] as const,
  SURVEY_EXPORT: (id: string) => [...PROJECT_QUERY_KEYS.SURVEYS, "DETAILS", id, "EXPORT"] as const,

  TICKET_CATEGORIES: ["TICKET_CATEGORIES"],
  TICKET_CATEGORIES_RESIDENT: () => [...PROJECT_QUERY_KEYS.TICKET_CATEGORIES, "RESIDENT"] as const,
  TICKET_CATEGORY: (id: string) => [...PROJECT_QUERY_KEYS.TICKET_CATEGORIES, id] as const,
  TICKET_STATUSES: ["TICKET_STATUSES"],
  TICKET_STATUS: (id: string) => [...PROJECT_QUERY_KEYS.TICKET_STATUSES, id] as const,

  TICKETS: ["TICKETS"],
  TICKETS_FEED: (filter?: ApiQueryParams<"getAdminTicketsV1">) =>
    filter
      ? ([...PROJECT_QUERY_KEYS.TICKETS, "FEED", filter] as const)
      : ([...PROJECT_QUERY_KEYS.TICKETS, "FEED"] as const),
  TICKETS_DETAILS: (id: string) => [...PROJECT_QUERY_KEYS.TICKETS, "DETAILS", id] as const,
  TICKETS_DENIAL_REPLY_SUGGESTION: (id: string) =>
    [...PROJECT_QUERY_KEYS.TICKETS, "REJECT_DENIAL_REPLY_SUGGESTION", id] as const,
  TICKETS_RESPONSIBILITY_SUGGESTION: (id: string) =>
    [...PROJECT_QUERY_KEYS.TICKETS_DETAILS(id), "RESPONSIBILITY_SUGGESTION"] as const,
  TICKETS_DETAILS_TRANSLATION: (id: string, languageId: string) =>
    [...PROJECT_QUERY_KEYS.TICKETS_DETAILS(id), "TRANSLATION", languageId] as const,
  TICKETS_DETAILS_ACTIVITIES: (id: string, params?: ApiQueryParams<"getAdminTicketsActivitiesV1", 1>) =>
    [...PROJECT_QUERY_KEYS.TICKETS_DETAILS(id), "ACTIVITIES", params] as const,
  TICKET_COMMENT_TRANSLATION: (ticketId: string, commentId: string, languageCode: string) =>
    [
      ...PROJECT_QUERY_KEYS.TICKETS_DETAILS(ticketId),
      "ACTIVITES",
      "COMMENT_TRANSLATION",
      commentId,
      languageCode,
    ] as const,
  TICKETS_FILTERS_VALUES: () => [...PROJECT_QUERY_KEYS.TICKETS, "FILTERS_VALUES"] as const,
  TICKETS_HISTORY: (id: string, type: string) => [...PROJECT_QUERY_KEYS.TICKETS, "HISTORY", id, type] as const,
  TICKETS_RESIDENT_DETAILS: (id: string) => [...PROJECT_QUERY_KEYS.TICKETS, "RESIDENT_DETAILS", id] as const,
  TICKETS_RESIDENT_DETAILS_COMMENTS: (id: string, params?: ApiQueryParams<"getTicketsCommentsV1", 1>) =>
    [...PROJECT_QUERY_KEYS.TICKETS, "RESIDENT_DETAILS", id, "COMMENTS", params] as const,
  TICKETS_RESIDENT_FEED: (filter?: ApiQueryParams<"getTicketsV1">) =>
    filter
      ? ([...PROJECT_QUERY_KEYS.TICKETS, "RESIDENT_FEED", filter] as const)
      : ([...PROJECT_QUERY_KEYS.TICKETS, "RESIDENT_FEED"] as const),
  TICKETS_RESIDENT_FILTERS_VALUES: () => [...PROJECT_QUERY_KEYS.TICKETS, "RESIDENT_FILTERS_VALUES"] as const,
  TICKETS_RESIDENT_TAB_TOTAL: (tab: string) => [...PROJECT_QUERY_KEYS.TICKETS, "RESIDENT_TAB_TOTAL", tab] as const,
  TICKETS_RESIDENT_TABS_AMOUNT: (filter?: ApiQueryParams<"getTicketsStatsAllStatusesV1">) =>
    filter
      ? ([...PROJECT_QUERY_KEYS.TICKETS, "RESIDENT_TABS_AMOUNT", filter] as const)
      : ([...PROJECT_QUERY_KEYS.TICKETS, "RESIDENT_TABS_AMOUNT"] as const),
  TICKETS_TAB_TOTAL: (tab: string) => [...PROJECT_QUERY_KEYS.TICKETS, "TAB_TOTAL", tab] as const,
  TICKETS_TABS_AMOUNT: (filter?: ApiQueryParams<"getAdminTicketsStatsAllTabsV1">) =>
    filter
      ? ([...PROJECT_QUERY_KEYS.TICKETS, "TABS_AMOUNT", filter] as const)
      : ([...PROJECT_QUERY_KEYS.TICKETS, "TABS_AMOUNT"] as const),

  USERS: ["USERS"],
  USERS_ADMINS: ["USERS_ADMINS"],
  USERS_QUERY: (query?: ApiQueryParams<"getUsersV1">) =>
    query ? (["USERS", "FILTERS", query] as const) : (["USERS", "FILTERS"] as const),
  USER_DETAILS: (userId: string) => [...PROJECT_QUERY_KEYS.USERS, userId] as const,
  USER_CHAT_DETAILS: (userId: string) => [...PROJECT_QUERY_KEYS.USERS, "CHAT_DETAILS", userId] as const,
  USER_POSTS: (userId: string) => [...PROJECT_QUERY_KEYS.USERS, "POSTS", userId] as const,
  USER_PROJECTS: (userId: string) => [...PROJECT_QUERY_KEYS.USERS, "PROJECTS", userId] as const,
  USER_ROLE: (userId: string) => [...PROJECT_QUERY_KEYS.USERS, "ROLE", userId] as const,
  USER_SIGNUP_REMINDERS: (userId: string) => [...PROJECT_QUERY_KEYS.USERS, "SIGNUP_REMINDERS", userId] as const,
} as const;
