import { ErrorPage } from "components/Error/ErrorPage";
import type { RouteObject } from "react-router";

import { ListAlertsPage } from "./pages/List";
import { SettingsAlertsPage } from "./pages/Settings";
import { alertsRoutes } from "./routes";

export const alertsRoute = [
  {
    path: alertsRoutes.list,
    element: <ListAlertsPage />,
  },
  {
    path: alertsRoutes.settings,
    element: <SettingsAlertsPage />,
  },
  { path: "*", element: <ErrorPage status={404} /> },
] satisfies RouteObject[];
