import iconEdit05 from "assets/icons/edit-05.svg";
import iconPlayCircle from "assets/icons/play-circle.svg";
import iconPlusCircle from "assets/icons/plus-circle.svg";
import { Icon } from "components/Icon/Icon";
import { UploadDocumentIcon, UploadImageIcon } from "components/Icons/Icons";
import { twResolve } from "helpers/tw-resolve";
import type { AriaAttributes, FocusEventHandler } from "react";
import type React from "react";
import { useTranslation } from "react-i18next";

import { MediaXButton } from "./MediaXButton";

export interface MediaBaseProps extends AriaAttributes {
  texts?: {
    add?: string;
    edit?: string;
  };
  onAdd?: () => void;
  onDelete?: () => void;
  onEdit?: () => void;
  className?: string;
  theme?: "light" | "dark";
  "data-testid"?: string;
  "data-add-testid"?: string;
  "data-edit-testid"?: string;
  "data-delete-testid"?: string;
  onFocus?: FocusEventHandler;
  onBlur?: FocusEventHandler;
  disabled?: boolean;
  children?: React.ReactNode;
}

type MediaProps = {
  type?: "image" | "document" | "video";
};

export function Media(props: MediaBaseProps & MediaProps): React.ReactNode {
  const { t } = useTranslation();

  if (!props.children) {
    return (
      <MediaInputBox {...props}>
        <button
          className={twResolve(
            "flex aspect-video h-20 min-w-28 cursor-pointer items-center justify-center gap-2 px-2 text-grey-700",
            innerButtonStyles,
            props.disabled && "cursor-not-allowed",
            props.theme === "dark" ? "text-grey-100" : undefined,
          )}
          data-testid={props["data-add-testid"]}
          type="button"
          onClick={props.onAdd}
          onFocus={props.onFocus}
          onBlur={props.onBlur}
          disabled={props.disabled}
        >
          {props.type === "image" && <UploadImageIcon size={16} />}
          {props.type === "document" && <UploadDocumentIcon size={16} />}
          {props.type === "video" && <Icon name={iconPlayCircle} size={16} />}
          {!props.type && <Icon name={iconPlusCircle} size={16} />}
          <span className="max-w-[128px] whitespace-pre-line">{props.texts?.add || t("common.action.add")}</span>
        </button>
      </MediaInputBox>
    );
  }

  return (
    <MediaInputBox {...props}>
      {props.onEdit != null && !props.disabled && (
        <button
          className={twResolve(
            "absolute left-0 top-0 z-10 flex size-full flex-1 items-center justify-center gap-1 bg-[rgba(64,61,74,0.65)] text-white opacity-0 hocus:opacity-100",
            innerButtonStyles,
          )}
          data-testid={props["data-edit-testid"]}
          onClick={props.onEdit}
          type="button"
        >
          <Icon name={iconEdit05} size={16} />
          {props.texts?.edit || t("common.action.edit")}
        </button>
      )}
      {props.onDelete != null && !props.disabled && (
        <div className="absolute right-0 top-0 z-10 -translate-y-1/2 translate-x-1/2">
          <MediaXButton data-testid={props["data-delete-testid"]} onClick={props.onDelete} />
        </div>
      )}
      {props.children}
    </MediaInputBox>
  );
}

function MediaInputBox(props: MediaBaseProps & { children: React.ReactNode }) {
  return (
    <div
      data-testid={props["data-testid"]}
      className={twResolve(
        "relative aspect-video h-20 min-w-28 rounded-lg border border-grey-300 hocus:bg-grey-100",
        props["aria-invalid"] ? "border-red-600" : undefined,
        props.disabled && "bg-grey-100",
        props.theme === "dark" ? "bg-grey-900 hocus:bg-grey-700" : undefined,
        props.className,
      )}
      aria-invalid={props["aria-invalid"]}
    >
      {props.children}
    </div>
  );
}

export const innerButtonStyles =
  "active:text-blue-500 disabled:text-grey-500 rounded-lg focus-visible:outline-none focus-visible:before:content-[''] focus-visible:before:absolute focus-visible:before:-inset-2px focus-visible:before:pointer-events-none focus-visible:before:ring-1 focus-visible:before:ring-grey-900 focus-visible:before:rounded-lg";
