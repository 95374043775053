import { LoadingIcon } from "components/Icons/Icons";
import { AnimatePresence, type AnimationProps, motion } from "motion/react";
import type React from "react";

interface ButtonLoaderProps {
  isVisible: boolean | undefined;
}

// Intentionally handle rendering logic here to add delay to exit animation
export function ButtonLoader({ isVisible }: ButtonLoaderProps): React.ReactNode {
  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          variants={buttonLoaderAnimationVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
          className="absolute left-0 z-10 flex size-full items-center justify-center bg-white/80"
        >
          <LoadingIcon className="size-5" />
        </motion.div>
      )}
    </AnimatePresence>
  );
}

const buttonLoaderAnimationVariants: AnimationProps["variants"] = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.2,
      delay: 0.2,
    },
  },
};
