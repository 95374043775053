import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { ErrorPage } from "components/Error/ErrorPage";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { QUERY_KEYS } from "query-keys";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

import type { LayoutProps } from "./Layout";

export interface LoaderProps {
  children: (props: LayoutProps) => React.ReactNode;
}

const PAGE_SIZE = 10;

export function Loader(props: LoaderProps): React.ReactNode {
  const projectId = useProjectId();
  const [page, setPage] = useState(0);

  const api = useApi();
  const queryClient = useQueryClient();
  const showFlashToast = useFlashToast();
  const { t } = useTranslation();
  const { folderId } = useParams<{ folderId: string }>();

  const documentListQueryParams = {
    Limit: PAGE_SIZE,
    Offset: page * PAGE_SIZE,
  };

  const {
    data: documentList,
    isFetching: documentListIsLoading,
    error: documentListError,
  } = useQuery({
    queryKey: QUERY_KEYS.DOCUMENTS_LIST(projectId, folderId!, documentListQueryParams),
    queryFn: () => api.getDocumentsDetailsV1(folderId!, documentListQueryParams),
    select: commonAPIDataSelector,
  });

  const { mutateAsync: deleteFolder } = useMutation({
    mutationFn: api.deleteDocumentsFolderByIdV1,
    onSuccess: () => {
      showFlashToast({
        type: "success",
        title: t("model.document.folder.action.delete.notification.success"),
      });
    },
    onError: () => {
      showFlashToast({
        type: "error",
        title: t("model.document.folder.action.delete.notification.failure"),
      });
    },
    onSettled: () => {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.DOCUMENTS_LIST(projectId, folderId!) });
    },
  });
  const { mutateAsync: deleteFile } = useMutation({
    mutationFn: api.deleteDocumentsFileByIdV1,
    onSuccess: () => {
      showFlashToast({
        type: "success",
        title: t("model.document.file.action.delete.notification.success"),
      });
    },
    onError: () => {
      showFlashToast({
        type: "error",
        title: t("model.document.file.action.delete.notification.failure"),
      });
    },
    onSettled: () => {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.DOCUMENTS_LIST(projectId, folderId!) });
    },
  });

  async function deleteFolderItem({ id, isFolder }: { id: string; isFolder: boolean }) {
    if (isFolder) {
      await deleteFolder(id);

      return;
    }
    await deleteFile(id);
  }

  const totalPages = documentList ? Math.ceil(documentList.total / PAGE_SIZE) : 0;

  if (documentListError) {
    return <ErrorPage error={documentListError} />;
  }

  return documentListIsLoading ? (
    <FullSizeLoader withPadding />
  ) : (
    <>
      {props.children({
        documentList,
        page,
        totalPages,
        onPageChange: setPage,
        folderId: folderId!,
        deleteFolderItem,
      })}
    </>
  );
}
