import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { ChatDetailDto, ChatReplyDto, ChatReplyRequest } from "api/types";
import users01Icon from "assets/icons/users-01.svg";
import { Breadcrumbs } from "components/Breadcrumbs/Breadcrumbs";
import { ErrorPage } from "components/Error/ErrorPage";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { Icon } from "components/Icon/Icon";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { UserAvatar } from "components/UserAvatar/UserAvatar";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useUploadImage } from "hooks/Network/useUploadImage";
import { useUploadVideo } from "hooks/Network/useUploadVideo";
import { useBool } from "hooks/useBool";
import { useSignalRHub, useSignalRInvocation, useSignalRSubscription } from "hooks/useSignalR";
import { useSlug } from "hooks/useSlug";
import type { ChatReplyPayload } from "modules/chats/components/ChatWindow";
import { ChatWindow } from "modules/chats/components/ChatWindow";
import { QUERY_KEYS } from "query-keys";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { routes } from "routes";
import { twJoin } from "tailwind-merge";

import { EditGroupChatModal } from "./EditGroupChatModal";

const CHATS_PAGE = 10;

export const ChatDetailPage = (): React.ReactNode => {
  const [chatReplies, setChatReplies] = useState<ChatReplyDto[]>([]);
  const [isEditGroupModalOpened, editGroupModalHandler] = useBool(false);

  const { t } = useTranslation();
  const projectId = useProjectId();
  const sessionUser = useSessionUser();
  const slug = useSlug();
  const api = useApi();
  const query = useQueryClient();
  const { uploadFormImage } = useUploadImage();
  const { uploadFormVideo } = useUploadVideo({});
  const showFlashToast = useFlashToast();
  const { id: chatId } = useParams<{ id: string }>();
  const { signalRConnection } = useSignalRHub("chat-hub", {
    query: `userId=${sessionUser.id}&chatId=${chatId}`,
  });

  const { invoke: onReadMessage } = useSignalRInvocation(signalRConnection, "ChatMessageRead");
  const onNewChatMessage = useCallback(
    async (...args: [{ chatId: string; isGroupChat: boolean; latestReply: ChatReplyDto }]) => {
      const latestChatReply = args[0].latestReply;

      setChatReplies((prev) => [...prev, latestChatReply]);
      await onReadMessage({ chatId: chatId, userId: sessionUser.id });
    },
    [setChatReplies, onReadMessage, chatId, sessionUser.id],
  );
  useSignalRSubscription(signalRConnection, "NewChatMessage", onNewChatMessage);

  const {
    data: chatDetails,
    isPending: isLoadingChatDetails,
    error: errorLoadingChatDetails,
  } = useQuery({
    queryKey: QUERY_KEYS.CHATS_DETAILS(projectId, chatId!),
    queryFn: () => api.getChatsDetailsV2(chatId!),
    select: commonAPIDataSelector,
  });
  const {
    data: repliesData,
    hasNextPage: hasMoreReplies,
    fetchNextPage: fetchMoreReplies,
    isPending: isLoadingReplies,
    isFetchingNextPage: isLoadingMoreReplies,
    error: errorLoadingReplies,
  } = useInfiniteQuery({
    queryKey: QUERY_KEYS.CHATS_REPLIES(projectId, chatId!),
    queryFn: ({ pageParam = 0 }) =>
      api
        .getChatsRepliesV2(chatId!, { Offset: pageParam * CHATS_PAGE, Limit: CHATS_PAGE })
        .then((items) => commonAPIDataSelector(items)),
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages) => {
      if (!lastPage.hasMore) {
        return undefined;
      }

      return pages.length;
    },
    getPreviousPageParam: () => {
      return 0;
    },
  });
  const mutationChatsReply = useMutation({
    mutationFn: ({ payload }: { payload: ChatReplyRequest }) =>
      api.postChatsReplyV2(chatId!, payload).then((x) => x.data),
    onSuccess: async () => {
      await query.invalidateQueries({ queryKey: QUERY_KEYS.CHATS_REPLIES(projectId, chatId!) });
    },
    onError() {
      showFlashToast({ type: "error", title: t("page.chat-detail.chat.send-error") });
    },
  });

  useEffect(() => {
    setChatReplies(repliesData?.pages.flatMap((x) => x.items) ?? []);
  }, [repliesData]);

  const sendNewReply = async ({ message, images, videos }: ChatReplyPayload) => {
    const payload: ChatReplyRequest = { text: message };

    if (images.length > 0) {
      const uploadedImage = await uploadFormImage(images[0]);
      if (uploadedImage) {
        payload.imageId = uploadedImage.id;
      }
    }

    const uploadedVideoIds: string[] = [];
    if (videos.length > 0) {
      for (const video of videos) {
        const uploadedVideo = await uploadFormVideo(video);

        if (uploadedVideo) {
          uploadedVideoIds.push(uploadedVideo.id);
        }
      }

      payload.videoIds = uploadedVideoIds;
    }

    await mutationChatsReply.mutateAsync({
      payload,
    });
  };

  const error = !chatId || errorLoadingChatDetails || errorLoadingReplies;

  if (isLoadingChatDetails || isLoadingReplies) {
    return <FullSizeLoader />;
  }

  if (error) {
    return <ErrorPage error={error} />;
  }

  return (
    <DocumentPaper
      theme="minimal"
      title={
        chatDetails.chatType === "single"
          ? chatDetails.privateChatInfo!.receiver.fullName
          : chatDetails.groupChatInfo!.name
      }
      renderTitle={(title) => {
        return <ChatDetailPageTitle onClickEditChat={editGroupModalHandler.setTrue} {...{ title, chatDetails }} />;
      }}
      subTitle={
        <Breadcrumbs
          pages={[
            {
              name: t("page.chat-detail.breacrumbs.chats"),
              to: routes.chats.list({ slug }),
            },
            {
              name: t("page.chat-detail.breacrumbs.chat-detail"),
            },
          ]}
        />
      }
    >
      <ChatWindow
        canChat={
          chatDetails?.chatType === "single"
            ? chatDetails.privateChatInfo!.receiver.chatEnabled
            : chatDetails.groupChatInfo!.isMember
        }
        chatReplies={chatReplies}
        hasMoreReplies={hasMoreReplies}
        fetchMoreReplies={fetchMoreReplies}
        isLoadingMoreReplies={isLoadingMoreReplies}
        sendReply={sendNewReply}
        chatName={chatDetails?.groupChatInfo?.name}
      />

      <EditGroupChatModal
        isOpened={isEditGroupModalOpened}
        onOpenChange={editGroupModalHandler.set}
        chatId={chatId}
        groupInfo={chatDetails.groupChatInfo!}
      />
    </DocumentPaper>
  );
};

interface ChatDetailPageTitleProps {
  title: string | undefined;
  chatDetails: ChatDetailDto;
  onClickEditChat: () => void;
}

const ChatDetailPageTitle = ({ title, chatDetails, onClickEditChat }: ChatDetailPageTitleProps) => {
  const isEditChatAvailable = chatDetails?.chatType === "group" && chatDetails.groupChatInfo?.isMember;

  return (
    <div className="flex w-full items-center gap-2 overflow-hidden md:gap-4">
      {chatDetails.chatType === "single" && <UserAvatar img={chatDetails.privateChatInfo!.receiver.avatar} />}
      {chatDetails.chatType === "group" && (
        <div className="flex size-10 items-center justify-center rounded-full border border-grey-100 text-grey-400">
          {chatDetails.groupChatInfo?.image ? (
            <UserAvatar img={chatDetails.groupChatInfo.image} />
          ) : (
            <Icon name={users01Icon} size={24} />
          )}
        </div>
      )}
      <h1
        className={twJoin(
          "flex-1 truncate",
          isEditChatAvailable && "hover:cursor-pointer hover:text-aop-basic-blue-500",
        )}
        onClick={isEditChatAvailable ? onClickEditChat : undefined}
        role={isEditChatAvailable ? "button" : "heading"}
        tabIndex={isEditChatAvailable ? 0 : -1}
      >
        {title}
      </h1>
    </div>
  );
};
