import { ErrorPage } from "components/Error/ErrorPage";
import { canAccessReservations } from "components/ProjectSidebar/permissions";
import { RequireAuth } from "components/Routing/RequireAuth";
import type { RouteObject } from "react-router";

import { ReservationDetailsPage } from "./pages/ReservationDetails";
import { ReservationsListPage } from "./pages/ReservationsList";
import { reservationsRoutes } from "./routes";

export const reservationsRoute = [
  {
    path: reservationsRoutes.list,
    element: (
      <RequireAuth permission={canAccessReservations}>
        <ReservationsListPage />
      </RequireAuth>
    ),
  },
  {
    path: reservationsRoutes.reservationDetails,
    element: <ReservationDetailsPage />,
  },
  { path: "*", element: <ErrorPage status={404} /> },
] satisfies RouteObject[];
