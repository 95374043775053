import { twResolve } from "helpers/tw-resolve";
import { motion } from "motion/react";
import type React from "react";
import { createPortal } from "react-dom";

import { overlayVariants } from "./variants";

interface OverlayProps {
  className?: string;
  withPortal?: boolean;
  onClick?: () => void;
}

export function Overlay({ className, withPortal, onClick }: OverlayProps): React.ReactNode {
  const Component = (
    <motion.div
      className={twResolve(
        "fixed left-0 top-0 z-40 h-dvh w-screen bg-grey-900/60",
        className,
        onClick && "cursor-pointer",
      )}
      variants={overlayVariants}
      initial="hidden"
      animate="visible"
      exit="hidden"
      key="overlay"
      {...{ onClick }}
    />
  );

  if (withPortal) {
    return createPortal(Component, document.body);
  }

  return Component;
}
