import { ImageCropper } from "components/ImageCropper/ImageCropper";
import type { FormImage } from "components/ImageInput/useImageInput";
import { useImageInput } from "components/ImageInput/useImageInput";
import type { ImageMediaProps } from "components/Media/ImageMedia";
import { AvatarMedia, ImageMedia } from "components/Media/ImageMedia";
import { checkFileSize } from "helpers/file-size";
import { ALLOWED_FILE_TYPES } from "helpers/file-types";
import { isDefined } from "helpers/util";
import { useBool } from "hooks/useBool";
import { useCombinedRefs } from "hooks/useCombinedRef";
import { range } from "lodash-es";
import type { ChangeEvent, ForwardedRef } from "react";
import type React from "react";
import { forwardRef, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

export interface ImageInputProps extends Pick<React.InputHTMLAttributes<HTMLInputElement>, "id" | "aria-invalid"> {
  ref?: ForwardedRef<HTMLInputElement>;
  value: FormImage[];
  onChange: (files: FormImage[]) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  nOfImages?: number;
  theme?: "light" | "dark";
  isAvatarInput?: boolean;
  cropper?: boolean;
  texts?: ImageMediaProps["texts"];
  disabled?: boolean;
}

export const ImageInput = forwardRef<HTMLInputElement, ImageInputProps>(function ImageInput(
  { value: images, onChange, nOfImages = 1, theme = "light", cropper = false, texts, isAvatarInput, ...props },
  ref,
) {
  const [isDragging, setIsDragging] = useState(false);
  // Cropper
  const [isCropperModalOpen, cropperModalHandler] = useBool(false);
  const [cropperImgFile, setCropperImgFile] = useState<File | null>(null);
  const [savedCropperImgFiles, setSavedCropperImgFiles] = useState<File[]>([]);

  const replacementIndex = useRef<number | undefined>(undefined);
  const innerRef = useRef<HTMLInputElement>(null);
  const combinedRef = useCombinedRefs(innerRef, ref);

  const { t } = useTranslation();

  const { addImage, addImages, removeImage, replaceImage } = useImageInput({
    selectedImages: images,
    maximumFiles: nOfImages,
    onChange,
  });

  const onOpenFileSelector = () => {
    if (!combinedRef.current) return;

    combinedRef.current.click();
  };

  const onRemoveFile = (idx: number) => {
    if (cropperImgFile) {
      setCropperImgFile(null);
    }

    if (combinedRef.current) {
      removeImage(images[idx]);
      combinedRef.current.value = "";
      props.onBlur?.();
    }
  };

  const onEditFile = (idx: number) => {
    replacementIndex.current = idx;

    if (cropper) {
      if (!cropperImgFile || !savedCropperImgFiles.includes(cropperImgFile)) {
        onOpenFileSelector();

        return;
      }

      cropperModalHandler.setTrue();

      return;
    }

    onOpenFileSelector();
  };

  const onChangeFile = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files === null) {
      return;
    }

    if (isDefined(replacementIndex.current)) {
      replaceImage(images[replacementIndex.current], event.target.files[0]);
      replacementIndex.current = undefined;
    } else {
      addImages(event.target.files);
    }
  };

  const onSaveCroppedFile = (croppedImage: File) => {
    setSavedCropperImgFiles((prev) => [...prev, cropperImgFile!]);
    if (isDefined(replacementIndex.current)) {
      replaceImage(images[replacementIndex.current], croppedImage);
      replacementIndex.current = undefined;
    } else {
      addImage(croppedImage);
    }
  };

  const onCroppedFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files === null) {
      return;
    }

    setCropperImgFile(event.target.files[0]);
    cropperModalHandler.setTrue();

    if (combinedRef && combinedRef.current) {
      combinedRef.current.value = "";
    }

    return;
  };

  return (
    <>
      <div
        className="flex flex-wrap gap-2"
        onDrop={(event) => {
          if (event.dataTransfer.files && combinedRef.current) {
            combinedRef.current.files = event.dataTransfer.files;
            addImages(event.dataTransfer.files);
            setIsDragging(false);
            props.onBlur?.();

            event.preventDefault();
          }
        }}
        onDragOver={(event) => {
          event.preventDefault();
          event.dataTransfer.dropEffect = "copy";
        }}
        onDragOverCapture={() => setIsDragging(true)}
        onDragLeaveCapture={() => setIsDragging(false)}
      >
        {[...range(nOfImages)].map((idx) => {
          const disabled = images?.length < idx;

          if (disabled) return;

          if (isAvatarInput) {
            return (
              <AvatarMedia
                data-testid={`image_media_${idx}`}
                key={`ticket_img_frag_${idx}`}
                src={images?.[idx]?.url}
                aria-invalid={props["aria-invalid"] || (images?.[idx] && checkFileSize(images[idx]))}
                onAdd={() => onOpenFileSelector()}
                onDelete={() => onRemoveFile(idx)}
                onEdit={() => onEditFile(idx)}
                onFocus={props.onFocus}
                onBlur={props.onBlur}
                {...props}
                className={isDragging ? "border-aop-basic-blue-500" : undefined}
                disabled={props.disabled}
              />
            );
          }

          return (
            <ImageMedia
              data-testid={`image_media_${idx}`}
              key={`ticket_img_frag_${idx}`}
              src={images[idx]?.url}
              aria-invalid={props["aria-invalid"] || (images[idx] && checkFileSize(images[idx]))}
              onAdd={() => onOpenFileSelector()}
              onDelete={() => onRemoveFile(idx)}
              onEdit={() => onEditFile(idx)}
              onFocus={props.onFocus}
              onBlur={props.onBlur}
              theme={theme}
              texts={texts}
              {...props}
              className={isDragging ? "border-aop-basic-blue-500" : undefined}
              disabled={props.disabled}
            />
          );
        })}

        <input
          data-testid="image_input"
          tabIndex={-1}
          type="file"
          {...props}
          accept={ALLOWED_FILE_TYPES.IMAGE}
          className="sr-only"
          ref={combinedRef}
          onChange={cropper ? onCroppedFileChange : onChangeFile}
          disabled={props.disabled}
          multiple={nOfImages > 1}
        />
      </div>

      {cropper && (
        <ImageCropper
          title={t("model.service.profile-image.modal.title")}
          isOpen={isCropperModalOpen}
          onOpenChange={cropperModalHandler.set}
          onSave={onSaveCroppedFile}
          imgFile={cropperImgFile}
        />
      )}
    </>
  );
});
