import { ErrorPage } from "components/Error/ErrorPage";
import { RequireAuth } from "components/Routing/RequireAuth";
import type { RouteObject } from "react-router";

import { TicketStatusCreateOrEditPage } from "./pages/Details";
import { TicketStatusListPage } from "./pages/List";
import { canListStatuses, canManageStatuses } from "./permissions";
import { ticketStatusesRoutes } from "./routes";

export const ticketStatusesRoute = [
  {
    path: ticketStatusesRoutes.list,
    element: (
      <RequireAuth permission={canListStatuses}>
        <TicketStatusListPage />
      </RequireAuth>
    ),
  },
  {
    path: ticketStatusesRoutes.create,
    element: (
      <RequireAuth permission={canManageStatuses}>
        <TicketStatusCreateOrEditPage />
      </RequireAuth>
    ),
  },
  {
    path: ticketStatusesRoutes.edit,
    element: (
      <RequireAuth permission={canManageStatuses}>
        <TicketStatusCreateOrEditPage />
      </RequireAuth>
    ),
  },
  {
    path: "*",
    element: <ErrorPage status={404} />,
  },
] satisfies RouteObject[];
