import { ErrorPage } from "components/Error/ErrorPage";
import { canAccessAdminNotifications } from "components/ProjectSidebar/permissions";
import { RequireAuth } from "components/Routing/RequireAuth";
import type { RouteObject } from "react-router";

import { AdminNotificationsPage } from "./pages/List";
import { NotificationSettingsPage } from "./pages/Settings";
import { canEditAnyNotificationSettings, canEditOwnNotificationSettings } from "./permissions";
import { adminNotificationsRoutes } from "./routes";

export const adminNotificationsRoute = [
  {
    path: adminNotificationsRoutes.list,
    element: (
      <RequireAuth permission={canAccessAdminNotifications}>
        <AdminNotificationsPage />
      </RequireAuth>
    ),
  },
  {
    path: adminNotificationsRoutes.selfSettings,
    element: (
      <RequireAuth permission={canEditOwnNotificationSettings}>
        <NotificationSettingsPage />
      </RequireAuth>
    ),
  },
  {
    path: adminNotificationsRoutes.otherUserSettings,
    element: (
      <RequireAuth permission={canEditAnyNotificationSettings}>
        <NotificationSettingsPage />
      </RequireAuth>
    ),
  },
  {
    path: "*",
    element: <ErrorPage status={404} />,
  },
] satisfies RouteObject[];
