import { twResolve } from "helpers/tw-resolve";
import { twJoin } from "tailwind-merge";

export function FormContent({
  maxWidth = "sm",
  children,
  className,
  ...props
}: React.PropsWithChildren<{
  maxWidth?: "sm" | "md" | "lg" | "xl" | "2xl" | "3xl" | "4xl" | "none";
  "data-testid"?: string;
  className?: string;
}>): React.ReactNode {
  function getStyle(max: typeof maxWidth) {
    switch (max) {
      case "4xl":
        return "max-w-4xl";
      case "3xl":
        return "max-w-3xl";
      case "2xl":
        return "max-w-2xl";
      case "xl":
        return "max-w-xl";
      case "lg":
        return "max-w-lg";
      case "md":
        return "max-w-md";
      case "none":
        return "";
      case "sm":
      default:
        return "max-w-sm";
    }
  }

  return (
    <div {...props} className={twResolve("mb-7 flex flex-col gap-7", getStyle(maxWidth), className)}>
      {children}
    </div>
  );
}

export function FormSplitContent({
  splitAt = "sm",
  children,
}: React.PropsWithChildren<{ splitAt?: "sm" | "md" | "lg" | "xl" | "2xl" }>): React.ReactNode {
  function getStyle(split: typeof splitAt) {
    switch (split) {
      case "2xl":
        return "2xl:flex-row 2xl:gap-7";
      case "xl":
        return "2xl:flex-row 2xl:gap-7";
      case "lg":
        return "lg:flex-row lg:gap-7";
      case "md":
        return "md:flex-row md:gap-7";
      default:
        return "sm:flex-row sm:gap-7";
    }
  }

  return <div className={twJoin("flex flex-col justify-items-stretch gap-5", getStyle(splitAt))}>{children}</div>;
}

type FormCheckboxGridProps<T extends { label: string; [key: string]: React.ReactNode }> = {
  rows: T[];
  headerLabels: { [key in Exclude<keyof T, "label">]: string };
};

export function FormCheckboxGrid<T extends { label: string }>({
  headerLabels,
  rows,
}: FormCheckboxGridProps<T>): React.ReactNode {
  if (rows.length === 0) {
    return null;
  }

  const labels = Object.entries<string>(headerLabels);

  return (
    <table>
      <thead>
        <tr>
          {labels.map(([key, value]) => (
            <th className="whitespace-nowrap px-2 first-of-type:pl-0" key={key}>
              <span className="text-caption">{value}</span>
            </th>
          ))}
          <th />
        </tr>
      </thead>
      <tbody>
        {rows.map((row, i) => (
          <tr key={i}>
            {labels.map(([key]) => (
              <td
                className={twJoin("px-2 text-center leading-[0] first-of-type:pl-0", i === 0 ? "pt-1.5" : "pt-3")}
                key={key}
              >
                {row[key as keyof typeof row] as React.ReactNode}
              </td>
            ))}
            <td className={i === 0 ? "pt-1.5" : "pt-3"}>{row.label}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
