import { ErrorPage } from "components/Error/ErrorPage";
import { canAccessCalendar } from "components/ProjectSidebar/permissions";
import { RequireAuth } from "components/Routing/RequireAuth";
import { ReservationDetailsPage } from "modules/reservations/pages/ReservationDetails";
import type { RouteObject } from "react-router";

import { CalendarPage } from "./pages/Calendar";
import { calendarRoutes } from "./routes";

export const calendarRoute = [
  {
    path: calendarRoutes.list,
    element: (
      <RequireAuth permission={canAccessCalendar}>
        <CalendarPage />
      </RequireAuth>
    ),
  },
  {
    path: calendarRoutes.reservationDetails,
    element: <ReservationDetailsPage />,
  },
  { path: "*", element: <ErrorPage status={404} /> },
] satisfies RouteObject[];
