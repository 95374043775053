import type { ImageDto } from "api/types";
import iconFileAttachment01 from "assets/icons/file-attachment-01.svg";
import { Icon } from "components/Icon/Icon";
import { MediaXButton } from "components/Media/MediaXButton";
import { formatFileSize } from "helpers/file-size";
import { twJoin } from "tailwind-merge";

interface PdfProps {
  previewImage?: ImageDto;
  fileName?: string;
  fileSize?: number;
  onClick?: () => void;
  onDelete?: () => void;
  isInvalid?: boolean;
}

export function Pdf({ previewImage, fileName, fileSize, isInvalid, onClick, onDelete }: PdfProps): React.ReactNode {
  const Component = onClick ? "button" : "div";

  return (
    <Component
      data-testid="document-attachment"
      className={twJoin(
        "flex h-20 items-center gap-2 rounded-lg border bg-grey-100 p-4 text-left transition-colors",
        isInvalid ? "border-red-600" : "border-transparent",
        onClick ? "cursor-pointer  hocus:bg-grey-300/50" : undefined,
      )}
      type={onClick ? "button" : undefined}
      {...{ onClick }}
    >
      {previewImage ? (
        <div
          className="size-12 bg-contain bg-center bg-no-repeat"
          style={{
            backgroundImage: `url(${previewImage.url})`,
          }}
        />
      ) : (
        <div className="flex justify-center px-1 text-black">
          <Icon name={iconFileAttachment01} size={32} />
        </div>
      )}
      <div className="flex flex-col justify-center gap-2 text-black">
        <span className="line-clamp-1 max-w-60 break-all text-body-bold leading-none">{fileName || "..."}</span>
        {fileSize ? (
          <p className="leading-none text-grey-600">
            {formatFileSize(fileSize, 0)}
            {fileName?.toLowerCase().endsWith("pdf") ? " PDF" : ""}
          </p>
        ) : null}
      </div>
      {onDelete && (
        <div className="ml-4">
          <MediaXButton
            onClick={(e) => {
              e.stopPropagation();
              onDelete();
            }}
          />
        </div>
      )}
    </Component>
  );
}
