import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { QuickReplyMessageCommentDto, QuickReplyMessageCreateCommentRequest } from "api/types";
import iconEyeOff from "assets/icons/eye-off.svg";
import iconMessageSquare02 from "assets/icons/message-square-02.svg";
import iconThumbsUp from "assets/icons/thumbs-up.svg";
import { Button } from "components/Button/Button";
import { CommentFieldWithAvatar } from "components/CommentField/CommentField";
import { ErrorPage } from "components/Error/ErrorPage";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { Icon } from "components/Icon/Icon";
import type { FormImage } from "components/ImageInput/useImageInput";
import { useImageInput } from "components/ImageInput/useImageInput";
import { Snackbar } from "components/Snackbar/Snackbar";
import type { FormVideo } from "components/VideoInput/useVideoInput";
import { isVideoUploaded, useVideoInput } from "components/VideoInput/useVideoInput";
import { useUploadQuickReplyImage } from "hooks/Network/useUploadQuickReplyImage";
import { useUploadQuickReplyVideo } from "hooks/Network/useUploadQuickReplyVideo";
import { useBool } from "hooks/useBool";
import { useDocumentTitle } from "hooks/useDocumentTitle";
import { usePrompt } from "hooks/usePrompt";
import { useSetAtom } from "jotai";
import { sumBy, uniqBy } from "lodash-es";
import { CommunityPost } from "modules/quick-reply/components/CommunityPost/CommunityPost";
import { CommunityPostCommentSection } from "modules/quick-reply/components/CommunityPost/CommunityPostCommentSection";
import { AnimatePresence } from "motion/react";
import { useConfig } from "providers/ConfigProvider";
import { StopGlobalLoadingSpinner } from "providers/GlobalLoadingSpinner";
import { useQuickReplyMessageQueries } from "queries/quickReplyMessages";
import { quickReplyMesssageMutations } from "queries/quickReplyMessages/mutations";
import type { MouseEvent } from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { routes } from "routes";
import { languageAtom } from "state/app-language";

import { QuickReplyMenuBar } from "../components/QuickReplyMenuBar";

export function QuickReplyMessagePage(): React.ReactNode {
  const { token } = useParams<{ token: string }>();
  const response = useData(token!);
  const setLanguage = useSetAtom(languageAtom);

  useEffect(() => {
    if (response.data) {
      setLanguage(response.data.loggedInUser.language.id);
    }
  }, [response.data, setLanguage]);

  if (!response.data) {
    if (response.isLoading) {
      return null;
    }

    return <ErrorPage status={(response.error as unknown as Response)?.status || 0} />;
  }

  return (
    <>
      <StopGlobalLoadingSpinner />
      <QuickReplyMessagePageInternal {...response} />
    </>
  );
}

function QuickReplyMessagePageInternal({
  token,
  data,
  comments,
  isFetchingMoreComments,
  uploadImage,
  uploadVideo,
  editReply,
  reply,
  unlike,
  like,
  likeComment,
  unlikeComment,
  deleteComment,
  hasMoreComments,
  fetchMoreComments,
  onHideTranslation,
  onTranslate,
  translation,
  translationIsLoading,
}: ReturnType<typeof useData>) {
  const [images, setImages] = useState<FormImage[]>([]);
  const [videos, setVideos] = useState<FormVideo[]>([]);

  const isVideoAttachmentAllowed = useConfig("isVideoAttachmentAllowed");
  const { t } = useTranslation();
  const { addImages, removeImage, removeImages } = useImageInput({ selectedImages: images, onChange: setImages });
  const { uploadFormVideo } = useUploadQuickReplyVideo({
    usage: "message",
    token,
    onProgress: ({ name, progress }) => {
      setVideos((prevState) =>
        prevState.map((video) => {
          if (!isVideoUploaded(video) && video.file.name === name) {
            return { ...video, uploadProgress: progress };
          } else {
            return video;
          }
        }),
      );
    },
  });
  const { addVideos, removeVideo, removeVideos } = useVideoInput({
    selectedVideos: videos,
    onChange: setVideos,
    uploadFn: uploadFormVideo,
  });
  const { getValues, setValue, watch, register } = useForm<{ commentValue: string }>();
  const [editingComment, setEditingComment] = useState<QuickReplyMessageCommentDto>();
  const [deletedComment, setDeletedComment] = useState<QuickReplyMessageCommentDto>();

  const { loggedInUser } = data || {};

  const readonly = !!data?.deletedAt;

  useDocumentTitle(t("page.messages-email.title"));

  useEffect(() => {
    register("commentValue");
  }, [register]);

  const commentValue = watch("commentValue");
  const filteredComments = useMemo(
    () =>
      deletedComment ? markDeletedComments(comments, deletedComment.id, loggedInUser?.isSuperAdmin || false) : comments,
    [comments, loggedInUser?.isSuperAdmin, deletedComment],
  );

  async function onReply() {
    const { commentValue } = getValues();

    const payload: QuickReplyMessageCreateCommentRequest = {
      content: commentValue,
      imageId: undefined,
    };

    if (images.length > 0) {
      const image = images[0];
      const uploadedImage = await uploadImage(image);

      if (uploadedImage) {
        payload.imageId = uploadedImage.id;
      }
    }

    if (videos.length > 0) {
      const video = videos[0];
      const uploadedVideo = await uploadVideo(video);

      if (uploadedVideo) {
        payload.videoIds = [uploadedVideo.id];
      }
    }

    if (editingComment) {
      await editReply({
        replyId: editingComment.id,
        payload,
        failureMessage: t("page.quick-reply-message.comment-edit-error"),
      });
      setEditingComment(undefined);
    } else {
      await reply({
        payload,
        failureMessage: t("page.quick-reply-message.comment-error"),
      });
    }

    removeImages();
    removeVideos();
    setValue("commentValue", "");
  }

  const onEdit = useCallback(
    (comment: QuickReplyMessageCommentDto) => {
      setValue("commentValue", comment.content || "");
      setEditingComment(comment);
      setImages(comment.images);
      setVideos(comment.videos);
    },
    [setValue, setImages],
  );

  const onChangeContent = useCallback((value: string) => setValue("commentValue", value), [setValue]);

  const onCancelEdit = useCallback(() => {
    setValue("commentValue", "");
    removeImages();
    removeVideos();
    setEditingComment(undefined);
  }, [removeImages, removeVideos, setValue]);

  const onRemoveImage = useCallback(
    (imageToBeRemoved: FormImage) => {
      removeImage(imageToBeRemoved);
    },
    [removeImage],
  );

  const onToggleLike = useCallback(
    async function onToggleLike(event: MouseEvent) {
      event.preventDefault();

      if (data?.liked) {
        await unlike();
      } else {
        await like();
      }
    },
    [data?.liked, like, unlike],
  );

  const onDeleteComment = useCallback(
    async function onDeleteComment() {
      if (deletedComment) {
        setDeletedComment(undefined);
        await deleteComment({
          replyId: deletedComment.id,
          failureMessage: t("page.quick-reply-message.comment-delete-error"),
        });
      }
    },
    [deleteComment, deletedComment, t],
  );

  usePrompt(t("component.comment-field.confirm-not-undo"), deletedComment !== undefined);

  if (!data || !loggedInUser || !data.author) {
    return null;
  }

  return (
    <div className="min-h-full overflow-x-hidden">
      <QuickReplyMenuBar
        user={loggedInUser}
        loginUrl={routes.messageFeed.details({ slug: data.projectSlug, id: data.id || "" })}
        isSmall
      />
      <main className="px-0 py-2 pb-24 sm:p-4">
        <div className="mx-auto max-w-3xl rounded-sm bg-white shadow-md">
          <div className="p-5">
            {data.hiddenFromPropertyOwner ? (
              <div className="mb-2 inline-flex whitespace-nowrap rounded-full bg-grey-100 px-2 py-0.5 text-grey-900">
                <Icon name={iconEyeOff} className="mr-2" />
                {t("page.quick-reply-message.hidden-from-property-owner")}
              </div>
            ) : null}

            <CommunityPost
              id="0"
              title={translation?.title || data.title}
              content={translation?.content || data.content}
              images={data.images}
              videos={data.videos}
              documents={data.documents}
              author={data.author}
              group={data.group?.name}
              postedAt={data.postedAt}
              isDeleted={!!data.deletedAt}
              projectConnection={data.projectConnection}
              archivedAt={data.archivedAt}
              updatedAt={data.updatedAt}
            />
          </div>

          <div className="ml-auto px-5 pb-5">
            {data.languageIsoCode !== data.loggedInUser.language.id && (
              <Button
                styling="ghostPrimary"
                isLoading={translationIsLoading}
                onClick={() => {
                  if (translation) {
                    onHideTranslation();
                  } else {
                    onTranslate();
                  }
                }}
              >
                {translation
                  ? t("component.community-post.content.translate.original")
                  : t("component.community-post.content.translate")}
              </Button>
            )}
          </div>

          <div className="flex items-center p-5 pt-0 text-aop-basic-blue-500">
            <div className="min-w-16">
              <Button
                styling="ghostPrimary"
                data-testid="like"
                onClick={onToggleLike}
                title={t("common.action.like")}
                icon={<Icon name={iconThumbsUp} />}
                disabled={readonly}
              >
                {data.likeCount}
              </Button>
            </div>
            <div className="flex h-6 items-center gap-2">
              <Icon name={iconMessageSquare02} />
              {data.commentCount}
            </div>
          </div>

          {filteredComments && filteredComments.length > 0 ? (
            <div className="border-t border-grey-300 px-3">
              <CommunityPostCommentSection
                token={token}
                user={loggedInUser}
                comments={filteredComments}
                isLoadingMore={isFetchingMoreComments}
                canLoadMore={hasMoreComments}
                isReadonly={readonly}
                canComment={data.canComment && !readonly}
                onEdit={onEdit}
                onDelete={setDeletedComment}
                onLoadMore={fetchMoreComments}
                onLike={likeComment}
                onUnlike={unlikeComment}
              />
            </div>
          ) : null}

          {readonly || !data.canComment ? (
            <div className="h-5" />
          ) : (
            <form className="p-5 pt-0">
              <div className="flex items-end">
                <CommentFieldWithAvatar
                  user={loggedInUser}
                  value={commentValue}
                  onChange={onChangeContent}
                  onSubmit={onReply}
                  isEdit={editingComment !== undefined}
                  onCancel={onCancelEdit}
                  allowedAttachments={isVideoAttachmentAllowed ? ["image", "video"] : ["image"]}
                  images={images}
                  onAddImages={addImages}
                  onRemoveImage={onRemoveImage}
                  videos={videos}
                  onAddVideo={addVideos}
                  onRemoveVideo={removeVideo}
                />
              </div>
            </form>
          )}
        </div>
      </main>
      <div className="fixed inset-0 top-auto mx-auto max-w-3xl">
        <AnimatePresence>
          {deletedComment ? (
            <Snackbar
              message={t("component.comment-field.comment-deleted")}
              actions={[
                {
                  name: t("common.action.undo"),
                  action: () => setDeletedComment(undefined),
                  "data-testid": "undo-delete-comment",
                },
              ]}
              timeoutInSeconds={5}
              onTimeout={onDeleteComment}
            />
          ) : null}
        </AnimatePresence>
      </div>
    </div>
  );
}

function useData(token: string) {
  const detailToken = ["quick-reply-message-detail", token];
  const detailTokenTranslation = ["quick-reply-message-detail", token, "translation"];
  const commentToken = ["quick-reply-message-detail-comments", token];
  const commentAmount = 20;

  const queryClient = useQueryClient();
  const api = useApi();
  const showFlashToast = useFlashToast();

  const quickReplyMessageQueries = useQuickReplyMessageQueries();
  const { data, isLoading, error } = useQuery(quickReplyMessageQueries.details(token));
  const comments = useInfiniteQuery({
    queryKey: commentToken,
    queryFn: ({ pageParam = 0 }) => {
      if (pageParam === 0) {
        // These comments have already been fetched by the initial quick reply request
        return [];
      }

      return api
        .getQuickReplyMessageCommentsV1(token, {
          Offset: data!.comments.length + (pageParam - 1) * commentAmount,
          Limit: commentAmount,
        })
        .then((x) => x.data);
    },
    initialPageParam: 0,
    getNextPageParam: (_, pages) => {
      if (data && data.commentCount === sumBy(pages, (x) => x.length) + data.comments.length) {
        return undefined;
      }

      return pages.length;
    },
  });
  const addReplyMutation = quickReplyMesssageMutations.useAddReply({
    token,
    reply: data,
  });
  const { uploadFormImage } = useUploadQuickReplyImage({
    usage: "message",
    token,
  });
  const { uploadFormVideo } = useUploadQuickReplyVideo({
    usage: "message",
    token,
  });
  const updateReplyMutation = useMutation({
    mutationFn: ({
      replyId,
      payload,
    }: {
      replyId: string;
      payload: QuickReplyMessageCreateCommentRequest;
      failureMessage: string;
    }) => api.putQuickReplyMessageReplyV1(token, replyId, payload).then((x) => x.data),
    onSuccess(editedComment) {
      if (!data) {
        throw new Error("No data loaded");
      }

      function mapEditedComment(comment: QuickReplyMessageCommentDto) {
        if (comment.id === editedComment.id) {
          return editedComment;
        }

        return comment;
      }

      queryClient.setQueryData(detailToken, {
        ...data,
        comments: data.comments.map(mapEditedComment),
      });

      queryClient.setQueryData(commentToken, {
        ...comments.data,
        pages: comments.data?.pages.map((page) => page.map(mapEditedComment)),
      });
    },
    onError(_, { failureMessage }) {
      showFlashToast({ type: "error", title: failureMessage });
    },
  });
  const addLikeMutation = quickReplyMesssageMutations.useAddLike({ token, reply: data });
  const unlikeMutation = quickReplyMesssageMutations.useAddUnlike({ token, reply: data });
  const likeCommentMutation = useMutation({
    mutationFn: (commentId: string) => api.getQuickReplyMessageReplyLikeV1(token, commentId).then((x) => x.data),
    onMutate: (commentId) => {
      if (!data) {
        throw new Error("No data loaded");
      }

      queryClient.setQueryData(detailToken, {
        ...data,
        comments: data.comments.map((comment) => {
          if (comment.id === commentId) {
            return {
              ...comment,
              likeCount: comment.likeCount + 1,
              hasLiked: true,
            };
          } else if (comment.latestReply?.id === commentId) {
            return {
              ...comment,
              latestReply: {
                ...comment.latestReply,
                likeCount: comment.latestReply.likeCount + 1,
                hasLiked: true,
              },
            };
          }

          return comment;
        }),
      });

      return { previousData: data };
    },
    onError: (_, __, context) => {
      queryClient.setQueryData(detailToken, context?.previousData);
    },
  });
  const unlikeCommentMutation = useMutation({
    mutationFn: (commentId: string) => api.getQuickReplyMessageReplyUnlikeV1(token, commentId).then((x) => x.data),
    onMutate: (commentId) => {
      if (!data) {
        throw new Error("No data loaded");
      }

      queryClient.setQueryData(detailToken, {
        ...data,
        comments: data.comments.map((comment) => {
          if (comment.id === commentId) {
            return {
              ...comment,
              likeCount: comment.likeCount - 1,
              hasLiked: false,
            };
          } else if (comment.latestReply?.id === commentId) {
            return {
              ...comment,
              latestReply: {
                ...comment.latestReply,
                likeCount: comment.latestReply.likeCount - 1,
                hasLiked: false,
              },
            };
          }

          return comment;
        }),
      });

      return { previousData: data };
    },
    onError: (_, __, context) => {
      queryClient.setQueryData(detailToken, context?.previousData);
    },
  });
  const deleteCommentMutation = useMutation({
    mutationFn: ({ replyId }: { replyId: string; failureMessage: string }) =>
      api.deleteQuickReplyMessageReplyByIdV1(token, replyId).then((x) => x.data),
    onMutate: ({ replyId }) => {
      if (!data) {
        throw new Error("No data loaded");
      }

      queryClient.setQueryData(detailToken, {
        ...data,
        comments: markDeletedComments(data.comments, replyId, data.loggedInUser.isSuperAdmin),
      });

      queryClient.setQueryData(commentToken, {
        ...comments.data,
        pages: comments.data?.pages.map((page) => markDeletedComments(page, replyId, data.loggedInUser.isSuperAdmin)),
      });
    },
    onError: (_, { failureMessage }) => {
      showFlashToast({ type: "error", title: failureMessage });

      return queryClient.invalidateQueries({
        queryKey: detailToken,
      });
    },
  });

  const [showTranslation, showTranslationHandler] = useBool();
  const translation = useQuery({
    queryKey: detailTokenTranslation,
    queryFn: () => api.getQuickReplyMessageTranslationsDetailsV1(token, data?.loggedInUser.language.id || "-"),
    retry: false,
    enabled: showTranslation,
  });

  const allComments = useMemo(() => {
    if (!data?.comments) {
      return [];
    }

    const combinedComments = comments.data?.pages ? [...comments.data.pages.flat(), ...data.comments] : data.comments;

    return uniqBy(combinedComments, (x) => x.id);
  }, [data?.comments, comments.data?.pages]);

  return {
    data,
    error,
    isLoading,
    comments: allComments,
    isFetchingMoreComments: comments.isFetchingNextPage,
    hasMoreComments: comments.hasNextPage,
    fetchMoreComments: comments.fetchNextPage,
    uploadImage: uploadFormImage,
    uploadVideo: uploadFormVideo,
    reply: addReplyMutation.mutateAsync,
    editReply: updateReplyMutation.mutateAsync,
    unlike: unlikeMutation.mutateAsync,
    like: addLikeMutation.mutateAsync,
    likeComment: likeCommentMutation.mutateAsync,
    unlikeComment: unlikeCommentMutation.mutateAsync,
    deleteComment: deleteCommentMutation.mutateAsync,
    translation: showTranslation ? translation.data?.data : undefined,
    translationIsLoading: translation.isLoading,
    onTranslate: showTranslationHandler.setTrue,
    onHideTranslation: showTranslationHandler.setFalse,
    token,
  };
}

function markDeletedComments(
  comments: QuickReplyMessageCommentDto[],
  deletedReplyId: string,
  isSuperAdmin: boolean,
): QuickReplyMessageCommentDto[] {
  return comments.map((comment) => {
    if (comment.id === deletedReplyId) {
      return {
        ...comment,
        deletedAt: new Date().toISOString(),
        content: isSuperAdmin ? comment.content : undefined,
        images: isSuperAdmin ? comment.images : [],
      };
    }

    return comment;
  });
}
