import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { CommunityGroupCreateRequest, CommunityGroupEditRequest, CommunityGroupV2Dto } from "api/types";
import { ErrorPage } from "components/Error/ErrorPage";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { createFormTranslations, toTranslationsRequest, useLanguages } from "helpers/languages";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { createFormPermissions, toPermissionsRequest } from "helpers/permissions";
import { useProjectId } from "hooks/Network/useProjectId";
import { useUploadImage } from "hooks/Network/useUploadImage";
import { useSlug } from "hooks/useSlug";
import { QUERY_KEYS } from "query-keys";
import type React from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router";
import { routes } from "routes";

import type { CreateOrEditFormFields, CreateOrEditLayoutProps } from "./Layout";

interface Props {
  children: (props: Omit<CreateOrEditLayoutProps, "translations">) => React.ReactNode;
  type: CommunityGroupV2Dto["type"];
  notifications: {
    create: {
      success: string;
      error: string;
    };
    edit: {
      success: string;
      error: string;
    };
    realEstate?: {
      create: {
        success: string;
        error: string;
      };
      edit: {
        success: string;
        error: string;
      };
    };
  };
}

export function CreateOrEditLoader({ type, ...props }: Props): React.ReactNode {
  const projectId = useProjectId();
  const slug = useSlug();
  const { t } = useTranslation();
  const api = useApi();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { groupId: groupIdToDuplicate } = useMemo(
    () =>
      state && typeof state === "object" && "groupId" in state
        ? (state as { groupId: CommunityGroupV2Dto["id"] })
        : { groupId: undefined },
    [state],
  );
  const queryClient = useQueryClient();
  const showFlashToast = useFlashToast();
  const { id: groupId } = useParams<{ id: string }>();
  const isEditMode = groupId != null;

  const predefinedGroupId = groupId || groupIdToDuplicate;
  const { data: languages = [], isFetching: languagesIsFetching, error: languagesError } = useLanguages();

  const {
    data: groupDetails,
    isFetching: groupDetailsIsFetching,
    error: groupDetailsError,
  } = useQuery({
    queryKey: QUERY_KEYS.COMMUNITY_GROUP_DETAILS(projectId, predefinedGroupId!),
    queryFn: () => api.getGroupsDetailsV2(predefinedGroupId!),
    select: commonAPIDataSelector,
    enabled: isEditMode || groupIdToDuplicate != null,
    gcTime: 0,
  });

  const enableGroup = useMutation({
    mutationFn: api.postGroupsEnableV1,
    onSuccess: () => {
      showFlashToast({
        type: "success",
        title: t("page.community-groups.create-or-edit.enable.notification.success"),
      });
    },
    onError: () => {
      showFlashToast({
        type: "error",
        title: t("page.community-groups.create-or-edit.enable.notification.error"),
      });
    },
  });

  const disableGroup = useMutation({
    mutationFn: api.deleteGroupsByIdV1,
    onSuccess: () => {
      showFlashToast({
        type: "success",
        title: t("page.community-groups.create-or-edit.disable.notification.success"),
      });
    },
    onError: () => {
      showFlashToast({
        type: "error",
        title: t("page.community-groups.create-or-edit.disable.notification.error"),
      });
    },
  });

  const { uploadFormImage, isUploadingImage } = useUploadImage();
  const { mutate: editGroup, isPending: isSubmittingEdit } = useMutation({
    mutationFn: ({ payload }: { payload: CommunityGroupEditRequest; isRealEstate: boolean }) =>
      api.putGroupsV2(groupId!, payload).then(({ data }) => data),
    onSettled: () => {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.COMMUNITY_GROUP_DETAILS(projectId, groupId!) });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.COMMUNITY_GROUPS(projectId) });
    },
    onSuccess: (data, { isRealEstate }) => {
      showFlashToast({
        type: "success",
        title: isRealEstate ? props.notifications.realEstate!.edit.success : props.notifications.edit.success,
        "data-testid": "toast-group-is-edited",
      });
      void navigate(
        type === "interest"
          ? routes.interestGroups.details({ slug, id: data.id })
          : routes.helpCategories.details({ slug, id: data.id }),
      );
    },
    onError: (_, { isRealEstate }) => {
      showFlashToast({
        type: "error",
        title: isRealEstate ? props.notifications.realEstate!.edit.error : props.notifications.edit.error,
      });
    },
  });

  const { mutate: createGroup, isPending: isSubmittingCreate } = useMutation({
    mutationFn: ({ payload }: { payload: CommunityGroupCreateRequest; isRealEstate: boolean }) =>
      api.postGroupsV2(payload).then(({ data }) => data),
    onSettled: () => {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.COMMUNITY_GROUPS(projectId) });
    },
    onSuccess: (data, { isRealEstate }) => {
      showFlashToast({
        type: "success",
        title: isRealEstate ? props.notifications.realEstate!.create.success : props.notifications.create.success,
        "data-testid": "toast-group-is-created",
      });
      void navigate(
        type === "interest"
          ? routes.interestGroups.details({ slug, id: data.id })
          : routes.helpCategories.details({ slug, id: data.id }),
      );
    },
    onError: (_, { isRealEstate }) => {
      showFlashToast({
        type: "error",
        title: isRealEstate ? props.notifications.realEstate!.create.error : props.notifications.create.error,
      });
    },
  });

  const {
    data: defaultGroupsPermissions = [],
    isFetching: defaultGroupsPermissionsIsFetching,
    error: defaultGroupsPermissionsError,
  } = useQuery({
    queryKey: QUERY_KEYS.COMMUNITY_GROUPS_DEFAULT_PERMISSIONS(projectId),
    queryFn: () => api.getPermissionsDefaultGroupV1(),
    select: commonAPIDataSelector,
  });
  const {
    data: roles = [],
    isFetching: rolesIsFetching,
    error: rolesError,
  } = useQuery({
    queryKey: QUERY_KEYS.PERMISSION_ROLES(projectId),
    queryFn: () => api.getPermissionsRolesV1(),
    select: commonAPIDataSelector,
  });

  async function onSubmit(request: CreateOrEditFormFields) {
    let payload: CommunityGroupCreateRequest | CommunityGroupEditRequest = {
      nameTranslations: toTranslationsRequest(request.nameTranslations),
      descriptionTranslations: toTranslationsRequest(request.descriptionTranslations),
      icon: request.icon!,
      audience: request.audience,
      permissions: toPermissionsRequest(request.permissions),
      addAllResidents: !request.isRealEstate && request.isAllResidentsAdded,
    };

    if (request.image.length > 0) {
      const uploadedImage = await uploadFormImage(request.image[0]);

      payload = { ...payload, imageId: uploadedImage?.id };
    }

    if (request.enabled && groupDetails?.disabled) {
      await enableGroup.mutateAsync(groupId!);
    } else if (!request.enabled && !groupDetails?.disabled) {
      await disableGroup.mutateAsync(groupId!);
    }

    if (isEditMode) {
      editGroup({
        payload: { ...payload, postingLevel: request.postingLevel },
        isRealEstate: !!request.isRealEstate,
      });
    } else {
      createGroup({
        payload: { ...payload, type: request.isRealEstate ? "realEstate" : request.type },
        isRealEstate: !!request.isRealEstate,
      });
    }
  }

  if (isEditMode && groupDetails && !groupDetails.canEdit) {
    return <ErrorPage status={403} />;
  }

  if (isEditMode && groupDetails?.isResidentGroup) {
    return <ErrorPage status={403} />;
  }

  const error = groupDetailsError || rolesError || defaultGroupsPermissionsError || languagesError;
  if (error) {
    return <ErrorPage error={error} />;
  }

  const isPageLoading =
    groupDetailsIsFetching || rolesIsFetching || defaultGroupsPermissionsIsFetching || languagesIsFetching;
  if (isPageLoading) {
    return <FullSizeLoader withPadding />;
  }

  const isMultiProjectGroup =
    groupDetails?.projectConnectionGroupId !== undefined || groupDetails?.systemGroupId !== undefined;
  const defaultValues = {
    nameTranslations: createFormTranslations({ languages, translations: groupDetails?.nameTranslations }),
    descriptionTranslations: createFormTranslations({ languages, translations: groupDetails?.descriptionTranslations }),
    audience: groupDetails?.audience || [],
    icon: groupDetails?.icon,
    type,
    image: groupDetails?.image ? [groupDetails.image] : [],
    permissions: createFormPermissions(roles, defaultGroupsPermissions, groupDetails?.permissions),
    isRealEstate: groupDetails?.type === "realEstate",
    isAllResidentsAdded: false,
    postingLevel: groupDetails?.postingLevel,
    enabled: !groupDetails?.disabled,
  };

  return (
    <>
      {props.children({
        type,
        canToggleStatus: !!groupDetails?.canDelete && !!groupDetails?.systemGroupId,
        isMultiProjectGroup,
        // eslint-disable-next-line no-nested-ternary
        multiProjectGroupType: groupDetails?.systemGroupId
          ? "system"
          : groupDetails?.projectConnectionGroupId
            ? "connection"
            : undefined,
        isEditMode,
        languages,
        defaultValues,
        isSubmitting: isUploadingImage || isSubmittingCreate || isSubmittingEdit,
        onSubmit,
      })}
    </>
  );
}
