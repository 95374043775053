import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { ErrorPage } from "components/Error/ErrorPage";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { QUERY_KEYS } from "query-keys";
import { useState } from "react";
import { useParams } from "react-router";

import type { LayoutProps } from "./Layout";

interface LoaderProps {
  children: (props: LayoutProps) => React.ReactNode;
}

const PAGE_LIMIT = 10;

export function Loader(props: LoaderProps): React.ReactNode {
  const api = useApi();
  const [page, setPage] = useState<number>(0);
  const { id: surveyId } = useParams<{ id: string }>();

  const activeSurveyQueryParams = {
    Offset: page * PAGE_LIMIT,
    Limit: PAGE_LIMIT,
  };

  const {
    data: automatedSurveyDetails,
    isFetching: isLoadingAutomatedSurvey,
    error: automatedSurveyError,
  } = useQuery({
    queryKey: QUERY_KEYS.AUTOMATED_SURVEY_DETAILS(surveyId!),
    queryFn: () => api.getAutomatedSurveysDetailsV1(surveyId!),
    select: commonAPIDataSelector,
  });

  const {
    data: activeSurveys,
    isFetching: isLoadingActiveSurveys,
    error: activeSurveysError,
  } = useQuery({
    queryKey: QUERY_KEYS.AUTOMATED_SURVEY_ACTIVE_LIST(surveyId!, activeSurveyQueryParams),
    queryFn: () => api.getAutomatedSurveysActiveDetailsV1(surveyId!, activeSurveyQueryParams),
    select: commonAPIDataSelector,
    placeholderData: keepPreviousData,
    staleTime: 5000,
  });

  const error = automatedSurveyError || activeSurveysError;
  if (error) {
    return <ErrorPage error={error} />;
  }

  if (isLoadingAutomatedSurvey) {
    return <FullSizeLoader withPadding />;
  }

  const totalPages = activeSurveys ? Math.ceil(activeSurveys.total / PAGE_LIMIT) : 0;

  return props.children({
    surveyTitle: automatedSurveyDetails?.title ?? "",
    surveyType: automatedSurveyDetails?.category ?? "other",
    activeSurveys: activeSurveys?.items ?? [],
    isLoadingActiveSurveys,
    page,
    onPageChange: setPage,
    totalPages,
  });
}
