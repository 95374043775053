import { queryOptions } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { QUERY_KEYS } from "query-keys";
import type { ApiQueryParams } from "types/api-types";

export const useCommunityFeedQueries = () => {
  const projectId = useProjectId();
  const api = useApi();

  return {
    messages: (query: ApiQueryParams<"getMessagesV2">) =>
      queryOptions({
        queryKey: QUERY_KEYS.MESSAGES_FEED_OLD(projectId, query),
        queryFn: () => api.getMessagesV2(query),
        select: commonAPIDataSelector,
      }),
    audience: (query: { IncludeAnnouncements: boolean }) =>
      queryOptions({
        queryKey: QUERY_KEYS.MESSAGES_AUDIENCE(projectId, query),
        queryFn: async () => api.getCommunityFeedAudienceV1(query),
        select: commonAPIDataSelector,
      }),
  };
};
