import type { DocumentUploadDto } from "api/types";
import type { FormDocument } from "components/DocumentInput/useDocumentFile";
import { Pdf } from "components/Pdf/Pdf";
import type React from "react";

import { Media, type MediaBaseProps } from "./Media";

export interface DocumentMediaProps extends MediaBaseProps {
  document: FormDocument | DocumentUploadDto | undefined;
}

export function DocumentMedia({ document, ...props }: DocumentMediaProps): React.ReactNode {
  if (document) {
    const fileName = "file" in document ? document.file.name : document.fileName;
    let fileSize = "file" in document ? document.file.size : undefined;
    if ("fileSize" in document) {
      fileSize = document.fileSize;
    }

    return <Pdf onDelete={props.onDelete} isInvalid={!!props["aria-invalid"]} {...{ fileName, fileSize }} />;
  }

  return <Media {...props} type="document" />;
}
