import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { TicketRequest } from "api/types";
import { ErrorPage } from "components/Error/ErrorPage";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProject } from "hooks/Network/useProject";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useUploadImage } from "hooks/Network/useUploadImage";
import { getSessionStorageValue, useUpdateSessionStorage } from "hooks/useLocalStorage";
import { useQueryParam } from "hooks/useQueryParam";
import { useSlug } from "hooks/useSlug";
import { isArray } from "lodash-es";
import type { FormValues } from "modules/tickets/components/ResidentCreateTicketModal";
import type { ResidentTicketTabs } from "modules/tickets/constants";
import {
  RESIDENT_CLOSED_TICKET_TAB,
  RESIDENT_OPEN_TICKET_TAB,
  RESIDENT_RECENT_TICKET_TAB,
} from "modules/tickets/constants";
import { ticketStorageKeys } from "modules/tickets/filters";
import { QUERY_KEYS } from "query-keys";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { routes } from "routes";
import type { ApiQueryParams } from "types/api-types";

import type { LayoutProps } from "./Layout";

interface Props {
  children: (props: LayoutProps) => React.ReactNode;
}

const ITEM_AMOUNT = 30;
const TABS = [RESIDENT_OPEN_TICKET_TAB, RESIDENT_CLOSED_TICKET_TAB, RESIDENT_RECENT_TICKET_TAB];

type AllResidentTicketsQueryParams = ApiQueryParams<"getTicketsV1">;
export type ResidentTicketsFilters = Omit<AllResidentTicketsQueryParams, "Limit" | "Offset">;
export type OnUpdateParams = <T extends keyof ResidentTicketsFilters>(
  key: T,
  value: T extends keyof AllResidentTicketsQueryParams ? AllResidentTicketsQueryParams[T] : string,
) => void;

export function Loader({ children }: Props): React.ReactNode {
  const projectId = useProjectId();
  const slug = useSlug();
  const api = useApi();
  const sessionUser = useSessionUser();
  const queryClient = useQueryClient();
  const project = useProject();
  const { t } = useTranslation();
  const showFlashToast = useFlashToast();
  const navigate = useNavigate();
  const searchInputRef = useRef<HTMLInputElement>(null);

  const TICKET_FILTERS_KEY = ticketStorageKeys.filters(project.id);

  const [queryParamTab, setTab] = useQueryParam("tab");
  const defaultTab =
    queryParamTab && TABS.includes(queryParamTab as any) ? (queryParamTab as ResidentTicketTabs) : undefined;

  const [filters, updateFilters] = useState<ResidentTicketsFilters>(() => {
    const storage = getSessionStorageValue<Partial<ResidentTicketsFilters>>(TICKET_FILTERS_KEY, {});

    return {
      Search: typeof storage.Search === "string" ? storage.Search : "",
      StatusType: storage.StatusType && TABS.includes(storage.StatusType) ? storage.StatusType : undefined,
      StatusIds: isArray(storage.StatusIds) ? storage.StatusIds : [],
      CategoryIds: isArray(storage.CategoryIds) ? storage.CategoryIds : [],
      ReporterIds: isArray(storage.ReporterIds) ? storage.ReporterIds : [],
      RepairRequestFilterType: isArray(storage.RepairRequestFilterType) ? storage.RepairRequestFilterType : "both",
    };
  });
  useUpdateSessionStorage(TICKET_FILTERS_KEY, filters);

  const tab = filters.StatusType || "open";

  const clearFilters = useCallback(() => {
    updateFilters((query) => ({
      ...query,
      StatusIds: [],
      CategoryIds: [],
      ReporterIds: [],
      RepairRequestFilterType: "both",
      Search: "",
    }));

    // Uncontrolled input
    if (searchInputRef.current) {
      searchInputRef.current.value = "";
    }
  }, []);

  const onUpdateParams = useCallback<OnUpdateParams>((key, value) => {
    updateFilters((ticketFeedQuery) => ({
      ...ticketFeedQuery,
      [key]: value,
    }));
  }, []);

  useEffect(() => {
    if (defaultTab) {
      onUpdateParams("StatusType", defaultTab);
      setTab(null);
    }
  }, [defaultTab, onUpdateParams, setTab]);

  const filterCount = useMemo(() => {
    const { StatusIds, CategoryIds, ReporterIds, RepairRequestFilterType } = filters;

    return (
      // eslint-disable-next-line no-nested-ternary
      (StatusIds ? (StatusIds.length ? 1 : 0) : 0) +
      // eslint-disable-next-line no-nested-ternary
      (CategoryIds ? (CategoryIds.length ? 1 : 0) : 0) +
      // eslint-disable-next-line no-nested-ternary
      (ReporterIds ? (ReporterIds.length ? 1 : 0) : 0) +
      // eslint-disable-next-line no-nested-ternary
      (RepairRequestFilterType ? (RepairRequestFilterType !== "both" ? 1 : 0) : 0)
    );
  }, [filters]);

  const query = {
    ...filters,
    StatusType: tab,
  } satisfies AllResidentTicketsQueryParams;
  const {
    data: ticketData,
    hasNextPage: hasMoreTickets,
    isLoading: isLoadingTickets,
    isFetchingNextPage: isLoadingMoreTickets,
    fetchNextPage: loadMoreTickets,
    error: ticketsError,
  } = useInfiniteQuery({
    queryKey: QUERY_KEYS.TICKETS_RESIDENT_FEED(projectId, query),
    queryFn: ({ pageParam = 0 }) =>
      api
        .getTicketsV1({
          ...query,
          Limit: ITEM_AMOUNT,
          Offset: pageParam * ITEM_AMOUNT,
        })
        .then((items) => commonAPIDataSelector(items)),
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages) => {
      if (!lastPage.hasMore) {
        return undefined;
      }

      return pages.length;
    },
  });

  const { data: tabsStats, error: tabsStatsError } = useQuery({
    // exclude tab and sortby to prevent refetching because they do not affect the amount of tickets
    queryKey: QUERY_KEYS.TICKETS_RESIDENT_TABS_AMOUNT(projectId, { ...query, StatusType: undefined }),
    queryFn: () => api.getTicketsStatsAllStatusesV1({ ...query, StatusType: undefined }),
    select: commonAPIDataSelector,
  });

  const { data: totalTicketsInTab, error: totalTicketsInTabError } = useQuery({
    queryKey: QUERY_KEYS.TICKETS_RESIDENT_TAB_TOTAL(projectId, tab),
    queryFn: () => api.getTicketsStatsDetailsV1(tab),
    select: commonAPIDataSelector,
    enabled: filterCount > 0,
  });

  const {
    data: ticketFilterValues,
    isLoading: isLoadingTicketFilterValues,
    error: ticketFilterValuesError,
  } = useQuery({
    queryKey: QUERY_KEYS.TICKETS_FILTERS_VALUES(projectId),
    queryFn: () => api.getTicketsFiltersV1(),
    select: commonAPIDataSelector,
  });

  const {
    data: ticketCategories = [],
    isLoading: isLoadingTicketCategories,
    error: ticketCategoriesError,
  } = useQuery({
    queryKey: QUERY_KEYS.TICKET_CATEGORIES_RESIDENT(projectId),
    queryFn: () => api.getTicketCategoriesCreatableV1(),
    select: commonAPIDataSelector,
  });

  const { isPending: isCreating, mutate: create } = useMutation({
    mutationFn: (data: TicketRequest) => api.postTicketsV1(data),
    onSuccess: (response) => {
      showFlashToast({
        type: "success",
        title: t("page.tickets.create.action.create.notification.success"),
      });

      void navigate(routes.tickets.details({ slug, id: response.data.id }));
    },
    onError: () => {
      showFlashToast({
        type: "error",
        title: t("page.tickets.create.action.create.notification.error"),
        "data-testid": "request-error",
      });
    },
    onSettled: () => {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.TICKETS(projectId) });
    },
  });

  const { uploadFormImage, isUploadingImage } = useUploadImage();

  async function onSubmit(data: FormValues) {
    const imageIds: string[] | undefined = [];
    if (data.images) {
      for (const image of data.images) {
        const uploadedImage = await uploadFormImage(image);
        if (uploadedImage) {
          imageIds.push(uploadedImage.id);
        }
      }
    }

    create({
      categoryId: data.category!.id,
      visibility: data.visibility,
      title: data.subject,
      content: data.description,
      imageIds: imageIds.length ? imageIds : undefined,
      requestOrigin: "web",
    });
  }

  const totalTickets = ticketData?.pages[0].total;
  const tickets = useMemo(() => ticketData?.pages.flatMap((x) => x.items) ?? [], [ticketData]);

  if (ticketsError || totalTicketsInTabError || tabsStatsError || ticketFilterValuesError || ticketCategoriesError) {
    return <ErrorPage error={ticketsError} />;
  }

  if (isLoadingTicketFilterValues || isLoadingTicketCategories) {
    return <FullSizeLoader withPadding />;
  }

  return (
    <>
      {children({
        tab,
        queryParams: filters,
        sessionUser,
        onUpdateParams,
        totalTickets,
        totalTicketsInTab: filterCount > 0 ? totalTicketsInTab?.tickets : totalTickets,
        tabsStats,
        tickets,
        isLoadingTickets,
        hasMoreTickets,
        loadMoreTickets,
        isLoadingMoreTickets,
        ticketFilterValues,
        clearFilters,
        filterCount,
        ticketCategories,
        onTicketCreate: onSubmit,
        isCreatingTicket: isCreating || isUploadingImage,
        searchInputRef,
      })}
    </>
  );
}
