import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { QuickReplyMessageCreateCommentRequest, QuickReplyMessageDto } from "api/types";
import { useFlashToast } from "components/FlashToast/FlashToast";

const useAddReply = ({ token, reply }: { token: string; reply: QuickReplyMessageDto | undefined }) => {
  const api = useApi();
  const showFlashToast = useFlashToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ payload }: { payload: QuickReplyMessageCreateCommentRequest; failureMessage: string }) =>
      api.postQuickReplyMessageReplyV1(token, payload).then((x) => x.data),
    onSuccess(newComment) {
      if (!reply) {
        throw new Error("No data loaded");
      }

      queryClient.setQueryData(["quick-reply-message-detail", token], {
        ...reply,
        comments: [...reply.comments, newComment],
        commentCount: reply.commentCount + 1,
      });
    },
    onError(_, { failureMessage }) {
      showFlashToast({ type: "error", title: failureMessage });
    },
  });
};

const useAddLike = ({ token, reply }: { token: string; reply: QuickReplyMessageDto | undefined }) => {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => api.postQuickReplyMessageLikeV1(token).then((x) => x.data),
    onMutate: () => {
      if (!reply) {
        throw new Error("No data loaded");
      }

      queryClient.setQueryData(["quick-reply-message-detail", token], {
        ...reply,
        likeCount: reply.likeCount + 1,
        liked: true,
      });

      return { previousData: reply };
    },
    onError: (_, __, context) => {
      queryClient.setQueryData(["quick-reply-message-detail", token], context?.previousData);
    },
  });
};

const useAddUnlike = ({ token, reply }: { token: string; reply: QuickReplyMessageDto | undefined }) => {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => api.postQuickReplyMessageUnlikeV1(token).then((x) => x.data),
    onMutate: () => {
      if (!reply) {
        throw new Error("No data loaded");
      }

      queryClient.setQueryData(["quick-reply-message-detail", token], {
        ...reply,
        likeCount: reply.liked ? reply.likeCount - 1 : reply.likeCount,
        liked: false,
      });

      return { previousData: reply };
    },
    onError: (_, __, context) => {
      queryClient.setQueryData(["quick-reply-message-detail", token], context?.previousData);
    },
  });
};

export const quickReplyMesssageMutations = {
  useAddReply,
  useAddLike,
  useAddUnlike,
};
