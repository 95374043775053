import { useMutation } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { useFlashToast } from "components/FlashToast/FlashToast";
import type { OptimizeType } from "modules/messages/pages/AdminCreatePost/Loader";
import { useTranslation } from "react-i18next";

const useGetCopilotResponse = () => {
  const api = useApi();
  const { t } = useTranslation();
  const showFlashToast = useFlashToast();

  return useMutation({
    mutationFn: ({ input, option }: { input: string; option?: Exclude<OptimizeType, "refresh"> }) =>
      api.postCopilotV1({ userInput: input, copilotOptions: option ? [option] : [] }).then((x) => x.data),
    onError() {
      showFlashToast({ type: "error", title: t("page.message-feed.optimize.copilot-error") });
    },
  });
};

export const generalMutations = {
  useGetCopilotResponse,
};
