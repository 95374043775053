import { useQuery } from "@tanstack/react-query";
import iconNarrowDown from "assets/icons/arrow-narrow-down.svg";
import iconNarrowRight from "assets/icons/arrow-narrow-right.svg";
import iconNarrowUp from "assets/icons/arrow-narrow-up.svg";
import { Icon } from "components/Icon/Icon";
import { InfoIcon } from "components/InfoIcon/InfoIcon";
import { Label } from "components/Label/Label";
import { isDefined } from "helpers/util";
import { useAnalyticsQueries } from "queries/analytics/queryOptions";
import type React from "react";
import { useTranslation } from "react-i18next";

import { Speedometer } from "./Speedometer";

export function HomeFahWidgetScoreSection(): React.ReactNode {
  const { t } = useTranslation();

  const analyticsQueries = useAnalyticsQueries();
  const { data } = useQuery(analyticsQueries.getFah());

  return (
    <div className="mx-auto flex w-full flex-col items-center justify-center gap-8">
      <div className="flex w-full items-center justify-center gap-2 self-start">
        <h2 className="text-center text-headline2">{t("components.analytics-widgets.fah.title")}</h2>
        <InfoIcon tooltip={t("components.analytics-widgets.fah.info.tooltip")} />
      </div>
      <div className="flex w-full max-w-[376px] flex-col items-center gap-4 ">
        <Speedometer
          score={
            isDefined(data?.totalFeelingAtHomeScore?.rating) ? data?.totalFeelingAtHomeScore?.rating / 10 : undefined
          }
          labels={{
            min: "0",
            max: "10",
          }}
          renderCurrentValue={
            isDefined(data?.totalFeelingAtHomeScore?.rating) ? (score) => (score * 10).toFixed(1) : () => "-"
          }
        />
        <div className="flex w-full flex-col gap-1">
          <p className="text-center text-body">
            {t("components.analytics-widgets.fah.aop-benchmark.label")}{" "}
            {data?.benchmarkFeelingAtHomeScore?.rating?.toFixed(1) || "-"}
          </p>
          <p className="text-center text-body text-grey-700">
            {t("components.analytics-widgets.fah.aop-benchmark.description")}
          </p>
        </div>
      </div>
      <div className="w-full max-w-[376px] [&>*]:w-full">
        <HomeFahWidgetTrendLabel
          currValue={data?.totalFeelingAtHomeScore?.rating}
          prevValue={data?.lastFeelingAtHomeScore?.rating}
        />
      </div>
    </div>
  );
}

type HomeFahWidgetTrendLabelProps = {
  currValue: number | undefined;
  prevValue: number | undefined;
};

const HomeFahWidgetTrendLabel = ({ currValue, prevValue }: HomeFahWidgetTrendLabelProps) => {
  const { t } = useTranslation();

  if (!isDefined(currValue) || !isDefined(prevValue)) {
    return (
      <Label theme="blue" isUppercase={false}>
        <Icon name={iconNarrowRight} />
        {prevValue == null && currValue != null
          ? t("components.analytics-widgets.fah.trend.no-change")
          : t("common.label.not-applicable")}
      </Label>
    );
  }

  const difference = currValue - prevValue;
  let label = t("components.analytics-widgets.fah.aop-benchmark.neutral.description");
  let icon: string = iconNarrowRight;
  let theme: "green" | "yellow" | "blue" = "blue";
  if (difference > 0) {
    icon = iconNarrowUp;
    theme = "green";
    label = t("components.analytics-widgets.fah.aop-benchmark.increased.description", {
      difference: difference.toFixed(1),
    });
  } else if (difference < 0) {
    icon = iconNarrowDown;
    theme = "yellow";
    label = t("components.analytics-widgets.fah.aop-benchmark.decreased.description", {
      difference: difference.toFixed(1),
    });
  }

  return (
    <Label isUppercase={false} {...{ theme }}>
      <span className="flex items-center gap-1 normal-case">
        <Icon name={icon} />
        {label}
      </span>
    </Label>
  );
};
