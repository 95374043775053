import { type AnimationProps } from "motion/react";

export const projectSwitcherVariants: AnimationProps["variants"] = {
  hidden: {
    opacity: 0,
    y: -16,
    transition: {
      duration: 0.2,
      ease: "linear",
    },
  },
  visible: {
    opacity: 1,
    y: "0",
    transition: {
      duration: 0.2,
      ease: "linear",
    },
  },
  exit: {
    opacity: 0,
    height: 0,
    transition: {
      duration: 0,
    },
  },
};
