import type {
  ConnectedProjectDto,
  LanguageDto,
  TranslateRequest,
  TranslateResult,
  UserRoleTypeCommunityGroupPermissionsEntityRequest,
} from "api/types";
import iconGlobe02 from "assets/icons/globe-02.svg";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "components/Accordion/Accordion";
import { Breadcrumbs } from "components/Breadcrumbs/Breadcrumbs";
import { Button } from "components/Button/Button";
import { Checkbox } from "components/Checkbox/Checkbox";
import { Form } from "components/Form/Form";
import { FormCheckbox } from "components/Form/FormCheckbox";
import { FormContent } from "components/Form/FormContent";
import { FormErrorWrapper } from "components/Form/FormErrorWrapper";
import { FormField } from "components/Form/FormField";
import { FormGroupIconPicker } from "components/Form/FormGroupIconPicker";
import { FormImageInput } from "components/Form/FormImageInput";
import { FormInput } from "components/Form/FormInput";
import { FormMultiSelect } from "components/Form/FormMultiSelect";
import { FormRadio, FormRadioGroup } from "components/Form/FormRadio";
import { FormSelect } from "components/Form/FormSelect";
import { FormTextArea } from "components/Form/FormTextArea";
import { Icon } from "components/Icon/Icon";
import type { FormImage } from "components/ImageInput/useImageInput";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { PermissionListLayout, PermissionWrapper } from "components/Permission/Permission";
import { Wizard, WizardStep } from "components/Wizard/Wizard";
import { getFlagIcon } from "helpers/flags";
import type { FormTranslations } from "helpers/languages";
import { createImageSizeRule, createImageTypeRule, createRequiredStringRule } from "helpers/rules";
import type { FormPermissions } from "helpers/system-groups/permissions";
import { isDefined, sortAlphabetically } from "helpers/util";
import type { GroupIconType } from "modules/community-groups/components/GroupIcons";
import { useSystemSettingBreadcrumbs } from "modules/system-settings/util/useSystemSettingBreadcrumbs";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

export interface LayoutProps {
  languages: LanguageDto[];
  projects: ConnectedProjectDto[];
  defaultValues: FormValues;
  onTranslate: (payload: TranslateRequest) => Promise<TranslateResult[] | null>;
  isTranslating: boolean;
  onSubmit: (data: FormValues) => void;
  isSubmitting: boolean;
}

const MAX_LENGTH = { NAME: 40, DESCRIPTION: 400 };

export function Layout({
  languages,
  projects,
  defaultValues,
  onTranslate,
  isTranslating,
  onSubmit,
  isSubmitting,
}: LayoutProps): React.ReactNode {
  const { id: groupId } = useParams<{ id: string }>();
  const isEdit = isDefined(groupId);
  const { t } = useTranslation();
  const [isAllProjects, setIsAllProjects] = useState<boolean>(
    isEdit ? defaultValues.projects.length === projects.length : true,
  );
  const formMethods = useForm<FormValues>({
    defaultValues,
  });

  const canLeave = async () => await formMethods.trigger();

  const postingLevel = useWatch({ name: "postingLevel", control: formMethods.control });

  const breadcrumbs = useSystemSettingBreadcrumbs({ module: "platform-groups", status: isEdit ? "edit" : "create" });

  async function translate(field: "name" | "description", languageId: LanguageDto["id"]) {
    const value = formMethods.getValues(`${field}Translations.${languageId}`);
    if (!value) {
      return;
    }

    const result = await onTranslate({
      languages: languages.filter((l) => l.id !== languageId).map((l) => l.id),
      text: value,
    });
    if (result) {
      for (const translation of result) {
        formMethods.setValue(`${field}Translations.${translation.languages}`, translation.text);
      }
    }
  }

  const audience = useMemo(() => {
    if (postingLevel === "project") {
      return ["project"];
    } else if (postingLevel === "projectConnection") {
      return ["projectConnection"];
    } else {
      return ["project", "projectConnection"];
    }
  }, [postingLevel]);

  useEffect(() => {
    if (postingLevel === "project") {
      formMethods.setValue("audience", "project");
    } else if (postingLevel === "projectConnection") {
      formMethods.setValue("audience", "projectConnection");
    }
  }, [formMethods, postingLevel]);

  useEffect(() => {
    if (isAllProjects) {
      formMethods.setValue("projects", projects);
    }
  }, [formMethods, projects, isAllProjects]);

  return (
    <DocumentPaper
      theme="wide"
      title={isEdit ? t("page.platform-groups.edit.title") : t("page.platform-groups.create.title")}
      subTitle={<Breadcrumbs pages={breadcrumbs} />}
    >
      <Form formMethods={formMethods} onSubmit={onSubmit}>
        <Wizard
          id="help-category-wizard"
          actionsText={{
            finish: isEdit ? t("common.action.edit") : t("common.action.create"),
          }}
          isSubmitting={isSubmitting}
          onFinish={formMethods.handleSubmit(onSubmit)}
          strictOrder={!isEdit}
        >
          <WizardStep id={1} canLeave={canLeave} hasFinish={isEdit} title={t("page.platform-groups.wizard.general")}>
            <FormContent>
              <FormField label={t("page.platform-groups.form.type.label")}>
                <FormErrorWrapper<FormValues> name="type">
                  <FormRadioGroup>
                    <FormRadio<FormValues>
                      name="type"
                      value="interest"
                      displayValue={t("page.platform-groups.list.type.interest-group")}
                      rules={{
                        validate: {
                          required: createRequiredStringRule(t, "page.platform-groups.form.name.label"),
                        },
                      }}
                      disabled={isEdit}
                    />
                    <FormRadio<FormValues>
                      name="type"
                      value="helpCategory"
                      displayValue={t("page.platform-groups.list.type.help-category")}
                      rules={{
                        validate: {
                          required: createRequiredStringRule(t, "page.platform-groups.form.name.label"),
                        },
                      }}
                      disabled={isEdit}
                    />
                  </FormRadioGroup>
                </FormErrorWrapper>
              </FormField>
              {languages.map((lng) => {
                const nameId = `name-${lng.id}`;
                const descriptionId = `description-${lng.id}`;

                return (
                  <Fragment key={lng.id}>
                    <FormField
                      htmlFor={nameId}
                      label={t("page.platform-groups.form.name.label", { language: lng.poEditorCode.toUpperCase() })}
                      actions={
                        <Button
                          styling="ghostPrimary"
                          onClick={() => translate("name", lng.id)}
                          icon={<Icon name={iconGlobe02} size={16} />}
                        >
                          {t("page.platform-groups.translate")}
                        </Button>
                      }
                      required
                    >
                      <FormInput<FormValues>
                        name={`nameTranslations.${lng.id}`}
                        id={nameId}
                        rules={{
                          validate: {
                            required: createRequiredStringRule(t, "page.platform-groups.form.name.label", {
                              language: lng.poEditorCode.toUpperCase(),
                            }),
                          },
                          maxLength: {
                            message: t("components.form.error.max-length", {
                              length: MAX_LENGTH.NAME,
                            }),
                            value: MAX_LENGTH.NAME,
                          },
                        }}
                        prefix={getFlagIcon({ languageId: lng.id })}
                        placeholder={t("page.platform-groups.form.name.placeholder")}
                        disabled={isTranslating}
                      />
                    </FormField>
                    <FormField
                      htmlFor={descriptionId}
                      label={t("page.platform-groups.form.description.label", {
                        language: lng.poEditorCode.toUpperCase(),
                      })}
                      actions={
                        <Button
                          styling="ghostPrimary"
                          onClick={() => translate("description", lng.id)}
                          icon={<Icon name={iconGlobe02} size={16} />}
                        >
                          {t("page.platform-groups.translate")}
                        </Button>
                      }
                      required
                    >
                      <FormTextArea<FormValues>
                        name={`descriptionTranslations.${lng.id}`}
                        id={descriptionId}
                        rules={{
                          validate: {
                            required: createRequiredStringRule(t, "page.platform-groups.form.description.label", {
                              language: lng.poEditorCode.toUpperCase(),
                            }),
                          },
                          maxLength: {
                            message: t("components.form.error.max-length", {
                              length: MAX_LENGTH.DESCRIPTION,
                            }),
                            value: MAX_LENGTH.DESCRIPTION,
                          },
                        }}
                        icon={getFlagIcon({ languageId: lng.id })}
                        placeholder={t("page.platform-groups.form.description.placeholder")}
                        disabled={isTranslating}
                        compactHeight
                      />
                    </FormField>
                  </Fragment>
                );
              })}
              <FormField htmlFor="icon" label={t("page.platform-groups.form.icon.label")} required>
                <FormGroupIconPicker<FormValues>
                  data-testid="group-icon"
                  id="icon"
                  name="icon"
                  rules={{
                    required: t("components.form.error.required", {
                      inputName: t("page.platform-groups.form.icon.label"),
                    }),
                  }}
                />
              </FormField>
              <FormField htmlFor="imageFile" label={t("page.platform-groups.form.image.label")} required>
                <FormImageInput<FormValues, "image">
                  name="image"
                  id="imageFile"
                  rules={{
                    required: t("components.form.error.required", {
                      inputName: t("page.platform-groups.form.image.label"),
                    }),
                    validate: {
                      size: createImageSizeRule({ t }),
                      type: createImageTypeRule({ t }),
                    },
                  }}
                />
              </FormField>
              <FormField label={t("page.platform-groups.form.projects.label")} required>
                <label className="my-2 flex items-center gap-2">
                  <Checkbox
                    name={t("page.platform-groups.form.projects.checkbox")}
                    onChange={(e) => setIsAllProjects(e.target.checked)}
                    checked={isAllProjects}
                    disabled={isEdit}
                  />
                  <span className="text-caption">{t("page.platform-groups.form.projects.checkbox")}</span>
                </label>
                <FormMultiSelect<FormValues, "projects">
                  name="projects"
                  placeholder={t("page.platform-groups.form.projects.placeholder")}
                  items={projects ? projects : []}
                  sortSelectedItems={sortAlphabetically}
                  keySelector={(x) => x.id}
                  renderOption={(x) => x.name}
                  rules={{
                    required: t("components.form.error.required", {
                      inputName: t("page.platform-groups.form.projects.label"),
                    }),
                  }}
                  disabled={isAllProjects || isEdit}
                />
              </FormField>
              <FormField
                label={t("page.platform-groups.form.posting-level.label")}
                tooltip={t("page.platform-groups.form.posting-level.tooltip")}
              >
                <FormSelect<FormValues, string>
                  name="postingLevel"
                  placeholder={t("page.platform-groups.form.posting-level.placeholder")}
                  items={["project", "projectConnection", "projectAndProjectConnection"]}
                  keySelector={(x) => x}
                  renderOption={(x) => {
                    switch (x) {
                      case "project":
                        return t("page.platform-groups.form.posting-level.options.project");
                      case "projectConnection":
                        return t("page.platform-groups.form.posting-level.options.connection");
                      case "projectAndProjectConnection":
                        return t("page.platform-groups.form.posting-level.options.both");
                    }
                  }}
                  rules={{
                    required: t("components.form.error.required", {
                      inputName: t("page.platform-groups.form.posting-level.label"),
                    }),
                  }}
                  disabled={isEdit}
                />
              </FormField>
              <FormField
                label={t("page.platform-groups.form.audience.label")}
                tooltip={t("page.platform-groups.form.audience.tooltip")}
              >
                <FormSelect<FormValues, string>
                  name="audience"
                  placeholder={t("page.platform-groups.form.audience.placeholder")}
                  items={audience}
                  keySelector={(x) => x}
                  renderOption={(x) => {
                    switch (x) {
                      case "project":
                        return t("page.platform-groups.form.audience.options.project");
                      case "projectConnection":
                        return t("page.platform-groups.form.audience.options.connection");
                    }
                  }}
                  rules={{
                    required: t("components.form.error.required", {
                      inputName: t("page.platform-groups.form.audience.label"),
                    }),
                  }}
                  disabled={isEdit && postingLevel !== "projectAndProjectConnection"}
                />
              </FormField>
            </FormContent>
          </WizardStep>
          <WizardStep
            id={2}
            canLeave={canLeave}
            hasFinish={isEdit}
            title={t("page.platform-groups.wizard.permissions")}
          >
            <FormContent maxWidth="none">
              <Accordion allowMultipleExpanded allowZeroExpanded>
                {Object.values(defaultValues.permissions).map(({ name, roleType }) => (
                  <Fragment key={roleType}>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>{name}</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <PermissionListLayout>
                          <PermissionWrapper>
                            <FormCheckbox<FormValues>
                              alignTop
                              memorise={defaultValues.permissions[roleType].canCreatePost}
                              name={`permissions.${roleType}.canCreatePost`}
                              label={t("page.platform-groups.form.permissions.can-create-post")}
                            />
                          </PermissionWrapper>
                          <PermissionWrapper>
                            <FormCheckbox<FormValues>
                              alignTop
                              memorise={defaultValues.permissions[roleType].canCreateAnnouncement}
                              name={`permissions.${roleType}.canCreateAnnouncement`}
                              label={t("page.platform-groups.form.permissions.can-create-announcement")}
                            />
                          </PermissionWrapper>
                          <PermissionWrapper>
                            <FormCheckbox<FormValues>
                              alignTop
                              memorise={defaultValues.permissions[roleType]?.canListPosts}
                              name={`permissions.${roleType}.canListPosts`}
                              label={t("page.platform-groups.form.permissions.can-list-posts")}
                            />
                          </PermissionWrapper>
                          <PermissionWrapper>
                            <FormCheckbox<FormValues>
                              alignTop
                              memorise={defaultValues.permissions[roleType].canCommentPost}
                              name={`permissions.${roleType}.canCommentPost`}
                              label={t("page.platform-groups.form.permissions.can-comment")}
                            />
                          </PermissionWrapper>
                        </PermissionListLayout>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </Fragment>
                ))}
              </Accordion>
            </FormContent>
          </WizardStep>
        </Wizard>
      </Form>
    </DocumentPaper>
  );
}

export interface FormValues {
  type: "interest" | "helpCategory" | "realEstate";
  nameTranslations: FormTranslations;
  descriptionTranslations: FormTranslations;
  icon?: GroupIconType;
  image?: FormImage[];
  projects: ConnectedProjectDto[];
  permissions: FormPermissions<UserRoleTypeCommunityGroupPermissionsEntityRequest>;
  postingLevel: "project" | "projectConnection" | "projectAndProjectConnection";
  audience: "project" | "projectConnection";
}
