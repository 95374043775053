import { ErrorPage } from "components/Error/ErrorPage";
import type { RouteObject } from "react-router";

import { Home } from "./pages";
import { homeRoutes } from "./routes";

export const homeRoute = [
  {
    path: homeRoutes.home,
    element: <Home />,
  },
  { path: "*", element: <ErrorPage status={404} /> },
] satisfies RouteObject[];
