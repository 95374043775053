import { isDefined } from "helpers/util";
import { motion } from "motion/react";

interface SpeedometerProps {
  /**
   * Number between 0 and 1
   */
  score: number | undefined;
  labels?: {
    min?: string;
    max?: string;
  };
  renderCurrentValue?: (score: number) => string;
  withDelay?: boolean;
}

const MIN_ROTATION_DEG = -90;
const MAX_ROTATION_DEG = 90;
const TOTAL_ROTATION_DEG = MAX_ROTATION_DEG - MIN_ROTATION_DEG;

export function Speedometer({ score, labels, renderCurrentValue, withDelay }: SpeedometerProps): React.ReactNode {
  // Used to compute the rotation of the pointer
  let snappedScore = score;
  // Bit hacky but this makes it so 6 and 8 are just in the next segment
  if (isDefined(snappedScore) && snappedScore > 0.5 && snappedScore < 0.98) {
    snappedScore += 0.01;
  }

  const rotation = isDefined(snappedScore) ? MIN_ROTATION_DEG + TOTAL_ROTATION_DEG * snappedScore : 0;

  return (
    <div className="relative flex w-full items-end gap-2">
      {labels?.min && <span className="text-body leading-none">{labels.min}</span>}
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" fill="none" viewBox="0 0 160 80">
        <path
          className="fill-[#0A9520]"
          d="M157.274 59.294a79.998 79.998 0 00-26.928-41.466L115.242 36.48a56 56 0 0118.85 29.026l23.182-6.212z"
        />
        <path
          className="fill-[#0A9520]"
          d="M156 80c2.209 0 4.01-1.792 3.9-3.998a80.01 80.01 0 00-8.705-32.49c-1.008-1.965-3.464-2.617-5.377-1.512l-13.856 8c-1.914 1.105-2.555 3.545-1.59 5.533a55.997 55.997 0 015.485 20.47C136.015 78.207 137.791 80 140 80h16z"
        />
        <path
          className="fill-[#87BD1F]"
          d="M144.718 32.972a79.999 79.999 0 00-40.002-29.058L97.301 26.74a56 56 0 0128.002 20.34l19.415-14.108z"
        />
        <path
          className="fill-[#FFE423]"
          d="M104.721 3.915a79.999 79.999 0 00-49.442 0l7.416 22.826a56 56 0 0134.61 0l7.416-22.826z"
        />
        <path
          className="fill-[#F87F24]"
          d="M55.279 3.915a80 80 0 00-40 29.062l19.416 14.107a56 56 0 0128-20.343L55.279 3.915z"
        />
        <path
          className="fill-[#F11C28]"
          d="M18.515 35.328c-1.788-1.298-4.298-.907-5.506.943A80 80 0 00.1 76c-.11 2.208 1.69 4 3.9 4h16c2.21 0 3.985-1.793 4.143-3.997a56.001 56.001 0 018.318-25.602c1.168-1.875.785-4.37-1.002-5.668l-12.944-9.405z"
        />
        <path
          className="fill-[#F11C28]"
          d="M15.279 32.977a80 80 0 00-14.84 38.66l23.868 2.51a56 56 0 0110.388-27.063L15.28 32.977z"
        />
        {isDefined(snappedScore) && (
          <motion.path
            initial={{
              rotate: MIN_ROTATION_DEG,
            }}
            animate={{
              rotate: rotation,
            }}
            transition={{
              delay: withDelay ? 0.4 : 0.1,
              duration: 1,
              ease: [0.61, 0.04, 0.3, 0.99],
            }}
            className="!origin-[80px_80px] fill-black"
            key={rotation}
            fillRule="evenodd"
            d="M80,16 L87,28 H73 L80,16 Z"
            clipRule="evenodd"
          />
        )}
      </svg>
      {renderCurrentValue && (
        <h3
          style={{
            fontSize: "clamp(2rem, calc(2.5vw + 4vh), 4.5rem)",
            fontWeight: 600,
            lineHeight: "75%",
          }}
          className="absolute bottom-0 left-1/2 -translate-x-1/2"
        >
          {renderCurrentValue(score ? score : 0)}
        </h3>
      )}
      {labels?.min && <span className="text-body leading-none">{labels.max}</span>}
    </div>
  );
}
