import type { DocumentDto, ImageDto } from "api/types";
import type { FormDocument } from "components/DocumentInput/useDocumentFile";
import { useCallback } from "react";

export type FormImage = ImageDto | InputFile;

interface InputFile {
  file: File;
  url: string;
  uploadPromise?: Promise<ImageDto | undefined>;
}

interface Props {
  selectedImages: FormImage[];
  maximumFiles?: number;
  onChange: (images: FormImage[]) => void;
}

interface ImageInput {
  readonly addImage: (file: File) => void;
  readonly addImages: (files: FileList | null) => void;
  readonly replaceImage: (oldImage: FormImage, newFile: File) => void;
  readonly removeImage: (imageToRemove: FormImage) => void;
  readonly removeImages: () => void;
}

export function useImageInput({ selectedImages, maximumFiles = 1, onChange }: Props): ImageInput {
  const removeImage = useCallback(
    (imageToRemove: FormImage) => {
      const newSelectedImages = [];

      for (const image of selectedImages) {
        if (imageToRemove.url === image.url) {
          revokeFileUrl([image]);
        } else {
          newSelectedImages.push(image);
        }
      }

      onChange(newSelectedImages);
    },
    [onChange, selectedImages],
  );

  const removeImages = useCallback(() => {
    revokeFileUrl(selectedImages);
    onChange([]);
  }, [onChange, selectedImages]);

  const addImages = useCallback(
    (files: FileList | File[] | null) => {
      if (!files) {
        return;
      }

      const newlyAddedImages: InputFile[] = Array.from(files)
        .slice(0, maximumFiles)
        .map((file) => {
          const imageFile: InputFile = {
            url: URL.createObjectURL(file),
            file,
          };

          return imageFile;
        });

      const newSelectedImages = [...selectedImages, ...newlyAddedImages].filter((image) => Boolean(image));

      if (newSelectedImages.length > maximumFiles) {
        const omittedImages = newSelectedImages.splice(0, newSelectedImages.length - maximumFiles);

        revokeFileUrl(omittedImages);
      }

      onChange(newSelectedImages);
    },
    [maximumFiles, onChange, selectedImages],
  );

  const addImage = useCallback((file: File) => addImages([file]), [addImages]);

  const replaceImage = useCallback(
    (oldImage: FormImage, newFile: File) => {
      if (!newFile) {
        return;
      }

      const newSelectedImages = [];
      for (const image of selectedImages) {
        if (oldImage.url === image.url) {
          revokeFileUrl([image]);
          newSelectedImages.push({ url: URL.createObjectURL(newFile), file: newFile });
        } else {
          newSelectedImages.push(image);
        }
      }

      onChange(newSelectedImages);
    },
    [onChange, selectedImages],
  );

  return { addImage, addImages, replaceImage, removeImage, removeImages } as const;
}

export function isDocumentUploaded(document?: FormDocument): document is DocumentDto {
  return document ? "id" in document : false;
}

export function isImageUploaded(image?: FormImage): image is ImageDto {
  return image ? "id" in image : false;
}

function revokeFileUrl(images: FormImage[]) {
  for (const image of images) {
    if (!isImageUploaded(image)) {
      URL.revokeObjectURL(image.url);
    }
  }
}
