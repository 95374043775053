import type { DocumentDto } from "api/types";
import { useCallback } from "react";

export type FormDocument = DocumentDto | InputFile;

interface InputFile {
  file: File;
  uploadPromise?: Promise<DocumentDto | undefined>;
}

interface Props {
  selectedDocuments: FormDocument[];
  maximumFiles?: number;
  onChange: (documents: FormDocument[]) => void;
}

interface DocumentInput {
  readonly addDocuments: (files: FileList | null) => void;
  readonly removeDocument: (documentToRemove: FormDocument) => void;
  readonly removeDocuments: () => void;
}

export function useDocumentInput({ selectedDocuments, maximumFiles = 5, onChange }: Props): DocumentInput {
  const removeDocument = useCallback(
    (documentToRemove: FormDocument) => {
      let newSelectedDocuments: FormDocument[] = [];

      if (isDocumentUploaded(documentToRemove)) {
        newSelectedDocuments = selectedDocuments.filter(
          (document) => !isDocumentUploaded(document) || documentToRemove.id !== document.id,
        );
      } else {
        newSelectedDocuments = selectedDocuments.filter(
          (document) => isDocumentUploaded(document) || documentToRemove.file !== document.file,
        );
      }

      onChange(newSelectedDocuments);
    },
    [onChange, selectedDocuments],
  );

  const removeDocuments = useCallback(() => onChange([]), [onChange]);

  const addDocuments = useCallback(
    (files: FileList | null) => {
      if (!files) {
        return;
      }
      const newlyAddedDocuments: InputFile[] = Array.from(files)
        .slice(0, maximumFiles)
        .map((file) => {
          const documentFile: InputFile = {
            file,
          };

          return documentFile;
        });

      const newSelectedDocuments = [...selectedDocuments, ...newlyAddedDocuments].filter((video) => Boolean(video));

      onChange(newSelectedDocuments);
    },
    [maximumFiles, onChange, selectedDocuments],
  );

  return {
    addDocuments,
    removeDocument,
    removeDocuments,
  } as const;
}

export function isDocumentUploaded(document?: FormDocument): document is DocumentDto {
  return document ? "id" in document : false;
}
