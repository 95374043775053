import { type AnimationProps } from "motion/react";

export const sidebarGroupedItemAnimation: AnimationProps["variants"] = {
  hidden: {
    opacity: 0,
    height: 0,
    transition: {
      duration: 0.1,
      ease: "linear",
    },
  },
  visible: {
    opacity: 1,
    height: "auto",
    transition: {
      duration: 0.1,
      ease: "linear",
    },
  },
  exit: {
    opacity: 0,
    height: 0,
    transition: {
      duration: 0,
    },
  },
};

export const sidebarMobileAnimation: AnimationProps["variants"] = {
  hidden: {
    y: "100%",
    transition: {
      duration: 0.6,
      ease: [0.8, -0.01, 0.03, 0.98],
    },
  },
  visible: {
    y: 0,
    transition: {
      duration: 0.6,
      ease: [0.8, -0.01, 0.03, 0.98],
    },
  },
};

export const sidebarItemLabelAnimation: AnimationProps["variants"] = {
  hidden: {
    opacity: 0,
    width: 0,
  },
  visible: {
    opacity: 1,
    width: "auto",
  },
};

export const sidebarFloatingElementAnimation: AnimationProps["variants"] = {
  hidden: {
    opacity: 0,
    transition: {
      duration: 0.1,
      ease: "linear",
    },
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.1,
      ease: "linear",
    },
  },
};
