import { useMutation } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { MessageCreateRequest } from "api/types";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { useTranslation } from "react-i18next";

const useAddMessage = () => {
  const api = useApi();
  const { t } = useTranslation();
  const showFlashToast = useFlashToast();

  return useMutation({
    mutationFn: ({ payload }: { payload: MessageCreateRequest }) => api.postMessagesV1(payload).then((x) => x.data),
    onError() {
      showFlashToast({ type: "error", title: t("page.message-feed.create.notifications.error") });
    },
  });
};

export const communityFeedMutations = {
  useAddMessage,
};
