import { CHAR_CODES, Icon54 } from "components/Icon54/Icon54";
import { twJoin } from "tailwind-merge";

export interface IconPickerProps {
  onChange: (charCode: number) => void;
  selectedCharCode: number | null;
}

export function IconPicker({ onChange, selectedCharCode }: IconPickerProps): React.ReactNode {
  return (
    <div
      className="grid w-full justify-center gap-2"
      style={{
        gridTemplateColumns: `repeat(auto-fill, 40px)`,
        gridTemplateRows: `repeat(auto-fill, 40px)`,
      }}
    >
      {CHAR_CODES.map((charCode) => {
        const isSelected = selectedCharCode === charCode;

        return (
          <button
            key={charCode}
            className={twJoin(
              "flex size-10 items-center justify-center rounded-lg border hocus:border-aop-basic-blue-500 hocus:outline-none",
              isSelected ? "border-aop-basic-blue-500 bg-blue-100" : "border-grey-300",
            )}
            data-testid="icon-char-action-select"
            onClick={() => {
              onChange(charCode);
            }}
          >
            <Icon54 className="w-8 text-[2rem]" charCode={charCode} disableUserSelect />
          </button>
        );
      })}
    </div>
  );
}
