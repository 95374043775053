import type React from "react";

import { buttonVariants } from "./Button";

interface FileUploadButtonProps {
  children: React.ReactNode;
  disabled?: boolean;
  id?: string;
  accept?: React.InputHTMLAttributes<HTMLInputElement>["accept"];
  onChange?: React.InputHTMLAttributes<HTMLInputElement>["onChange"];
}

export function FileUploadButton({ children, id, disabled, accept, onChange }: FileUploadButtonProps): React.ReactNode {
  return (
    <>
      <label
        className={buttonVariants({
          styling: "primary",
        })}
        htmlFor={id}
        {...{ disabled }}
      >
        {children}
      </label>
      <input data-testid="upload-input" className="hidden" type="file" {...{ id, accept, onChange }} />
    </>
  );
}
