import type { ImageDto, UserDto } from "api/types";
import iconEdit05 from "assets/icons/edit-05.svg";
import iconSend03 from "assets/icons/send-03.svg";
import iconX from "assets/icons/x.svg";
import { Button } from "components/Button/Button";
import { IconButton } from "components/Button/IconButton";
import type { FormDocument } from "components/DocumentInput/useDocumentFile";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { Gallery } from "components/Gallery/Gallery";
import { Icon } from "components/Icon/Icon";
import { LoadingIcon } from "components/Icons/Icons";
import type { FormImage } from "components/ImageInput/useImageInput";
import { Pdf } from "components/Pdf/Pdf";
import { ScalingTextArea } from "components/ScalingTextArea/ScalingTextArea";
import { Tooltip } from "components/Tooltip/Tooltip";
import { UserAvatar } from "components/UserAvatar/UserAvatar";
import type { FormVideo } from "components/VideoInput/useVideoInput";
import { VideoPreview } from "components/VideoPreview/VideoPreview";
import { isTouchDevice } from "helpers/device";
import {
  MAXIMUM_DOCUMENT_FILE_SIZE_IN_MEGA_BYTES,
  MAXIMUM_IMAGE_FILE_SIZE_IN_MEGA_BYTES,
  MAXIMUM_VIDEO_FILE_SIZE_IN_MEGA_BYTES,
  megaBytesToBytes,
} from "helpers/file-size";
import { twResolve } from "helpers/tw-resolve";
import { useBool } from "hooks/useBool";
import { useCombinedRefs } from "hooks/useCombinedRef";
import type React from "react";
import type { ComponentProps } from "react";
import { forwardRef, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { twJoin } from "tailwind-merge";

import { CommentFieldActions } from "./CommentFieldActions";

export type BaseCommentFieldProps = {
  value: string;
  tooltips?: {
    send?: string | undefined;
    uploadImage?: string | undefined;
    uploadVideo?: string | undefined;
    uploadDocument?: string | undefined;
  };
  isEdit?: boolean;
  isNote?: boolean;
  disabled?: boolean;
  allowedAttachments?: ("image" | "video" | "document")[];
  images?: FormImage[];
  videos?: FormVideo[];
  documents?: FormDocument[];
  onSubmit: () => Promise<void>;
  onChange: (value: string) => void;
  onAddImages?: (images: FileList) => void;
  onRemoveImage?: (image: FormImage) => void;
  onAddVideo?: (video: FileList) => void;
  onRemoveVideo?: (video: FormVideo) => void;
  onAddDocument?: (document: FileList) => void;
  onRemoveDocument?: (document: FormDocument) => void;
  onCancel?: () => void;
} & Pick<React.InputHTMLAttributes<HTMLInputElement>, "autoFocus" | "placeholder">;

export interface CommentFieldWithAvatarProps extends BaseCommentFieldProps {
  user: UserDto;
  isReply?: boolean;
  actionComponent?: React.ReactNode;
}

export const CommentFieldWithAvatar = forwardRef<HTMLTextAreaElement, CommentFieldWithAvatarProps>(
  function CommentFieldWithAvatar(props, ref): React.ReactNode {
    const [isSubmitting, setSubmitting] = useBool();
    const showFlashToast = useFlashToast();
    const { t } = useTranslation();

    async function onSubmit() {
      try {
        setSubmitting.setTrue();
        await props.onSubmit();
      } finally {
        setSubmitting.setFalse();
      }
    }

    function onAddDocument(documents: FileList) {
      if (documents.length === 0) return;

      const maxFileSize = megaBytesToBytes(MAXIMUM_DOCUMENT_FILE_SIZE_IN_MEGA_BYTES);
      if (Array.from(documents).some((document) => document.size > maxFileSize)) {
        showFlashToast({
          title: t("components.form.error.file-too-big", {
            sizeInMegaBytes: maxFileSize,
          }),
          type: "error",
        });
      } else {
        props.onAddDocument?.(documents);
      }
    }

    function onAddVideo(videos: FileList) {
      if (videos.length === 0) return;

      const maxFileSize = megaBytesToBytes(MAXIMUM_VIDEO_FILE_SIZE_IN_MEGA_BYTES);
      if (Array.from(videos).some((video) => video.size > maxFileSize)) {
        showFlashToast({
          title: t("components.form.error.file-too-big", {
            sizeInMegaBytes: maxFileSize,
          }),
          type: "error",
        });
      } else {
        props.onAddVideo?.(videos);
      }
    }

    function onAddImages(images: FileList) {
      if (images.length === 0) return;

      const maxFileSize = megaBytesToBytes(MAXIMUM_IMAGE_FILE_SIZE_IN_MEGA_BYTES);
      if (Array.from(images).some((image) => image.size > maxFileSize)) {
        showFlashToast({
          title: t("components.form.error.file-too-big", {
            sizeInMegaBytes: maxFileSize,
          }),
          type: "error",
        });
      } else {
        props.onAddImages?.(images);
      }
    }

    const isContentAvailable = !!props.value && props.value.length > 0;
    const isImageAttachmentAvailable = !!props.images && props.images.length > 0;
    const isDocumentAttachmentAvailable = !!props.documents && props.documents.length > 0;
    const isVideoAttachmentAvailable = !!props.videos && props.videos.length > 0;
    const isAttachment = isImageAttachmentAvailable || isDocumentAttachmentAvailable || isVideoAttachmentAvailable;
    const canSendComment = isContentAvailable || isAttachment;

    return (
      <CommentFieldWrapper>
        <CommentFieldRow>
          <div className="flex w-full items-start gap-2">
            <div className={twJoin("flex items-center", props.actionComponent ? "h-10" : "h-[52px]")}>
              <CommentFieldAvatar avatar={props.user.avatar} isDeleted={!!props.user.deletedAt} />
            </div>
            <div className="flex w-full flex-col items-center gap-2">
              {props.actionComponent && (
                <div className="flex w-full items-center justify-start gap-2">{props.actionComponent}</div>
              )}
              <CommentFieldInputBox isSubmitting={isSubmitting} isNote={props.isNote} disabled={props.disabled}>
                <CommentFieldInput
                  ref={ref}
                  autoFocus={props.autoFocus}
                  value={props.value}
                  isEdit={props.isEdit}
                  isSubmitting={isSubmitting}
                  onChange={props.onChange}
                  isNote={props.isNote}
                  placeholder={props.placeholder}
                  disabled={props.disabled}
                />
                <div
                  className={twResolve(
                    "flex h-10 items-center self-end bg-transparent text-grey-500 xl:self-start",
                    props.isNote && "bg-yellow-100",
                    isSubmitting && "pointer-events-none text-grey-300",
                    props.isNote && isSubmitting && "bg-yellow-100/30",
                  )}
                >
                  {props.allowedAttachments?.includes("image") && !!onAddImages && (
                    <CommentFieldActions.AddImage
                      isSubmitting={isSubmitting}
                      disabled={
                        props.disabled ||
                        (props.documents && props.documents.length > 0) ||
                        (props.videos && props.videos.length > 0)
                      }
                      tooltip={props.tooltips?.uploadImage}
                      {...{ onAddImages }}
                    />
                  )}
                  {props.allowedAttachments?.includes("video") && !!onAddVideo && (
                    <CommentFieldActions.AddVideo
                      isSubmitting={isSubmitting}
                      disabled={
                        props.disabled ||
                        (props.documents && props.documents.length > 0) ||
                        (props.images && props.images.length > 0)
                      }
                      tooltip={props.tooltips?.uploadVideo}
                      {...{ onAddVideo }}
                    />
                  )}
                  {props.allowedAttachments?.includes("document") && !!onAddDocument && (
                    <CommentFieldActions.AddDocument
                      isSubmitting={isSubmitting}
                      disabled={
                        props.disabled ||
                        (props.images && props.images.length > 0) ||
                        (props.videos && props.videos.length > 0)
                      }
                      tooltip={props.tooltips?.uploadDocument}
                      {...{ onAddDocument }}
                    />
                  )}
                  {!props.isReply && (
                    <div className="flex flex-row items-center gap-2">
                      <CommentFieldSend
                        isSubmitting={isSubmitting}
                        isEdit={props.isEdit}
                        canSend={canSendComment}
                        sendTooltip={props.tooltips?.send}
                        {...{ onSubmit }}
                      />
                      {Boolean(props.onCancel) && (
                        <CommentFieldInlineCancel size="sm" onCancel={props.onCancel} isCancellable={props.isEdit} />
                      )}
                    </div>
                  )}
                </div>
              </CommentFieldInputBox>
            </div>
          </div>

          {props.isReply && (
            <>
              <CommentFieldSend
                isReply
                isSubmitting={isSubmitting}
                isEdit={props.isEdit}
                canSend={canSendComment}
                sendTooltip={props.tooltips?.send}
                {...{ onSubmit }}
              />
              {Boolean(props.onCancel) && (
                <CommentFieldInlineCancel onCancel={props.onCancel} isCancellable={props.isEdit} />
              )}
            </>
          )}
        </CommentFieldRow>
        {isAttachment && (
          <div className="pl-12">
            {props.images && props.images.length > 0 && (
              <CommentFieldGallery images={props.images} onRemoveImage={props.onRemoveImage} />
            )}
            {props.videos && props.videos.length > 0 && (
              <CommentFieldVideoPreview video={props.videos[0]} onRemoveVideo={props.onRemoveVideo} />
            )}
            {props.documents && props.documents.length > 0 && (
              <CommentFieldDocumentPreview documents={props.documents} onRemoveDocument={props.onRemoveDocument} />
            )}
          </div>
        )}
      </CommentFieldWrapper>
    );
  },
);

function CommentFieldWrapper({ children }: React.PropsWithChildren<{}>) {
  return <div className="flex flex-1 flex-col gap-4">{children}</div>;
}

function CommentFieldRow({ children }: React.PropsWithChildren<{}>) {
  return <div className="relative flex flex-col items-end gap-2 md:flex-row md:items-center">{children}</div>;
}

function CommentFieldInputBox({
  isSubmitting,
  isNote,
  disabled,
  children,
}: React.PropsWithChildren<{
  isSubmitting?: boolean;
  isNote?: boolean;
  disabled?: boolean;
}>) {
  return (
    <div
      className={twResolve(
        "flex w-full flex-1 flex-col items-center gap-2 rounded-lg border border-grey-300 bg-white p-1 focus-within:border-grey-900 @4xl:flex-row @4xl:items-start",
        isSubmitting && "pointer-events-none opacity-30",
        // eslint-disable-next-line no-nested-ternary
        disabled ? "bg-grey-100" : isNote ? "bg-yellow-100" : "",
      )}
    >
      {children}
    </div>
  );
}

const CommentFieldInput = forwardRef<
  HTMLTextAreaElement,
  {
    autoFocus?: boolean;
    disabled?: boolean;
    value?: string;
    placeholder?: string;
    onChange: (value: string) => void;
    isEdit?: boolean;
    isNote?: boolean;
    isSubmitting?: boolean;
  }
>(function CommentFieldInput({ autoFocus, value, onChange, placeholder, isEdit, isNote, isSubmitting, disabled }, ref) {
  const { t } = useTranslation();
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
  const combinedRef = useCombinedRefs(textAreaRef, ref);

  useEffect(() => {
    if (isEdit && textAreaRef.current) {
      textAreaRef.current.focus();
      textAreaRef.current.setSelectionRange(textAreaRef.current.value.length, textAreaRef.current.value.length);
      textAreaRef.current.scrollTop = textAreaRef.current.scrollHeight;
    }
  }, [isEdit, textAreaRef]);

  return (
    <ScalingTextArea
      className={twJoin(
        "block min-h-10 w-full resize-none bg-transparent py-2 pl-1 placeholder:text-grey-500 focus:outline-none",
        isSubmitting && "pointer-events-none opacity-30",
        isNote && "bg-yellow-100",
      )}
      disabled={disabled}
      autoFocus={autoFocus && !isTouchDevice()}
      data-testid="comment-field"
      name="content"
      ref={combinedRef}
      value={value || ""}
      onChange={(event) => onChange(event.target.value)}
      placeholder={
        // eslint-disable-next-line no-nested-ternary
        placeholder
          ? placeholder
          : isNote
            ? t("component.comment-field.create-new-note")
            : t("component.comment-field.create-new-reaction")
      }
    />
  );
});

function CommentFieldGallery({
  images,
  onRemoveImage,
}: {
  images: FormImage[];
  onRemoveImage?: (image: FormImage) => void;
}) {
  return (
    <div data-testid="comment-attachment-image-preview">
      <Gallery images={images} onRemove={onRemoveImage} />
    </div>
  );
}

function CommentFieldVideoPreview({
  video,
  onRemoveVideo,
}: {
  video: FormVideo;
  onRemoveVideo?: (video: FormVideo) => void;
}) {
  return (
    <div data-testid="comment-attachment-video-preview">
      <VideoPreview video={video} onDelete={() => onRemoveVideo?.(video)} />
    </div>
  );
}

function CommentFieldDocumentPreview({
  documents,
  onRemoveDocument,
}: {
  documents: FormDocument[];
  onRemoveDocument?: (document: FormDocument) => void;
}) {
  const [fileUrl, setFileUrl] = useState<string | undefined>(undefined);

  const document = documents[0];

  useEffect(() => {
    if (!document) {
      setFileUrl(undefined);

      return;
    }

    let currFileUrl;
    if ("file" in document) {
      currFileUrl = URL.createObjectURL(document.file);
    } else {
      currFileUrl = document.url;
    }

    setFileUrl(currFileUrl);

    return () => {
      if (currFileUrl && currFileUrl.includes("blob")) {
        URL.revokeObjectURL(currFileUrl);
      }
    };
  }, [document]);

  const fileName = "file" in document ? document.file.name : document.fileName;
  let fileSize: number | undefined = undefined;
  if ("file" in document) {
    fileSize = document.file.size;
  } else if ("fileSize" in document) {
    fileSize = document.fileSize as number;
  }

  return (
    <div data-testid="comment-attachment-document-preview">
      <Pdf
        onClick={() => window.open(fileUrl, "_blank")}
        onDelete={() => onRemoveDocument && onRemoveDocument(document)}
        {...{ fileName, fileSize }}
      />
    </div>
  );
}

function CommentFieldAvatar({ avatar, isDeleted }: { avatar?: ImageDto; isDeleted?: boolean }) {
  return (
    <div className="size-10 shrink-0">
      <UserAvatar img={avatar} isUserDeleted={isDeleted} />
    </div>
  );
}

function CommentFieldSend({
  sendTooltip,
  isSubmitting,
  isEdit,
  canSend,
  onSubmit,
  isReply,
}: {
  sendTooltip?: string;
  isSubmitting?: boolean;
  isEdit?: boolean;
  canSend?: boolean;
  onSubmit: () => Promise<void>;
  isReply?: boolean;
}) {
  const { t } = useTranslation();

  const onClickSend = () => {
    void onSubmit();
  };

  return isReply ? (
    <Button
      title={sendTooltip ? sendTooltip : t("component.comment-field.send-comment")}
      className="min-w-fit"
      data-testid="send-comment"
      styling="primary"
      disabled={isSubmitting || !canSend}
      onClick={onSubmit}
      isLoading={isSubmitting}
    >
      {isEdit ? t("component.community-post.comments.edit") : t("component.community-post.comments.reply")}
    </Button>
  ) : (
    <Tooltip tooltip={sendTooltip ? sendTooltip : t("component.comment-field.send-comment")}>
      <button
        className={twResolve(
          "flex size-7 cursor-pointer items-center justify-center rounded-full transition-colors hover:bg-blue-100 focus:bg-blue-200 focus:outline-none disabled:pointer-events-none",
          canSend ? "text-aop-basic-blue-500" : "opacity-30",
          isSubmitting && "pointer-events-none",
        )}
        data-testid="send-comment"
        disabled={isSubmitting || !canSend}
        type="button"
        onClick={onClickSend}
      >
        {
          // eslint-disable-next-line no-nested-ternary
          isSubmitting ? (
            <LoadingIcon className="w-5" />
          ) : isEdit ? (
            <Icon name={iconEdit05} size={20} />
          ) : (
            <Icon name={iconSend03} size={20} />
          )
        }
      </button>
    </Tooltip>
  );
}

function CommentFieldInlineCancel({
  size,
  onCancel,
  isCancellable,
}: {
  size?: ComponentProps<typeof IconButton>["size"];
  onCancel?: () => void;
  isCancellable?: boolean;
}) {
  const { t } = useTranslation();

  if (!isCancellable || !onCancel) {
    return null;
  }

  return (
    <IconButton onClick={onCancel} size={size} styling="secondary" title={t("common.action.cancel")}>
      <Icon name={iconX} size={20} />
    </IconButton>
  );
}
