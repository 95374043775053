import iconEye from "assets/icons/eye.svg";
import iconEyeOff from "assets/icons/eye-off.svg";
import iconX from "assets/icons/x.svg";
import { Anchor } from "components/Anchor/Anchor";
import { Button } from "components/Button/Button";
import { IconButton } from "components/Button/IconButton";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { Form } from "components/Form/Form";
import { FormField } from "components/Form/FormField";
import { FormInput } from "components/Form/FormInput";
import { Icon } from "components/Icon/Icon";
import { removeFromHeaders } from "helpers/headers";
import { isHttpError } from "helpers/Network/errors";
import { createRequiredStringRule } from "helpers/rules";
import { useBool } from "hooks/useBool";
import { useQueryParam } from "hooks/useQueryParam";
import { useCallback, useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { routes } from "routes";
import EmailPassword from "supertokens-web-js/recipe/emailpassword";
import { submitNewPassword } from "supertokens-web-js/recipe/emailpassword";

import { AuthenticationPage } from "../components/AuthenticationPage";
import { PasswordHelper } from "../components/PasswordHelper";

enum PageState {
  Initial,
  Failed,
  Success,
  SuccessAndLogin,
}

export function ResetPasswordPage(): React.ReactNode {
  const showFlashToast = useFlashToast();
  const { t } = useTranslation();

  const [globalError, setGlobalError] = useState<string>();
  const [isSubmitting, isSubmittingHandlers] = useBool(false);
  const [state, setState] = useState(PageState.Initial);

  const [showPassword, showPasswordHandlers] = useBool();

  const [token] = useQueryParam("token");
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      void navigate("/");
    }
  }, [navigate, token]);

  const onLogin = useCallback(async (password: string) => {
    try {
      const email = new URLSearchParams(window.location.search).get("email");
      if (!email) {
        return false;
      }

      const response = await EmailPassword.signIn({
        formFields: [
          {
            id: "email",
            value: email,
          },
          {
            id: "password",
            value: password,
          },
        ],
        options: {
          preAPIHook: (x) => {
            return Promise.resolve({
              ...x,
              requestInit: removeFromHeaders(x.requestInit, "Authorization"),
            });
          },
        },
      });

      return response.status === "OK";
    } catch (error) {
      return false;
    }
  }, []);

  const onSubmit = useCallback(
    async (password: string) => {
      isSubmittingHandlers.setTrue();
      try {
        const response = await submitNewPassword({
          formFields: [
            {
              id: "password",
              value: password,
            },
          ],
        });

        if (response.status === "FIELD_ERROR") {
          // This should not happen, we have form validation
          console.error(response.formFields);
          showFlashToast({ type: "error", title: t("common.generic-error") });
        } else if (response.status === "RESET_PASSWORD_INVALID_TOKEN_ERROR") {
          setState(PageState.Failed);
        } else {
          const loggedIn = await onLogin(password);
          if (loggedIn) {
            setState(PageState.SuccessAndLogin);
          } else {
            setState(PageState.Success);
          }
        }
      } catch (err: any) {
        if (err.isSuperTokensGeneralError === true) {
          setGlobalError(err.message);
        } else if (isHttpError(err)) {
          setGlobalError(t("page.login.errors.unknown"));
        } else {
          showFlashToast({ type: "error", title: t("common.generic-error") });
        }
      } finally {
        isSubmittingHandlers.setFalse();
      }
    },
    [isSubmittingHandlers, onLogin, showFlashToast, t],
  );

  const formMethods = useForm<FormValues>({
    defaultValues: {
      password: "",
    },
  });

  const password = useWatch({ control: formMethods.control, name: "password" });

  const submitCount = formMethods.formState.submitCount;

  return (
    <AuthenticationPage
      header={
        // eslint-disable-next-line no-nested-ternary
        state === PageState.Success || state === PageState.SuccessAndLogin
          ? t("page.reset-password.completed.title")
          : state === PageState.Failed
            ? t("page.reset-password.failed.title")
            : t("page.reset-password.title")
      }
      body={
        // eslint-disable-next-line no-nested-ternary
        state === PageState.Success
          ? t("page.reset-password.completed.description")
          : // eslint-disable-next-line no-nested-ternary
            state === PageState.SuccessAndLogin
            ? t("page.reset-password.completed-and-logged-in.description")
            : state === PageState.Failed
              ? t("page.reset-password.failed.description")
              : undefined
      }
    >
      {state === PageState.Initial && (
        <Form formMethods={formMethods} onSubmit={(data) => onSubmit(data.password)}>
          <div className="flex flex-col gap-8">
            {globalError && (
              <div className="relative flex items-center rounded bg-red-100 p-2 text-center text-caption text-red-600">
                <span className="flex-1 px-8">{globalError}</span>
                <span className="absolute right-0">
                  <IconButton
                    title={t("common.action.close")}
                    styling="ghostSecondary"
                    onClick={() => setGlobalError(undefined)}
                  >
                    <Icon name={iconX} size={16} />
                  </IconButton>
                </span>
              </div>
            )}
            <FormField label={t("page.login.form.password.label")}>
              <FormInput<FormValues>
                id="password"
                type={showPassword ? "text" : "password"}
                name="password"
                hideErrorText
                autoComplete="current-password"
                autoCapitalize="none"
                spellCheck="false"
                autoFocus
                aria-required
                rules={{
                  validate: {
                    required: createRequiredStringRule(t, "page.login.form.password.label"),
                    minCharacters(password) {
                      if (password.length < 8) {
                        return t("page.login.errors.password-minimum-chars", { chars: 8 });
                      }
                    },
                    containsLowercase(password) {
                      if (password.toUpperCase() === password) {
                        return t("page.login.errors.password-lowercase");
                      }
                    },
                    containsNumber(password) {
                      if (!/\d/.test(password)) {
                        return t("page.login.errors.password-number");
                      }
                    },
                  },
                }}
                postfix={
                  <IconButton
                    title={
                      showPassword
                        ? t("page.login.form.password.toggle-hidden")
                        : t("page.login.form.password.toggle-visible")
                    }
                    onClick={showPasswordHandlers.toggle}
                    styling="ghostSecondary"
                  >
                    <Icon name={showPassword ? iconEyeOff : iconEye} size={18} />
                  </IconButton>
                }
              />

              <PasswordHelper {...{ password, hasSubmittedPassword: submitCount > 0 }} />
            </FormField>
            <Button type="submit" className="w-full" isLoading={isSubmitting}>
              {t("page.reset-password.confirm")}
            </Button>
          </div>
          <div className="mt-4 flex justify-center text-caption">
            <Anchor to={routes.authentication.login()} isBold>
              {t("page.reset-password.cancel")}
            </Anchor>
          </div>
        </Form>
      )}
      {(state === PageState.Success || state === PageState.SuccessAndLogin || state === PageState.Failed) && (
        <div className="flex flex-col gap-4">
          <Button type="link" href={routes.authentication.login()} className="w-full" data-testid="redirect-home-btn">
            {t("page.reset-password.completed.go-home")}
          </Button>
        </div>
      )}
    </AuthenticationPage>
  );
}

interface FormValues {
  password: string;
}
