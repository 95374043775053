import { keepPreviousData, queryOptions } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";

export const useQuickReplyMessageQueries = () => {
  const api = useApi();

  return {
    details: (token: string) =>
      queryOptions({
        queryKey: ["quick-reply-message-detail", token],
        queryFn: () => api.getQuickReplyMessageDetailsV1(token).then(({ data }) => data),
        placeholderData: keepPreviousData,
      }),
  };
};
