import { useSlug } from "hooks/useSlug";
import { useForbiddenPopupContext } from "providers/ForbiddenPopupProvider";
import type { ReactNode } from "react";
import { Link } from "react-router";
import { routes } from "routes";

interface Props {
  user: { id: string; projectId: string; canViewProfile: boolean };
  children: ReactNode;
  "data-testid"?: string;
  isInline?: boolean;
}

export function UserNameLink({ user, children, isInline = true, ...props }: Props): React.ReactNode {
  const slug = useSlug();
  const forbiddenContext = useForbiddenPopupContext();

  if (user.canViewProfile) {
    return (
      <Link
        className={isInline ? undefined : "text-aop-basic-blue-500 hover:underline"}
        to={routes.users.details({ slug, id: user.id })}
        {...props}
      >
        {children}
      </Link>
    );
  } else {
    return (
      <button onClick={forbiddenContext.open} {...props}>
        {children}
      </button>
    );
  }
}
