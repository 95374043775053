import { twJoin } from "tailwind-merge";

import type { GroupIconType } from "./GroupIcons";
import { GROUP_ICONS, GroupIcon } from "./GroupIcons";

export interface GroupIconPickerProps {
  onChange: (icon: GroupIconType) => void;
  selectedIcon: GroupIconType | null;
}

export function GroupIconPicker({ onChange, selectedIcon }: GroupIconPickerProps): React.ReactNode {
  return (
    <div
      className="grid w-full justify-center gap-2"
      style={{
        gridTemplateColumns: `repeat(auto-fill, 40px)`,
        gridTemplateRows: `repeat(auto-fill, 40px)`,
      }}
    >
      {GROUP_ICONS.map((icon) => {
        const isSelected = selectedIcon === icon;

        return (
          <button
            key={icon}
            className={twJoin(
              "flex size-10 items-center justify-center rounded-lg border hocus:border-aop-basic-blue-500 hocus:outline-none",
              isSelected ? "border-aop-basic-blue-500 bg-blue-100" : "border-grey-300",
            )}
            data-testid="icon-action-select"
            onClick={() => {
              onChange(icon);
            }}
          >
            <GroupIcon size={32} icon={icon} />
          </button>
        );
      })}
    </div>
  );
}
