import chevronRightIcon from "assets/icons/chevron-right.svg";
import { Icon } from "components/Icon/Icon";
import { Overlay } from "components/Overlay/Overlay";
import { twResolve } from "helpers/tw-resolve";
import { useBool } from "hooks/useBool";
import { useScreenIsBiggerThan } from "hooks/useScreenIsBiggerThan";
import { useSidebarManager } from "hooks/useSidebar";
import { AnimatePresence, motion } from "motion/react";
import { useEffect, useRef } from "react";
import { twJoin } from "tailwind-merge";

import { sidebarMobileAnimation } from "./animations";
import { SidebarContext } from "./SidebarContext";
import { SidebarGroupedItem } from "./SidebarGroupedItem";
import { SidebarItem } from "./SidebarItem";
import { type SidebarBodyRootProps, type SidebarItemType } from "./types";

function SidebarRoot({
  children,
  isOpened,
  isCollapsed = false,
  onToggleOpen,
  onToggleCollapse,
  isFaded,
}: SidebarBodyRootProps): React.ReactNode {
  const [isBodyScrolled, bodyScrolledHandlers] = useBool(false);
  const isDesktop = useScreenIsBiggerThan("md");

  return (
    <SidebarContext.Provider
      value={{
        isFaded,
        isCollapsed,
        isBodyScrolled,
        onToggleBodyScrolled: bodyScrolledHandlers.set,
        onToggleCollapse,
      }}
    >
      <AnimatePresence>
        {(isDesktop || (!isDesktop && isOpened)) && (
          <>
            <motion.div
              className={twJoin(
                "group/sidebar fixed bottom-0 z-40 flex max-h-[70svh] w-full flex-col overflow-hidden rounded-t-lg transition-[width] duration-300 md:relative md:h-screen md:max-h-full md:overflow-visible md:rounded-none",
                isFaded ? "border-r border-grey-100 bg-[#fafafa]" : "bg-white",
                isCollapsed ? "md:min-w-[56px]" : "md:w-72 lg:w-80",
              )}
              variants={sidebarMobileAnimation}
              initial={isDesktop ? "visible" : "hidden"}
              animate="visible"
              exit="hidden"
              key="sidebar"
            >
              {children}
              {/* Collapse trigger */}
              {isDesktop && !isFaded && (
                <div
                  onClick={() => onToggleCollapse(!isCollapsed)}
                  className="group/sidebar-collapse-trigger absolute -right-3 top-0 z-10 h-full cursor-ew-resize px-2"
                >
                  {isDesktop && !isFaded && (
                    <button
                      className={twJoin(
                        "absolute left-1/2 top-6 z-20 -translate-x-1/2 -translate-y-1/2 cursor-pointer rounded-full bg-grey-100 p-1 text-grey-500 outline-none transition duration-200 focus-visible:bg-grey-300 focus-visible:text-grey-700 group-hover/sidebar-collapse-trigger:bg-grey-300 group-hover/sidebar-collapse-trigger:text-grey-700 hocus:bg-grey-300 hocus:text-grey-700",
                      )}
                      aria-label={isCollapsed ? "Expand sidebar" : "Collapse sidebar"}
                      type="button"
                    >
                      <Icon
                        className={twResolve("block transition-transform", !isCollapsed && "rotate-180")}
                        name={chevronRightIcon}
                        size={16}
                      />
                    </button>
                  )}
                  <span className="block h-full w-px bg-grey-300 group-hover:bg-grey-400" />
                </div>
              )}
            </motion.div>
            {!isDesktop && <Overlay className="z-30" onClick={() => onToggleOpen(false)} withPortal />}
          </>
        )}
      </AnimatePresence>
    </SidebarContext.Provider>
  );
}

interface SidebarHeaderProps {
  children: React.ReactNode;
}

function SidebarHeader({ children }: SidebarHeaderProps): React.ReactNode {
  const { isBodyScrolled } = useSidebarManager();

  return (
    <div
      style={{ backgroundColor: "inherit" }}
      className={twJoin("sticky top-0 z-10 w-full bg-current py-8 pb-2", isBodyScrolled && "shadow-sm")}
    >
      {children}
    </div>
  );
}

interface SidebarBodyProps {
  children?: React.ReactNode;
  items: SidebarItemType[];
  isFirstItemImportant?: boolean;
}

function SidebarBody({ children, items, isFirstItemImportant }: SidebarBodyProps): React.ReactNode {
  const { isCollapsed, onToggleBodyScrolled } = useSidebarManager();
  const refContainer = useRef<HTMLDivElement>(null);

  // Attach listener to refContainer for scrolltop > 0
  useEffect(() => {
    const elemContainer = refContainer.current;

    if (!elemContainer) return;

    const handleScroll = () => {
      if (elemContainer.scrollTop > 0) onToggleBodyScrolled(true);
      else onToggleBodyScrolled(false);
    };

    elemContainer.addEventListener("scroll", handleScroll);

    return () => elemContainer.removeEventListener("scroll", handleScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={twJoin(
        "flex size-full flex-1 flex-col items-start overflow-y-auto overflow-x-hidden py-2 pb-8",
        isCollapsed ? "px-2 md:px-2" : "px-4 md:px-6",
      )}
      ref={refContainer}
    >
      <ul className={twJoin("flex w-full flex-col gap-3 md:gap-4")}>
        {items.map((item, index) => {
          if (item.type === "group") return <SidebarGroupedItem {...item} key={item.key} />;
          else return <SidebarItem isBig={isFirstItemImportant && index === 0} {...item} key={item.key} />;
        })}
      </ul>
      {children}
    </div>
  );
}

interface SidebarFooterProps {
  children: React.ReactNode;
}

function SidebarFooter({ children }: SidebarFooterProps): React.ReactNode {
  const { isCollapsed } = useSidebarManager();

  return (
    <div
      className={twJoin(
        "top-0 mt-auto flex w-full justify-start overflow-x-clip border-t border-grey-100 bg-white py-4",
        isCollapsed ? "px-2" : "px-4 md:px-8",
      )}
    >
      {children}
    </div>
  );
}

export const Sidebar = {
  Root: SidebarRoot,
  Body: SidebarBody,
  Footer: SidebarFooter,
  Header: SidebarHeader,
};
