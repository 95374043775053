import type { Row, TableState } from "@tanstack/react-table";
import { createColumnHelper, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import type {
  AppTicketDto,
  CreatableTicketCategoryDto,
  SelfDto,
  TicketFiltersDto,
  TicketTabsStatsDto,
} from "api/types";
import iconBarChart02 from "assets/icons/bar-chart-02.svg";
import iconFilterFunnel01 from "assets/icons/filter-funnel-01.svg";
import iconMessageCircle01 from "assets/icons/message-circle-01.svg";
import iconStar01 from "assets/icons/star-01.svg";
import iconUsers01 from "assets/icons/users-01.svg";
import iconX from "assets/icons/x.svg";
import { Anchor } from "components/Anchor/Anchor";
import { Button } from "components/Button/Button";
import { CheckboxMultiSelect } from "components/CheckboxMultiSelect/CheckboxMultiSelect";
import type { Tab } from "components/ContentTabs/ContentTabs";
import { ContentTabs } from "components/ContentTabs/ContentTabs";
import { formatDate } from "components/FormattedDate/FormattedDate";
import { Icon } from "components/Icon/Icon";
import { LoadingIcon } from "components/Icons/Icons";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { SearchInput } from "components/SearchInput/SearchInput";
import { Select } from "components/Select/Select";
import { Table } from "components/Table/Table";
import { UserAvatar } from "components/UserAvatar/UserAvatar";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useBool } from "hooks/useBool";
import { useOnIntersection } from "hooks/useOnIntersection";
import { usePermission } from "hooks/usePermission";
import { useScreenIsBiggerThan } from "hooks/useScreenIsBiggerThan";
import { useSlug } from "hooks/useSlug";
import { debounce } from "lodash-es";
import type { FormValues } from "modules/tickets/components/ResidentCreateTicketModal";
import { ResidentCreateTicketModal } from "modules/tickets/components/ResidentCreateTicketModal";
import type {
  RepairRequestFilterTypes,
  ResidentTicketFilterParams,
  ResidentTicketFilterTypes,
  ResidentTicketTabs,
} from "modules/tickets/constants";
import {
  reporterTypeValues,
  RESIDENT_CLOSED_TICKET_TAB,
  RESIDENT_OPEN_TICKET_TAB,
  residentTicketTypeValues,
} from "modules/tickets/constants";
import { usePostHog } from "posthog-js/react";
import type { ChangeEvent, Ref } from "react";
import { useCallback, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router";
import { routes } from "routes";
import { twJoin } from "tailwind-merge";

import type { OnUpdateParams, ResidentTicketsFilters } from "./Loader";

export interface LayoutProps {
  tab: ResidentTicketTabs;
  queryParams: ResidentTicketsFilters;
  sessionUser: SelfDto;
  onUpdateParams: OnUpdateParams;
  totalTickets?: number;
  totalTicketsInTab?: number;
  tabsStats?: TicketTabsStatsDto;
  tickets: AppTicketDto[];
  isLoadingTickets: boolean;
  hasMoreTickets: boolean | undefined;
  loadMoreTickets: () => void;
  isLoadingMoreTickets: boolean;
  ticketFilterValues: TicketFiltersDto | undefined;
  clearFilters: () => void;
  filterCount: number;
  ticketCategories: CreatableTicketCategoryDto[];
  onTicketCreate: (values: FormValues) => Promise<void>;
  isCreatingTicket: boolean;
  searchInputRef: Ref<HTMLInputElement> | undefined;
}

export function Layout({
  sessionUser,
  tab,
  queryParams,
  onUpdateParams,
  totalTickets,
  totalTicketsInTab,
  tabsStats,
  tickets,
  isLoadingTickets,
  hasMoreTickets,
  loadMoreTickets,
  isLoadingMoreTickets,
  ticketFilterValues,
  clearFilters,
  filterCount,
  ticketCategories,
  onTicketCreate,
  isCreatingTicket,
  searchInputRef,
}: LayoutProps): React.ReactNode {
  const { t } = useTranslation();
  const [isFilterMenuOpen, filterMenuHandler] = useBool(false);
  const [isCreateModalOpened, createModalHandler] = useBool(false);
  const hasPermission = usePermission();
  const posthog = usePostHog();

  const onUpdateFilters = useCallback(
    (filter: ResidentTicketFilterParams, value: ResidentTicketFilterTypes) => {
      onUpdateParams(filter, value);
    },
    [onUpdateParams],
  );

  const onTabSelection = useCallback(
    (val: ResidentTicketTabs) => {
      onUpdateParams("StatusType", val);
    },
    [onUpdateParams],
  );

  const onSearch = useMemo(
    () => debounce((e: ChangeEvent<HTMLInputElement>) => onUpdateFilters("Search", e.target.value), 500),
    [onUpdateFilters],
  );

  const tabs: Tab<ResidentTicketTabs>[] = [
    {
      id: RESIDENT_OPEN_TICKET_TAB,
      name: tabsStats == null ? t("page.resident-tickets.tabs.open-loading") : t("page.resident-tickets.tabs.open"),
      count: tabsStats?.openTickets,
    },
    {
      id: RESIDENT_CLOSED_TICKET_TAB,
      name: tabsStats == null ? t("page.resident-tickets.tabs.closed-loading") : t("page.resident-tickets.tabs.closed"),
      count: tabsStats?.closedTickets,
    },
  ];

  const isMd = useScreenIsBiggerThan("md");

  const hasFilters = filterCount > 0 || !!queryParams.Search;

  return (
    <DocumentPaper
      title={t("page.resident-tickets.title")}
      subTitle={t("page.resident-tickets.subtitle")}
      theme="minimal"
      actions={
        hasPermission((x) => x.canCreateTicket) && (
          <Button
            data-testid="create-ticket-button"
            styling="primary"
            onClick={() => {
              posthog?.capture("clicked_create_ticket");
              createModalHandler.setTrue();
            }}
          >
            {t("page.resident-tickets.create-btn")}
          </Button>
        )
      }
      header={
        <div className="flex flex-col gap-2">
          <div className="flex w-full grow flex-wrap items-center gap-4 lg:flex-nowrap">
            <div className="w-full max-w-[396px]">
              <SearchInput
                placeholder={t("page.resident-tickets.search.placeholder")}
                defaultValue={queryParams.Search}
                onChange={onSearch}
                ref={searchInputRef}
              />
            </div>
            <div className="flex w-full grow flex-wrap items-center gap-4 lg:flex-nowrap">
              <Button
                styling="secondary"
                onClick={filterMenuHandler.toggle}
                icon={<Icon name={iconFilterFunnel01} size={16} />}
              >
                <span className="flex items-center gap-2">
                  {t("page.resident-tickets.filter-btn")}
                  {filterCount > 0 && (
                    <span className="size-5 rounded-full bg-aop-basic-blue-500 text-caption text-white">
                      {filterCount}
                    </span>
                  )}
                </span>
              </Button>
              {filterCount > 0 && (
                <Button styling="secondary" onClick={clearFilters} icon={<Icon name={iconX} size={16} />}>
                  {t("page.resident-tickets.filter-btn-clear")}
                </Button>
              )}
            </div>
          </div>
          {isFilterMenuOpen && (
            <TicketFilters
              activeFilters={queryParams}
              onUpdateFilters={onUpdateFilters}
              values={
                ticketFilterValues || {
                  statuses: [],
                  categories: [],
                  assignees: [],
                }
              }
              onClose={filterMenuHandler.setFalse}
            />
          )}
        </div>
      }
    >
      <ContentTabs<ResidentTicketTabs> onTabChange={onTabSelection} tabs={tabs} activeTabId={tab}>
        <div className="flex flex-wrap items-center justify-between gap-4 px-6 py-4">
          <span className="text-caption">
            {totalTickets != null && totalTicketsInTab != null ? (
              <Trans
                i18nKey="page.resident-tickets.table.results.label"
                values={{
                  count: totalTickets,
                  total: totalTicketsInTab,
                }}
                components={{
                  bold: <b />,
                }}
              />
            ) : null}
          </span>
          <div className="flex flex-wrap items-center gap-2 text-grey-700 xs:ml-auto">
            <span className="text-caption-bold">{t("page.resident-tickets.table.sort.last-activity")}</span>
            <Icon name={iconBarChart02} className="block rotate-90" size={16} />
          </div>
        </div>
        {isMd ? (
          <TicketsTable
            sessionUser={sessionUser}
            hasFilters={hasFilters}
            tickets={tickets}
            hasMoreTickets={hasMoreTickets}
            loadMoreTickets={loadMoreTickets}
            isLoadingTickets={isLoadingTickets}
            isLoadingMoreTickets={isLoadingMoreTickets}
            tab={tab}
          />
        ) : (
          <TicketsMobileView
            sessionUser={sessionUser}
            hasFilters={hasFilters}
            tickets={tickets}
            hasMoreTickets={hasMoreTickets}
            loadMoreTickets={loadMoreTickets}
            isLoadingTickets={isLoadingTickets}
            isLoadingMoreTickets={isLoadingMoreTickets}
            tab={tab}
          />
        )}
      </ContentTabs>
      <ResidentCreateTicketModal
        isOpened={isCreateModalOpened}
        onOpenChange={createModalHandler.set}
        onSend={onTicketCreate}
        categories={ticketCategories}
        isSending={isCreatingTicket}
      />
    </DocumentPaper>
  );
}

interface TicketsTableProps {
  sessionUser: LayoutProps["sessionUser"];
  tickets: AppTicketDto[];
  hasMoreTickets: boolean | undefined;
  loadMoreTickets: () => void;
  isLoadingTickets: boolean;
  isLoadingMoreTickets: boolean;
  tab: ResidentTicketTabs;
  hasFilters: boolean;
}

function TicketsTable({
  sessionUser,
  tickets,
  hasMoreTickets,
  loadMoreTickets,
  isLoadingTickets,
  isLoadingMoreTickets,
  hasFilters,
  tab,
}: TicketsTableProps): React.ReactNode {
  const slug = useSlug();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const posthog = usePostHog();

  const handleRowClick = useCallback(
    (row: Row<AppTicketDto>) => {
      posthog?.capture("clicked_ticket");
      void navigate(routes.tickets.details({ slug, id: row.original.id }));
    },
    [slug, posthog, navigate],
  );

  const ref = useOnIntersection({
    threshold: 0,
    onIntersect: useCallback(() => {
      if (!isLoadingMoreTickets && hasMoreTickets) {
        loadMoreTickets();
      }
    }, [isLoadingMoreTickets, hasMoreTickets, loadMoreTickets]),
  });

  const columns = useMemo(() => {
    const helper = createColumnHelper<AppTicketDto>();

    return [
      helper.accessor("visibility", {
        header: "",
        cell: (cell) =>
          cell.getValue() !== "private" ? (
            <div className="flex size-full items-center justify-center bg-aop-basic-blue-500 px-2 text-white">
              <Icon name={iconUsers01} size={16} />
            </div>
          ) : null,
        size: 32,
      }),
      helper.accessor("status", {
        header: t("page.resident-tickets.table.header.status"),
        cell: (cell) => (
          <div className="flex items-center gap-2">
            <div className={twJoin("size-2 rounded-full", cell.row.original.unread && "bg-red-500")} />
            <div
              className="max-w-[200px] truncate rounded-md px-2 py-1 text-center text-white"
              style={{
                textTransform: "uppercase",
                color: cell.getValue().color,
                backgroundColor: `${cell.getValue().color}1A`,
              }}
            >
              <span className="text-caption-bold">{cell.getValue().name}</span>
            </div>
          </div>
        ),
      }),
      helper.accessor("title", {
        header: t("page.resident-tickets.table.header.title"),
        cell: (cell) => (
          <div className="flex flex-col gap-0.5">
            <Anchor style="inherit" to={routes.tickets.details({ slug, id: cell.row.original.id })}>
              <span className="line-clamp-2 min-w-52 text-body-bold 2xl:min-w-80">{cell.getValue()}</span>
            </Anchor>
            <span className="text-overline text-grey-400">
              <span className="flex items-center gap-1">
                {formatDate(i18n, "datetime", cell.row.original.postedAt)}
              </span>
            </span>
          </div>
        ),
      }),
      helper.accessor("category", {
        header: t("page.resident-tickets.table.header.category"),
        cell: (cell) => <span className="text-body-bold">{cell.getValue().name}</span>,
      }),
      helper.accessor("user", {
        header: t("page.resident-tickets.table.header.reporter"),
        cell: (cell) => (
          <div className="flex items-center gap-2">
            <div className="size-8">
              <UserAvatar img={cell.getValue().avatar} isUserDeleted={!!cell.getValue().deletedAt} />
            </div>
            <div className="flex flex-col gap-0.5">
              <span className="truncate text-body-bold">{cell.getValue().fullName}</span>
            </div>
          </div>
        ),
      }),
      helper.accessor("numberOfComments", {
        header: t("page.resident-tickets.table.header.activity"),
        cell: (cell) => (
          <div className="flex items-center gap-2">
            <Icon name={iconMessageCircle01} />
            <span className="text-body-bold">{cell.getValue()}</span>
          </div>
        ),
      }),
      helper.accessor("rating", {
        header: t("page.resident-tickets.table.header.rating"),
        cell: (cell) =>
          cell.getValue() ? (
            <div className="flex w-fit items-center gap-1 rounded-md bg-yellow-100 px-2 py-1 text-center">
              <Icon name={iconStar01} className="fill-yellow-400 text-yellow-500" />
              <span className="text-body-bold">{cell.getValue()}</span>
            </div>
          ) : null,
      }),
      helper.accessor("id", {
        header: "",
        cell: (cell) =>
          cell.row.original.status.type === "closed" &&
          cell.row.original.author.id === sessionUser.id && (
            <div className="mr-2 inline-flex items-center gap-2 rounded-lg border border-yellow-500 bg-yellow-100 p-2">
              <Icon name={iconStar01} className="text-yellow-500" />
              <span className="whitespace-nowrap text-caption-bold">{t("page.resident-tickets.table.rate")}</span>
            </div>
          ),
      }),
    ];
  }, [i18n, sessionUser.id, slug, t]);

  const tableState = useMemo(
    () =>
      ({
        columnVisibility: {
          rating: tab === RESIDENT_CLOSED_TICKET_TAB,
        },
      }) satisfies Partial<TableState>,
    [tab],
  );

  const table = useReactTable({
    columns: columns,
    data: tickets,
    getCoreRowModel: getCoreRowModel(),
    state: tableState,
  });

  return (
    <div className="overflow-auto">
      {isLoadingTickets ? (
        <div className="p-4">
          <LoadingIcon className="inset-0 mx-auto my-4 w-6" />
        </div>
      ) : (
        <>
          {tickets.length > 0 ? (
            <Table table={table} onRowClick={handleRowClick} firstCellIsIndicator hideBorder />
          ) : (
            <span className="block p-6 pb-12 text-caption-bold">
              {hasFilters ? t("page.resident-tickets.table.no-results") : t("page.resident-tickets.table.empty")}
            </span>
          )}
          {hasMoreTickets && (
            <div className="h-16 p-4" ref={ref}>
              <LoadingIcon className="inset-0 mx-auto my-4 w-6" />
            </div>
          )}
        </>
      )}
    </div>
  );
}

function TicketsMobileView({
  sessionUser,
  hasFilters,
  hasMoreTickets,
  isLoadingMoreTickets,
  isLoadingTickets,
  loadMoreTickets,
  tab,
  tickets,
}: TicketsTableProps) {
  const slug = useSlug();
  const { t, i18n } = useTranslation();
  const posthog = usePostHog();

  const ref = useOnIntersection({
    threshold: 0,
    onIntersect: useCallback(() => {
      if (!isLoadingMoreTickets && hasMoreTickets) {
        loadMoreTickets();
      }
    }, [isLoadingMoreTickets, hasMoreTickets, loadMoreTickets]),
  });

  return (
    <div className="bg-aop-off-white pb-2">
      {isLoadingTickets ? (
        <div className="p-4">
          <LoadingIcon className="inset-0 mx-auto my-4 w-6" />
        </div>
      ) : (
        <>
          {tickets.length > 0 ? (
            <div className="flex flex-col gap-5 py-5">
              {tickets.map((ticket) => (
                <Link
                  className="flex flex-col gap-4 rounded-lg bg-white p-4 shadow-md hover:shadow-lg"
                  key={ticket.id}
                  to={routes.tickets.details({ slug, id: ticket.id })}
                  onClick={() => posthog?.capture("clicked_ticket")}
                >
                  <div className="flex flex-col gap-0.5">
                    <span className="flex items-start justify-between gap-2">
                      <span className="flex items-center gap-2">
                        {ticket.visibility !== "private" ? (
                          <span className="inline-flex items-center justify-center rounded bg-aop-basic-blue-500 p-1.5 text-white">
                            <Icon name={iconUsers01} size={16} />
                          </span>
                        ) : null}
                        <span className="line-clamp-2 text-body-bold">{ticket.title}</span>
                      </span>
                      <div className={twJoin("size-2 rounded-full", ticket.unread && "bg-red-500")} />
                    </span>
                    <span className="text-overline text-grey-400">
                      {formatDate(i18n, "datetimeShort", ticket.postedAt)}
                    </span>
                  </div>

                  <div className="flex items-center justify-between gap-2">
                    <div className="flex items-center gap-2">
                      <div className="size-8">
                        <UserAvatar img={ticket.user.avatar} isUserDeleted={!!ticket.user.deletedAt} />
                      </div>
                      <div className="flex flex-col gap-0.5">
                        <span className="text-caption-bold">{ticket.user.fullName}</span>
                      </div>
                    </div>
                    <div className="flex flex-wrap gap-2">
                      {tab === RESIDENT_CLOSED_TICKET_TAB && ticket.rating != null ? (
                        <div className="flex w-fit items-center gap-1 rounded-md bg-yellow-100 px-2 py-1 text-center">
                          <Icon name={iconStar01} className="fill-yellow-400 text-yellow-500" />
                          <span className="text-body-bold">{ticket.rating}</span>
                        </div>
                      ) : null}
                      <div className="flex items-center gap-2">
                        <Icon name={iconMessageCircle01} size={16} />
                        <span className="text-body-bold">{ticket.numberOfComments}</span>
                      </div>
                    </div>
                  </div>

                  <div className="flex items-center justify-between">
                    <div
                      className="rounded-md px-2 py-1 text-center text-white"
                      style={{
                        textTransform: "uppercase",
                        color: ticket.status.color,
                        backgroundColor: `${ticket.status.color}1A`,
                      }}
                    >
                      <span className="text-caption-bold">{ticket.status.name}</span>
                    </div>
                    <span className="text-body-bold">{ticket.category.name}</span>
                  </div>

                  {ticket.status.type === "closed" && ticket.author.id === sessionUser.id && (
                    <div className="flex items-center gap-2 rounded-lg border border-yellow-500 bg-yellow-100 p-2">
                      <Icon name={iconStar01} className="text-yellow-500" />
                      <span className="whitespace-nowrap text-caption-bold">
                        {t("page.resident-tickets.table.rate")}
                      </span>
                    </div>
                  )}
                </Link>
              ))}
            </div>
          ) : (
            <span className="block p-6 pb-12 text-caption-bold">
              {hasFilters ? t("page.resident-tickets.table.no-results") : t("page.resident-tickets.table.empty")}
            </span>
          )}
          {hasMoreTickets && (
            <div className="h-16 p-4" ref={ref}>
              {(isLoadingTickets || isLoadingMoreTickets) && <LoadingIcon className="inset-0 mx-auto my-4 w-6" />}
            </div>
          )}
        </>
      )}
    </div>
  );
}

interface TickerFiltersProps {
  activeFilters: ResidentTicketsFilters;
  onUpdateFilters: (filter: ResidentTicketFilterParams, val: ResidentTicketFilterTypes) => void;
  values: TicketFiltersDto;
  onClose: () => void;
}

function TicketFilters({ activeFilters, onUpdateFilters, values, onClose }: TickerFiltersProps): React.ReactNode {
  const { t } = useTranslation();
  const sessionUser = useSessionUser();

  return (
    <div className="flex flex-col gap-4 border-t border-grey-100 py-2">
      <span className="text-caption-bold">{t("page.resident-tickets.filter.label")}</span>
      <div className="flex flex-col gap-2">
        <div className="flex h-full flex-col flex-wrap items-center gap-4 md:flex-row">
          <div className="min-w-52">
            <CheckboxMultiSelect
              className="max-h-min"
              id="statuses"
              items={values.statuses}
              selected={values.statuses.filter((x) => activeFilters.StatusIds?.includes(x.id))}
              onChange={(x) =>
                onUpdateFilters(
                  "StatusIds",
                  x.map((x) => x.id),
                )
              }
              placeholder={t("page.resident-tickets.filter.status")}
              renderOption={(x) => (
                <div
                  className="w-full rounded-md px-2 text-center"
                  style={{ textTransform: "uppercase", color: x.color, backgroundColor: `${x.color}1A` }}
                >
                  <span className="text-caption-bold">{x.name || "-"}</span>
                </div>
              )}
              keySelector={(x) => x.id}
            />
          </div>
          <div className="min-w-52">
            <CheckboxMultiSelect
              id="categories"
              items={values.categories}
              selected={values.categories.filter((x) => activeFilters.CategoryIds?.includes(x.id))}
              onChange={(x) =>
                onUpdateFilters(
                  "CategoryIds",
                  x.map((x) => x.id),
                )
              }
              renderValue={(value) => (
                <span className="block max-w-36 truncate pr-5">{value.map((x) => x.name).join(", ")}</span>
              )}
              placeholder={t("page.resident-tickets.filter.category")}
              renderOption={(x) => x.name}
              keySelector={(x) => x.id}
            />
          </div>
          <div className="min-w-52">
            <Select
              id="reporter"
              items={reporterTypeValues}
              selected={activeFilters.ReporterIds?.length ? "mine" : "all"}
              onChange={(x) => onUpdateFilters("ReporterIds", x === "all" ? [] : [sessionUser.id])}
              renderOption={(x) => {
                switch (x) {
                  case "mine":
                    return t("page.resident-tickets.filter.reporter.mine");
                  case "all":
                    return t("page.resisdent-tickets.filter.reporter.all");
                }
              }}
              keySelector={(x) => x}
            />
          </div>
          <div className="min-w-52">
            <Select
              id="visibility"
              items={residentTicketTypeValues}
              selected={activeFilters.RepairRequestFilterType}
              onChange={(x) => onUpdateFilters("RepairRequestFilterType", x)}
              renderOption={(x) => {
                switch (x) {
                  case "both":
                    return t("page.resident-tickets.filter.private-collective.both");
                  case "onlyPrivate":
                    return t("page.resident-tickets.filter.private-collective.private");
                  case "onlyCollective":
                    return t("page.resident-tickets.filter.private-collective.collective");
                }
              }}
              keySelector={(x) => x as RepairRequestFilterTypes}
            />
          </div>
        </div>
        <div className="self-center justify-self-end">
          <Button styling="tertiary" onClick={onClose} icon={<Icon name={iconX} size={16} />}>
            {t("page.resident-tickets.filter-btn-close")}
          </Button>
        </div>
      </div>
    </div>
  );
}
