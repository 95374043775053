import { useImageResolver } from "api/hooks/useImageResolver";
import type { ImageDto, OnboardingScreenDto } from "api/types";
import { Paper } from "components/Paper/Paper";
import { preloadImage } from "helpers/image";
import { useSlug } from "hooks/useSlug";
import { NextButton } from "modules/onboarding-flow/components/NextButton";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { routes } from "routes";
import { twJoin } from "tailwind-merge";

export interface LayoutProps {
  slides: OnboardingScreenDto[];
}

export function Layout({ slides }: LayoutProps): React.ReactNode {
  const slug = useSlug();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [activeSlide, setActiveSlide] = useState<OnboardingScreenDto>(slides[0]);
  const resolveImage = useImageResolver();

  const handleNextButtonClick = () => {
    const activeSlideIndex = slides.findIndex((slide) => slide.id === activeSlide.id);
    if (activeSlideIndex === slides.length - 1) {
      void navigate(routes.onboardingFlow.userProfile({ slug }));
    } else {
      setActiveSlide(slides[activeSlideIndex + 1]);
    }
  };

  const getImage = useCallback(
    function getImage(image: ImageDto) {
      return resolveImage(image, "intrinsic");
    },
    [resolveImage],
  );

  useEffect(() => {
    const nextImageIndex = slides.findIndex((slide) => slide.id === activeSlide.id) + 1;
    if (nextImageIndex < slides.length) {
      preloadImage(getImage(slides[nextImageIndex].image));
    }
  }, [activeSlide.id, slides, getImage]);

  return (
    <div className="flex h-full flex-col">
      <div className="flex-auto md:flex md:h-full md:flex-row">
        <main className="z-10 min-h-full w-full overflow-auto">
          <Paper theme="no-header-minimal">
            <div className="flex size-full items-center justify-center">
              <div data-testid="onboarding-slide" className="relative h-[700px] w-[320px]">
                <div
                  className="h-[calc(50%+16px)] rounded-t-lg bg-cover bg-center bg-no-repeat"
                  style={{
                    backgroundImage: `url(${getImage(activeSlide.image)})`,
                  }}
                />
                <div className="absolute bottom-0 flex h-1/2 w-full flex-col items-center justify-between rounded-lg bg-white text-center">
                  <div className="flex flex-col gap-2 px-8 pt-6">
                    <span className="text-body-bold">{activeSlide.title}</span>
                    <span className="text-caption">{activeSlide.subtitle}</span>
                  </div>
                  <div className="flex w-full justify-between">
                    <div className="flex items-center gap-2 p-4">
                      {slides.map((slide) => (
                        <div
                          className={twJoin(
                            "size-2 cursor-pointer rounded-full",
                            activeSlide === slide ? "bg-aop-dark-blue-500" : "bg-grey-300",
                          )}
                          key={slide.id}
                          onClick={() => setActiveSlide(slide)}
                        />
                      ))}
                    </div>
                    <NextButton onClick={handleNextButtonClick}>{t("common.action.next")}</NextButton>
                  </div>
                </div>
              </div>
            </div>
          </Paper>
        </main>
      </div>
    </div>
  );
}
