import { Button } from "components/Button/Button";
import { Checkbox } from "components/Checkbox/Checkbox";
import type { ModalBaseProps } from "components/Modal/Modal";
import { Modal } from "components/Modal/Modal";
import type React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

type ExportBookingsModalProps = ModalBaseProps & {
  onExport: (includeHistory: boolean) => void;
};

export function ExportBookingsModal({ isOpened, onOpenChange, onExport }: ExportBookingsModalProps): React.ReactNode {
  const [isHistoryIncluded, setIsHistoryIncluded] = useState(false);
  const { t } = useTranslation();

  const onClickExport = () => {
    onExport(isHistoryIncluded);
    onOpenChange(false);
  };

  return (
    <Modal.Root title={t("page.bookings.asset-detail.export.modal.title")} {...{ isOpened, onOpenChange }}>
      <span className="flex items-center gap-2">
        <Checkbox
          name="includeHistory"
          checked={isHistoryIncluded}
          onChange={(e) => setIsHistoryIncluded(e.target.checked)}
        />
        <label className="text-caption" htmlFor="includeHistory">
          {t("page.bookings.asset-detail.export.modal.checkbox")}
        </label>
      </span>
      <span className="text-overline text-grey-500">{t("page.bookings.asset-detail.export.modal.description")}</span>
      <Modal.Actions>
        <Modal.Close>
          <Button styling="secondary">{t("common.action.cancel")}</Button>
        </Modal.Close>
        <Button styling="primary" onClick={onClickExport}>
          {t("page.bookings.asset-detail.export.modal.button")}
        </Button>
      </Modal.Actions>
    </Modal.Root>
  );
}
