import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { UserRoleRequest } from "api/types";
import { ErrorPage } from "components/Error/ErrorPage";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSlug } from "hooks/useSlug";
import { QUERY_KEYS } from "query-keys";
import { useMemo } from "react";
import type { FieldPath } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router";
import { routes } from "routes";
import type { ApiQueryParams } from "types/api-types";

import type { CreateOrEditPageState } from "./";
import type { LayoutProps, RoleFormFieldValues } from "./Layout";
import { compileStructure, getDefaultFormValues } from "./Manager";

interface Props {
  children: (props: LayoutProps) => React.ReactNode;
}

export function Loader(props: Props): React.ReactNode {
  const projectId = useProjectId();
  const slug = useSlug();
  const { t } = useTranslation();
  const { state } = useLocation();
  const pageMeta = useMemo(() => (state == null ? {} : (state as CreateOrEditPageState)), [state]);
  const navigate = useNavigate();
  const { id: roleIdToEdit } = useParams<{ id: string }>();
  const queryClient = useQueryClient();
  const showFlashToast = useFlashToast();
  const api = useApi();

  const queryArgs = {
    Limit: 10000,
  } satisfies ApiQueryParams<"getGroupsV2">;
  const {
    data: communityGroups = [],
    isFetching: isGroupsFetching,
    error: groupsError,
  } = useQuery({
    queryKey: QUERY_KEYS.COMMUNITY_GROUPS_QUERY(projectId, queryArgs),
    queryFn: () => api.getGroupsV2(queryArgs),
    select: (data) => commonAPIDataSelector(data).items,
  });
  const roleId = roleIdToEdit ?? pageMeta?.roleDetails?.id ?? pageMeta?.roleId;
  const {
    data: roleDetails,
    isFetching: isRoleDetailsFetching,
    error: roleDetailsError,
  } = useQuery({
    queryKey: QUERY_KEYS.ROLE_DETAILS(projectId, roleId!),
    queryFn: () => api.getPermissionsRolesDetailsV1(roleId!).then(({ data }) => data),
    enabled: roleIdToEdit != null || !!pageMeta?.roleId,
    initialData: pageMeta?.roleDetails,
  });
  const {
    data: folders = [],
    isFetching: isFoldersFetching,
    error: foldersError,
  } = useQuery({
    queryKey: QUERY_KEYS.DOCUMENTS_ROOT_LIST(projectId),
    queryFn: () => api.getDocumentsV1(),
    select: (x) => commonAPIDataSelector(x).rootFolders,
  });
  const {
    data: ticketCategories = [],
    isFetching: isTicketCategoriesFetching,
    error: ticketCategoriesError,
  } = useQuery({
    queryKey: QUERY_KEYS.TICKET_CATEGORIES(projectId),
    queryFn: () => api.getTicketCategoriesV1(),
    select: (data) => commonAPIDataSelector(data).items,
  });
  const {
    data: servicePartners = [],
    isFetching: isServicePartnersFetching,
    error: servicePartnersError,
  } = useQuery({
    queryKey: QUERY_KEYS.SERVICE_PARTNERS_ALL(projectId),
    queryFn: () => api.getServicepartnersV1(),
    select: (data) => commonAPIDataSelector(data).items,
  });
  const isPageLoading =
    isGroupsFetching ||
    isRoleDetailsFetching ||
    isFoldersFetching ||
    isTicketCategoriesFetching ||
    isServicePartnersFetching;
  const defaultFormValues = useMemo(() => {
    if (isPageLoading) {
      return undefined;
    }

    return getDefaultFormValues(
      {
        communityGroups,
        ticketCategories: ticketCategories,
        servicePartners,
        folders,
      },
      roleDetails,
    );
  }, [isPageLoading, communityGroups, ticketCategories, servicePartners, folders, roleDetails]);

  const { mutateAsync: createRole, isPending: isRoleCreating } = useMutation({
    mutationFn: (data: UserRoleRequest) => api.postPermissionsRolesV1(data),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.ROLE_LIST(projectId) });
      showFlashToast({
        type: "success",
        title: t("page.role.create-or-edit.create-mode.action.create.notification.success"),
      });
      void navigate(routes.roles.list({ slug }));
    },
    onError: () => {
      showFlashToast({
        type: "error",
        title: t("page.role.create-or-edit.create-mode.action.create.notification.failure"),
      });
    },
  });
  const { mutateAsync: editRole, isPending: isRoleEditing } = useMutation({
    mutationFn: (data: { id: string; payload: UserRoleRequest }) => api.putPermissionsRolesV1(data.id, data.payload),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.ROLE_LIST(projectId) });
      showFlashToast({
        type: "success",
        title: t("page.role.create-or-edit.edit-mode.action.edit.notification.success"),
      });
      void navigate(routes.roles.list({ slug }));
    },
    onError: () => {
      showFlashToast({
        type: "error",
        title: t("page.role.create-or-edit.edit-mode.action.edit.notification.failure"),
      });
    },
  });

  const onSubmit: LayoutProps["onSubmit"] = (data) => {
    const payload: UserRoleRequest = {
      ...data,
      permissions: {
        ...data.permissions,
        communityGroups: Object.values(data.permissions.communityGroups),
        repairCategories: Object.values(data.permissions.repairCategories),
        servicePages: Object.values(data.permissions.servicePages),
        folders: Object.values(data.permissions.folders),
      },
    };

    if (roleIdToEdit) {
      void editRole({ id: roleIdToEdit, payload });
    } else {
      void createRole(payload);
    }
  };

  const structure = useMemo(
    () => compileStructure(t, communityGroups, ticketCategories, servicePartners, folders),
    [communityGroups, folders, ticketCategories, servicePartners, t],
  );

  const error = roleDetailsError || groupsError || ticketCategoriesError || servicePartnersError || foldersError;
  if (error) {
    return <ErrorPage error={error} />;
  }

  if (roleIdToEdit && roleDetails?.locked) {
    return <DocumentPaper theme="no-gaps" title={t("model.role.action.edit.is-locked")} />;
  }

  return isPageLoading || defaultFormValues == null ? (
    <FullSizeLoader withPadding />
  ) : (
    <>
      {props.children({
        onSubmit,
        defaultFormValues,
        isSubmitting: isRoleCreating || isRoleEditing,
        structure,
        initialStepId: pageMeta?.initialStepId,
        isDuplicate: pageMeta?.roleId != null,
      })}
    </>
  );
}

export interface PermissionLayoutStructure {
  id: string;
  groupLabel: string;
  items?: {
    label: string;
    modelPath: FieldPath<RoleFormFieldValues>;
    tooltip?: string;
  }[];
  subStructures?: PermissionLayoutStructure[];
}
