import { useInterval } from "hooks/useInterval";
import { motion } from "motion/react";
import { useEffect, useState } from "react";

interface SnackbarAction {
  name: string;
  action: () => void;
  "data-testid"?: string;
}

export interface SnackbarProps {
  message: string;
  timeoutInSeconds?: number;
  onTimeout?: () => void;
  actions: SnackbarAction[];
}

export function Snackbar({ message, actions, timeoutInSeconds, onTimeout }: SnackbarProps): React.ReactNode {
  const [seconds, setSeconds] = useState(() => timeoutInSeconds);

  const { clearInterval } = useInterval(() => {
    if (timeoutInSeconds != null) {
      setSeconds((s) => (s != null && s > 1 ? s - 1 : 0));
    } else {
      clearInterval();
    }
  }, 1000);

  useEffect(() => {
    if (seconds != null && seconds === 0) {
      onTimeout?.();
    }
  }, [seconds, onTimeout]);

  return (
    <motion.div
      className="flex h-16 w-full items-center rounded-t bg-grey-900/95 px-6 text-white shadow"
      initial={{ opacity: 0, y: "100%", bottom: 0 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.3 }}
      exit={{ opacity: 1, y: "100%" }}
    >
      <p className="mr-auto">{message}</p>
      {seconds != null ? (
        <div className="relative mx-4 flex size-8 items-center justify-center text-blue-200">
          <svg
            className="absolute inset-0 -rotate-90 animate-dash"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="none"
            stroke="currentColor"
            strokeWidth={1}
            strokeDasharray="1"
            strokeDashoffset="1"
            style={{
              animationDuration: `${timeoutInSeconds}s`,
            }}
          >
            <circle cx="10" cy="10" r="8" pathLength="1" />
          </svg>
          <span>{seconds}</span>
        </div>
      ) : null}
      <div className="flex">
        {actions.map((action) => (
          <button
            className="rounded px-4 py-2 text-blue-200 ring-blue-200/30 hover:bg-grey-500 hover:text-grey-100 focus:outline-none focus:ring-2"
            key={action.name}
            onClick={() => {
              clearInterval();
              action.action();
            }}
            data-testid={action["data-testid"]}
          >
            {action.name}
          </button>
        ))}
      </div>
    </motion.div>
  );
}
