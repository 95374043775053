import { ErrorPage } from "components/Error/ErrorPage";
import { RequireAuth } from "components/Routing/RequireAuth";
import type { RouteObject } from "react-router";

import { AssetDetailPage } from "./pages/AssetDetail";
import { CreateOrEditBookableAssetPage } from "./pages/CreateOrEditAssets";
import { CreateOrEditBookingPage } from "./pages/CreateOrEditBookings";
import { ListBookableAssetsPage } from "./pages/ListAssets";
import { canManageAnyBookableAsset, canViewSchedule } from "./permissions";
import { bookingsRoutes } from "./routes";

export const bookingsRoute = [
  {
    path: bookingsRoutes.list,
    element: <ListBookableAssetsPage />,
  },
  {
    path: bookingsRoutes.assetDetail,
    element: (
      <RequireAuth permission={canViewSchedule}>
        <AssetDetailPage />
      </RequireAuth>
    ),
  },
  {
    path: bookingsRoutes.createAsset,
    element: (
      <RequireAuth permission={canManageAnyBookableAsset}>
        <CreateOrEditBookableAssetPage />
      </RequireAuth>
    ),
  },
  {
    path: bookingsRoutes.editAsset,
    element: (
      <RequireAuth permission={canManageAnyBookableAsset}>
        <CreateOrEditBookableAssetPage />
      </RequireAuth>
    ),
  },
  {
    path: bookingsRoutes.bookAsset,
    element: <CreateOrEditBookingPage />,
  },
  {
    path: bookingsRoutes.editBooking,
    element: <CreateOrEditBookingPage />,
  },
  { path: "*", element: <ErrorPage status={404} /> },
] satisfies RouteObject[];
