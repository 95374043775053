import type { AssetBookingDto } from "api/types";
import trashIcon from "assets/icons/trash-01.svg";
import { IconButton } from "components/Button/IconButton";
import { FormattedDate } from "components/FormattedDate/FormattedDate";
import { Icon } from "components/Icon/Icon";
import { Modal, type ModalBaseProps } from "components/Modal/Modal";
import { UserAvatarLink } from "components/UserAvatarLink/UserAvatarLink";
import { UserNameLink } from "components/UserNameLink/UserNameLink";
import { timeWithoutSeconds } from "helpers/date";
import { orderBy } from "lodash-es";
import { useTranslation } from "react-i18next";
import { twJoin } from "tailwind-merge";

type ReservationListModalProps = ModalBaseProps & {
  bookings: AssetBookingDto[];
  date?: Date;
  onOpenCancelModal: (booking: AssetBookingDto) => void;
};

export function ReservationListModal({
  isOpened,
  onOpenChange,
  bookings,
  date,
  onOpenCancelModal,
}: ReservationListModalProps): React.ReactNode {
  const { t } = useTranslation();

  return (
    <Modal.Root
      title={t("page.bookings.asset-detail.booking-list.title")}
      description={date && <FormattedDate date={date} format="date" />}
      isScrollable
      {...{ isOpened, onOpenChange }}
    >
      {bookings.length > 0 && (
        <ul className="max-h-screen-minus-64">
          {orderBy(bookings, (x) => x.startTime).map((booking) => (
            <li className="flex w-full items-start gap-6 p-2 even:bg-grey-50" key={booking.id}>
              <div className="flex w-full flex-row items-center gap-2">
                {booking.author && (
                  <div className="size-10 self-start">
                    <UserAvatarLink user={booking.author} />
                  </div>
                )}
                <div className="flex w-full flex-col flex-nowrap">
                  <span
                    className={twJoin(
                      "flex justify-between gap-6",
                      booking.bookingReason ? "items-start" : "items-center",
                    )}
                  >
                    {booking.author && (
                      <UserNameLink user={booking.author}>
                        <span className="max-w-64 truncate font-old-semibold">{booking.author.fullName}</span>
                      </UserNameLink>
                    )}
                    <span
                      className={twJoin("flex flex-row gap-6", booking.bookingReason ? "items-start" : "items-center")}
                    >
                      <span>
                        {timeWithoutSeconds(booking.startTime)}-{timeWithoutSeconds(booking.endTime)}
                      </span>
                      {booking.canCancel && (
                        <IconButton
                          styling="danger"
                          onClick={() => {
                            onOpenCancelModal(booking);
                          }}
                          title={t("page.bookings.asset-detail.booking-list.cancel")}
                        >
                          <Icon name={trashIcon} />
                        </IconButton>
                      )}
                    </span>
                  </span>

                  {booking.bookingReason && (
                    <>
                      <strong className={twJoin("text-caption text-grey-600", booking.canCancel && "-mt-4")}>
                        {t("page.bookings.asset-detail.booking-list.reason")}
                      </strong>
                      <span className="max-w-md text-caption">{booking.bookingReason}</span>
                    </>
                  )}
                </div>
              </div>
            </li>
          ))}
        </ul>
      )}
    </Modal.Root>
  );
}
