import { Popover } from "@ark-ui/react";
import type { ConstraintListItemDto } from "api/types";
import { Button } from "components/Button/Button";
import { useBool } from "hooks/useBool";
import { groupBy } from "lodash-es";
import type React from "react";
import { useTranslation } from "react-i18next";
import { twJoin } from "tailwind-merge";

import { Tag } from "./Tag";

export interface TagListProps {
  tags: TagListItem[];
  limit: number;
}
export function TagList({ tags: allTags, limit }: TagListProps): React.ReactNode {
  const { t } = useTranslation();
  const [isOpen, openHandlers] = useBool();
  const tagsToShow = allTags.slice(0, limit);
  const tagsToHide = allTags.slice(limit);

  return (
    <div className="flex items-center gap-1" data-testid="tag-list-root">
      <TagListRow tags={tagsToShow} />
      <Button
        styling="tertiary"
        className="flex items-center"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (isOpen) {
            openHandlers.setFalse();
          } else {
            openHandlers.setTrue();
          }
        }}
      >
        <span className="text-caption-bold">
          {t("component.tag-list.details")}
          {tagsToHide.length ? ` (+${tagsToHide.length})` : null}
        </span>
      </Button>
      <Popover.Root
        open={isOpen}
        onInteractOutside={openHandlers.setFalse}
        positioning={{
          placement: "right-start",
          offset: {
            crossAxis: -15,
          },
        }}
      >
        <Popover.Anchor />
        <Popover.Positioner className="!z-50">
          <Popover.Content
            className={twJoin(
              "max-w-[80%] whitespace-pre-line text-caption md:max-w-sm",
              "data-[placement=left-end]:origin-bottom-right data-[placement=left-start]:origin-top-right data-[placement=right-end]:origin-bottom-left data-[placement=right-start]:origin-top-left",
              "data-[state=open]:animate-in data-[state=open]:fade-in data-[state=open]:zoom-in",
              "data-[state=closed]:animate-out data-[state=closed]:fade-out data-[state=closed]:zoom-out",
            )}
          >
            <TagListTooltip tags={allTags} />
          </Popover.Content>
        </Popover.Positioner>
      </Popover.Root>
    </div>
  );
}

interface TagListTooltipProps {
  tags: TagListItem[];
}
function TagListTooltip({ tags }: TagListTooltipProps) {
  const { t } = useTranslation();
  const tagsByType = groupBy(tags, ({ type }) => type);
  function getTypeTranslation(type: string) {
    switch (type) {
      case "company":
        return t("model.constraint.type.company");
      case "floor":
        return t("model.constraint.type.floor");
      case "building":
        return t("model.constraint.type.building");
      case "apartmentType":
        return t("model.constraint.type.apartment-type");
      default:
        return type;
    }
  }

  return (
    <div className="flex flex-col gap-2 rounded border border-grey-300 bg-white p-3 shadow-sm">
      {Object.entries(tagsByType).map(([type, tags]) => {
        return (
          <div key={type}>
            <span className="mb-1 block text-caption-bold">{getTypeTranslation(type)}:</span>
            <TagListRow tags={tags} />
          </div>
        );
      })}
    </div>
  );
}

interface TagListRowProps {
  tags: TagListItem[];
}
function TagListRow({ tags }: TagListRowProps) {
  return (
    <ul className="flex gap-0.5">
      {tags.map(({ id, content }) => (
        <li key={id}>
          <Tag>{content}</Tag>
        </li>
      ))}
    </ul>
  );
}

interface TagListItem {
  id: string;
  content: React.ReactNode;
  type: string;
}

export function transformConstraintToTag({ key, ...constraint }: ConstraintListItemDto): TagListItem {
  return { content: key, ...constraint };
}
