import { useApiClient } from "api/hooks/useApi";
import type { VideoDto } from "api/types";
import type { FormVideo } from "components/VideoInput/useVideoInput";
import { isVideoUploaded } from "components/VideoInput/useVideoInput";
import { fileMutations } from "queries/files";
import { CANCEL_TOKENS } from "queries/files/helpers";
import { useCallback } from "react";

export function useUploadVideo({
  onProgress,
}: {
  onProgress?: ({ name, progress }: { name: string; progress: number }) => void;
}): {
  uploadFormVideo: UploadFormVideo;
  abortUploadVideos: AbortUploadVideos;
  isUploadingVideo: boolean;
} {
  const apiClient = useApiClient();

  const { mutateAsync: uploadVideo, isPending: isUploadingVideo } = fileMutations.useAddVideo({
    onProgress: onProgress,
  });

  const uploadFormVideo: UploadFormVideo = useCallback(
    async (formVideo) => {
      if (isVideoUploaded(formVideo)) {
        return formVideo;
      }

      if (formVideo.uploadPromise) {
        return formVideo.uploadPromise;
      }

      return await uploadVideo(formVideo);
    },
    [uploadVideo],
  );

  const abortUploadVideos = (videos: FormVideo[]) => {
    videos.forEach((video) => {
      if (!isVideoUploaded(video)) {
        apiClient.abortRequest(CANCEL_TOKENS.UPLOAD_VIDEO(video.file.name));
        URL.revokeObjectURL(video.url);
      }
    });
  };

  return { uploadFormVideo, abortUploadVideos, isUploadingVideo };
}

export type UploadFormVideo = (image: FormVideo) => Promise<VideoDto | undefined>;
type AbortUploadVideos = (videos: FormVideo[]) => void;
