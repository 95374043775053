import type { RadioProps } from "components/Radio/Radio";
import { Radio } from "components/Radio/Radio";
import { twResolve } from "helpers/tw-resolve";
import type { FieldPath, FieldPathValue, FieldValues, RegisterOptions } from "react-hook-form";
import { useController } from "react-hook-form";
import { twJoin } from "tailwind-merge";

export interface FormRadioProps<
  TFormValues extends FieldValues,
  TName extends FieldPath<TFormValues> = FieldPath<TFormValues>,
> extends Omit<RadioProps, "onChange" | "checked" | "defaultChecked"> {
  name: TName;
  value: FieldPathValue<TFormValues, TName>;
  displayValue: string;
  className?: string;
  rules?: RegisterOptions<TFormValues, TName>;
}

/**
 * Values for radio buttons can only be strings. If you need to use a different type, you have to convert it to a string yourself.
 */
export function FormRadio<
  TFormValues extends FieldValues,
  TName extends FieldPath<TFormValues> = FieldPath<TFormValues>,
>({ rules, displayValue, className, name, ...props }: FormRadioProps<TFormValues, TName>): React.ReactNode {
  const { field } = useController<TFormValues, TName>({ name, rules });

  return (
    <label className={twResolve("inline-flex flex-row-reverse items-center justify-end gap-2", className)}>
      <span>{displayValue}</span>
      <Radio {...props} {...field} value={props.value} defaultChecked={field.value === props.value} />
    </label>
  );
}

export function FormRadioGroup({
  isVertical,
  children,
}: {
  isVertical?: boolean;
  children: React.ReactNode;
}): React.ReactNode {
  return <div className={twJoin("flex flex-wrap gap-x-4 gap-y-1", isVertical && "flex-col")}>{children}</div>;
}
