import { ErrorPage } from "components/Error/ErrorPage";
import { canAccessPracticalAndMore } from "components/ProjectSidebar/permissions";
import { RequireAuth } from "components/Routing/RequireAuth";
import { DocumentsCreateOrEditPage } from "modules/documents/pages/DocumentsCreateOrEditFile";
import { DocumentsList } from "modules/documents/pages/DocumentsList";
import { FolderCreateOrEdit } from "modules/documents/pages/FolderCreateOrEdit";
import { RootCreateOrEdit } from "modules/documents/pages/RootCreateOrEdit";
import { DocumentsRootList } from "modules/documents/pages/RootList";
import type { RouteObject } from "react-router";

import { documentsRoutes } from "./routes";

export const documentsRoute = [
  {
    path: documentsRoutes.rootList,
    element: (
      <RequireAuth permission={canAccessPracticalAndMore}>
        <DocumentsRootList />
      </RequireAuth>
    ),
  },
  {
    path: documentsRoutes.rootCreate,
    element: (
      <RequireAuth permission={(x) => x.isSuperAdmin}>
        <RootCreateOrEdit />
      </RequireAuth>
    ),
  },
  {
    path: documentsRoutes.rootEdit,
    element: (
      <RequireAuth permission={(x) => x.isSuperAdmin}>
        <RootCreateOrEdit />
      </RequireAuth>
    ),
  },
  { path: documentsRoutes.documentsList, element: <DocumentsList /> },
  { path: documentsRoutes.fileCreate, element: <DocumentsCreateOrEditPage /> },
  { path: documentsRoutes.fileEdit, element: <DocumentsCreateOrEditPage /> },
  { path: documentsRoutes.folderCreate, element: <FolderCreateOrEdit /> },
  { path: documentsRoutes.folderEdit, element: <FolderCreateOrEdit /> },
  { path: "*", element: <ErrorPage status={404} /> },
] satisfies RouteObject[];
