import type { AnimationProps } from "motion/react";

export const overlayVariants: AnimationProps["variants"] = {
  hidden: {
    opacity: 0,
    transition: {
      duration: 0.2,
      ease: "linear",
    },
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.2,
      ease: "linear",
    },
  },
};
