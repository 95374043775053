import type { EventDto } from "api/types";
import iconCalendar from "assets/icons/calendar.svg";
import iconEdit05 from "assets/icons/edit-05.svg";
import iconMarkerPin01 from "assets/icons/marker-pin-01.svg";
import iconUser01 from "assets/icons/user-01.svg";
import iconUsers01 from "assets/icons/users-01.svg";
import { DateRange } from "components/DateRange/DateRange";
import { FormattedDate } from "components/FormattedDate/FormattedDate";
import { Icon } from "components/Icon/Icon";
import { UserNameLink } from "components/UserNameLink/UserNameLink";
import { isDefined } from "helpers/util";
import type { TFunction } from "i18next";
import type React from "react";
import { useTranslation } from "react-i18next";
import { twJoin } from "tailwind-merge";

interface EventDetailsInfoSectionProps {
  event: EventDto;
}

export function EventDetailsInfoSection({ event }: EventDetailsInfoSectionProps): React.ReactNode {
  const { t } = useTranslation();

  const getRestAmountOfSpots = (max?: number, current?: number) => {
    if (max != null && current != null) {
      const result = max - current;
      if (result < 0) {
        console.error("Invalid amount of participants");
      }
      if (!result) {
        return Infinity;
      }

      return result;
    }

    return Infinity;
  };

  const getStatusTranslation = (data: EventDto, t: TFunction): string | null => {
    if (data.deletedAt) {
      return t("model.event.status.deleted");
    }

    const participantsExist = isDefined(data.maxParticipants) && isDefined(data.participantCount);
    const isFull = participantsExist ? data.participantCount! >= data.maxParticipants! : false;
    const restSpotsAmount = participantsExist && !isFull ? data.maxParticipants! - data.participantCount! : null;
    const noLimit = data.maxParticipants == null;
    if (data.isSignedUp) {
      return t("model.event.status.is-signed-up");
    }

    if (isFull) {
      return t("model.event.status.no-spots");
    }

    if (!data.canParticipate) {
      return null;
    }

    if (isDefined(restSpotsAmount)) {
      return t("model.event.status.spots", { count: data.maxParticipants! - data.participantCount! });
    }

    if (noLimit) {
      return t("model.event.status.is-not-signed-up");
    }

    return null;
  };

  const amnountParticipants = event.deletedAt ? 0 : event.participantCount;
  const statusTranslation = getStatusTranslation(event, t);
  const numberOfFreeSpots = getRestAmountOfSpots(event.maxParticipants, amnountParticipants);
  const isLastSpots = numberOfFreeSpots <= 3;
  const isUnlimitedEvent = event.type === "common" && event.maxParticipants == null;
  const isStatusAccented = (!event.isSignedUp && isLastSpots) || (isUnlimitedEvent && !event.isSignedUp);
  const labelParticipants = `${amnountParticipants || 0}${event.maxParticipants != null ? `/${event.maxParticipants}` : ""}`;

  return (
    <div className="flex flex-col items-start gap-1">
      <div className="flex items-center gap-2">
        <Icon className="text-grey-500" size={16} name={iconCalendar} />
        <DateRange start={event.startsAt} end={event.endsAt} />
      </div>
      {event.location != null && (
        <div className="flex items-center gap-2">
          <Icon className="text-grey-500" size={16} name={iconMarkerPin01} />
          {event.location}
        </div>
      )}
      <div className="flex items-center gap-2">
        <Icon className="text-grey-500" size={16} name={iconUser01} />
        <UserNameLink date-testid="event-organizer-link" user={event.organizer}>
          {event.organizer.fullName}
        </UserNameLink>
      </div>
      {!event.deletedAt && statusTranslation && (
        <div className="flex items-center gap-2">
          <Icon className="text-grey-500" size={16} name={iconUsers01} />
          <div className="flex items-center">
            {labelParticipants}
            <div
              className={twJoin(
                "ml-1.5 flex grow-0 items-center gap-1.5",
                isStatusAccented ? "text-red-600" : undefined,
              )}
            >
              <span className="block size-1 shrink-0 rounded-full bg-current content-['']" />
              {statusTranslation}
            </div>
          </div>
        </div>
      )}
      {!event.deletedAt && event.updatedAt && (
        <div className="flex items-center gap-2">
          <Icon className="text-grey-500" size={16} name={iconEdit05} />
          <div className="flex items-center gap-1">
            {t("model.event.last-edit", {
              date: "",
            })}
            <FormattedDate date={event.updatedAt} format="datetime" />
          </div>
        </div>
      )}
    </div>
  );
}
