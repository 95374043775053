import { useQuery } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { MessageV2Dto } from "api/types";
import { ErrorPage } from "components/Error/ErrorPage";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { isHttpError } from "helpers/Network/errors";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSlug } from "hooks/useSlug";
import { QUERY_KEYS } from "query-keys";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { routes } from "routes";

interface Props {
  children: (props: { message: MessageV2Dto }) => React.ReactNode;
}

export function Loader(props: Props): React.ReactNode {
  const projectId = useProjectId();
  const slug = useSlug();
  const apiClient = useApi();
  const { id: messageId } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const {
    data: messageDetails,
    isPending: isMessageDetailsLoading,
    error,
  } = useQuery({
    queryKey: QUERY_KEYS.MESSAGES_DETAILS(projectId, messageId!),
    queryFn: () => apiClient.getMessagesDetailsV2(messageId!).then((x) => x.data),
  });

  useEffect(() => {
    if (isHttpError(error) && error.status === 404) {
      void navigate(routes.messageFeed.list({ slug }));
    }
  }, [error, slug, navigate]);

  useEffect(() => {
    if (messageDetails?.isUnread) {
      void apiClient.postMessagesReadV1(messageId!);
    }
  }, [apiClient, messageDetails?.isUnread, messageId]);

  if (messageDetails && messageDetails.isUnread) {
    messageDetails.isUnread = false;
  }

  if (error) {
    return <ErrorPage error={error} />;
  }

  return messageDetails == null || isMessageDetailsLoading ? (
    <FullSizeLoader withPadding />
  ) : (
    props.children({
      message: messageDetails,
    })
  );
}
