import { useSlug } from "hooks/useSlug";
import { useEffect } from "react";
import { type To, useNavigate } from "react-router";

interface Props {
  to: (params: { slug: string }) => To;
}

export function Redirect({ to }: Props): null {
  const slug = useSlug();
  const navigate = useNavigate();

  useEffect(() => {
    void navigate(to({ slug }));
  }, [navigate, to, slug]);

  return null;
}
