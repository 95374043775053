import { ErrorPage } from "components/Error/ErrorPage";
import { canAccessServices } from "components/ProjectSidebar/permissions";
import type { RouteObject } from "react-router";

import { RequireAuth } from "../../components/Routing/RequireAuth";
import { ServiceCreateOrEditPage } from "./pages/CreateOrEdit";
import { ServiceDetailsPage } from "./pages/Details";
import { ServicesListPage } from "./pages/List";
import { canCreateNew, canSeeList } from "./permissions";
import { servicePartnersRoutes } from "./routes";

export const servicePartnersRoute = [
  {
    path: servicePartnersRoutes.list,
    element: (
      <RequireAuth permission={canAccessServices}>
        <ServicesListPage />
      </RequireAuth>
    ),
  },
  {
    path: servicePartnersRoutes.details,
    element: (
      <RequireAuth permission={canSeeList}>
        <ServiceDetailsPage />
      </RequireAuth>
    ),
  },
  {
    path: servicePartnersRoutes.create,
    element: (
      <RequireAuth permission={canCreateNew}>
        <ServiceCreateOrEditPage />
      </RequireAuth>
    ),
  },
  {
    path: servicePartnersRoutes.edit,
    element: (
      <RequireAuth permission={canSeeList}>
        <ServiceCreateOrEditPage />
      </RequireAuth>
    ),
  },
  { path: "*", element: <ErrorPage status={404} /> },
] satisfies RouteObject[];
