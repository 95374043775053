import { Modal, type ModalBaseProps } from "components/Modal/Modal";
import type React from "react";

type VideoPreviewDetailsModalProps = ModalBaseProps & {
  videoName: string | undefined;
  videoSrc: string;
};

export function VideoPreviewDetailsModal({
  isOpened,
  onOpenChange,
  videoName,
  videoSrc,
}: VideoPreviewDetailsModalProps): React.ReactNode {
  return (
    <Modal.Root title={videoName} size="lg" {...{ isOpened, onOpenChange }}>
      <video className="mx-auto aspect-video w-full rounded-md bg-black object-contain" src={videoSrc} controls />
    </Modal.Root>
  );
}
