import { useEffect } from "react";
import { useLocation } from "react-router";

export function useScrollToHash(): void {
  const location = useLocation();

  useEffect(() => {
    if (!location.hash) {
      return;
    }

    const hashValue = location.hash.slice(1);
    const elementId = decodeURIComponent(hashValue);
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, [location.hash]);
}
