import { NotLoggedInGuard } from "authentication/NotLoggedInGuard";
import { ErrorPage } from "components/Error/ErrorPage";
import type { RouteObject } from "react-router";

import { ForgotPasswordPage } from "./pages/ForgotPassword";
import { LogoutPage } from "./pages/Logout";
import { MultiFactorAuthenticationPage } from "./pages/MultiFactorAuthentication";
import { ResetPasswordPage } from "./pages/ResetPassword";
import { SignInSignUpPage } from "./pages/SignInSignUp";
import { VerifyEmailPage } from "./pages/VerifyEmail";
import { authenticationRoutes } from "./routes";

export const authenticationRoute = [
  {
    path: authenticationRoutes.login,
    element: (
      <NotLoggedInGuard>
        <SignInSignUpPage />
      </NotLoggedInGuard>
    ),
  },
  {
    path: authenticationRoutes.logout,
    element: <LogoutPage />,
  },
  {
    path: authenticationRoutes.verifyEmail,
    element: <VerifyEmailPage />,
  },
  {
    path: authenticationRoutes.multiFactorAuthentication,
    element: (
      <NotLoggedInGuard>
        <MultiFactorAuthenticationPage />
      </NotLoggedInGuard>
    ),
  },
  {
    path: authenticationRoutes.forgotPassword,
    element: (
      <NotLoggedInGuard>
        <ForgotPasswordPage />
      </NotLoggedInGuard>
    ),
  },
  {
    path: authenticationRoutes.resetPassword,
    element: <ResetPasswordPage />,
  },
  { path: "*", element: <ErrorPage status={404} /> },
] satisfies RouteObject[];
