import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { TicketCommentRequest } from "api/types";
import { ErrorPage } from "components/Error/ErrorPage";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import type { ResidentCreateCommentPayload } from "components/Ticket/TicketResidentCommentField";
import { isHttpError } from "helpers/Network/errors";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { useUploadImage } from "hooks/Network/useUploadImage";
import { useUploadVideo } from "hooks/Network/useUploadVideo";
import { useSlug } from "hooks/useSlug";
import { sumBy } from "lodash-es";
import { QUERY_KEYS } from "query-keys";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { routes } from "routes";

import type { LayoutProps } from "./Layout";

interface Props {
  children: (props: LayoutProps) => React.ReactNode;
}

const COMMENTS_AMOUNT = 20;

export function Loader({ children }: Props): React.ReactNode {
  const projectId = useProjectId();
  const slug = useSlug();
  const { id: ticketId } = useParams<{ id: string }>();
  const queryClient = useQueryClient();
  const api = useApi();
  const { t } = useTranslation();
  const showFlashToast = useFlashToast();
  const navigate = useNavigate();

  const detailToken = QUERY_KEYS.TICKETS_RESIDENT_DETAILS(projectId, ticketId!);
  const {
    data: ticket,
    isPending: isLoadingTicket,
    error: ticketDetailsError,
  } = useQuery({
    queryKey: detailToken,
    queryFn: () => api.getTicketsDetailsV1(ticketId!),
    select: commonAPIDataSelector,
  });

  const commentsQuery = useInfiniteQuery({
    queryKey: QUERY_KEYS.TICKETS_RESIDENT_DETAILS_COMMENTS(projectId, ticketId!),
    queryFn: ({ pageParam }) =>
      api
        .getTicketsCommentsV1(ticketId!, {
          Offset: pageParam,
          Limit: COMMENTS_AMOUNT,
        })
        .then((x) => x.data),
    initialPageParam: 0,
    getNextPageParam: (latestResponse, allResponses) => {
      const newOffset = sumBy(allResponses, (page) => page.items.length);

      return latestResponse?.hasMore ? newOffset : undefined;
    },
  });

  const { uploadFormImage } = useUploadImage();
  const { uploadFormVideo } = useUploadVideo({});

  const rate = useMutation({
    mutationFn: (payload: { rating?: number; remark: string; closingDate: Date }) =>
      api.putTicketsRateV1(ticketId!, {
        remark: payload.remark,
        rating: payload.rating ?? 0,
        closedAt: payload.closingDate.toISOString(),
      }),
    async onSuccess() {
      await queryClient.invalidateQueries({ queryKey: detailToken });
      await queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.TICKETS_RESIDENT_DETAILS_COMMENTS(projectId, ticketId!),
      });
    },
    async onError() {
      showFlashToast({
        type: "error",
        title: t("page.tickets.details.rate.error"),
      });

      await queryClient.invalidateQueries({ queryKey: detailToken });
    },
  });

  const reply = useMutation({
    mutationFn: (payload: TicketCommentRequest) => api.postTicketsCommentsV1(ticketId!, payload),
    async onSuccess() {
      await queryClient.invalidateQueries({ queryKey: detailToken });
      await queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.TICKETS_RESIDENT_DETAILS_COMMENTS(projectId, ticketId!),
      });
    },
    async onError() {
      showFlashToast({
        type: "error",
        title: t("page.tickets.details.reply.error"),
      });

      await queryClient.invalidateQueries({ queryKey: detailToken });
    },
  });

  const comments = useMemo(() => commentsQuery.data?.pages.flatMap((x) => x.items) || [], [commentsQuery.data?.pages]);

  async function createComment({ content, images, videos, internal }: ResidentCreateCommentPayload) {
    const payload: TicketCommentRequest = {
      content,
      accessType: internal ? "internal" : "public",
    };

    if (images && images.length > 0) {
      const uploadedImage = await uploadFormImage(images[0]);

      payload.imageId = uploadedImage?.id;
    }

    const uploadedVideoIds: string[] = [];
    if (videos && videos.length > 0) {
      for (const video of videos) {
        const uploadedVideo = await uploadFormVideo(video);

        if (uploadedVideo) {
          uploadedVideoIds.push(uploadedVideo.id);
        }
      }

      payload.videoIds = uploadedVideoIds;
    }

    await reply.mutateAsync(payload);
  }

  useEffect(() => {
    if (isHttpError(ticketDetailsError) && ticketDetailsError.status === 404) {
      void navigate(routes.tickets.list({ slug }));
      showFlashToast({
        type: "error",
        title: t("page.tickets.details.errors.not-found"),
      });
    }
  }, [navigate, showFlashToast, t, ticketDetailsError, slug]);

  // Mark as read called everytime ticket is loaded
  const markAsReadMutation = useMutation({
    mutationFn: () => api.postTicketsReadV1(ticketId!),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: QUERY_KEYS.TICKETS_FEED(projectId), refetchType: "inactive" }),
  });

  const markAsRead = markAsReadMutation.mutate;
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (ticket?.id) {
        markAsRead();
      }
    }, 500);

    return () => clearTimeout(timeout);
  }, [markAsRead, ticket?.id]);

  const error = ticketDetailsError || commentsQuery.error;
  if (error) {
    return <ErrorPage error={error} />;
  }

  if (isLoadingTicket) {
    return <FullSizeLoader withPadding />;
  }

  return (
    <>
      {children({
        ticket: ticket,
        comments: comments,
        isFetchingMoreComments: commentsQuery.isFetchingNextPage || commentsQuery.isPending,
        hasMoreComments: commentsQuery.hasNextPage || false,
        isRatingSubmitting: rate.isPending,
        fetchMoreComments: commentsQuery.fetchNextPage,
        onRate: rate.mutateAsync,
        onCreateComment: createComment,
      })}
    </>
  );
}
